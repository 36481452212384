.learning-video-details-sec .main-subs-plan-lernvido-dtl .cus-head {
  width: 100%;
  height: 65px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .cus-head h5 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
  padding: 20px 12px 12px 12px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card {
  border-radius: 4px;
  padding: 20px 20px 30px 20px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-btn button {
  color: #0A65CC;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border: none;
  background: none;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .video-card {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  border-radius: 10px;
  padding-bottom: 33.25%;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .video-card video {
  position: absolute;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-head-inside h1 {
  color: #141522;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.96px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec p {
  color: #54577A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec .cus-pan {
  color: #04A4F4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec-2 span {
  color: #141522;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-inner-c h1 {
  color: #141522;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.72px;
}
.learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-inner-c p {
  color: #141522;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .video-card {
    padding-bottom: 52.25%;
  }
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .video-card video {
    height: auto;
  }
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-head-inside h1 {
    font-size: 19px;
  }
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec p {
    font-size: 13px;
  }
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec .cus-pan {
    font-size: 13px;
  }
  .learning-video-details-sec .main-subs-plan-lernvido-dtl .subscrip-card .cus-sec-2 span {
    font-size: 13px;
  }
}/*# sourceMappingURL=LearningVideoDetails.css.map */