.popular-jobs-section {margin: 10px 0px 30px 0px;
.second-section {
.similer-post-heading {margin-bottom: 50px;}
.slick-slider {
.slick-next:before,
.slick-prev:before {font-size: 26px;line-height: 1;opacity: .75;color: rgba(27, 55, 92, 1);}
.slick-next,
.slick-prev {font-size: 0;line-height: 0;top: -20%;width: 20px;height: 20px;-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);transform: translate(0, -50%);cursor: pointer;color: transparent;border: none;outline: 0;background: 0 0;}
.slick-next {right: 40px !important;}
.slick-prev {right: 90px !important;left: unset;}
.slick-next:before {position: absolute;content: "\f054";color: #ffffff;font-size: 12px;font-family: fontawesome;background: rgba(27, 55, 92, 1);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgba(27, 55, 92, 1);
}
.slick-prev:before {position: absolute;content: "\f053";color: #ffffff;font-size: 12px;font-family: fontawesome;background: rgba(27, 55, 92, 1);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgba(27, 55, 92, 1);}
}}
/* silder css start */
.second-section {border-radius: 4px;background: #FFF;margin-top: 30px;padding: 30px 15px 15px 15px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.similer-post-heading {h2 {font-size: 32px;
font-weight: 700;text-align: left;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;
overflow: hidden;}}}
.card {padding: 20px !important;margin: 0px 10px;max-height: 250px;min-height: 250px;
.card-sub-dv {display: flex;
.company_image {border: 1px solid #ccc;width: 65px;height: 65px;border-radius: 5px;}
.company_image img {border: 1px solid #ccc;width: 65px;height: 65px;border-radius: 5px;object-fit: contain;}
.company_image img[alt] {font-size: 0.75em;}}
.footer-heading {text-align: left;
.cursor-default {cursor: default;}
p {a.remove-link-style {color: black;text-decoration: none;}}
h3 {color: #141414;font-size: 20px;font-weight: 500;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;
a {color: #141414;text-decoration: none;}}
p {color: #565656;font-size: 14px;font-weight: 400;
span {a {color: #2B85FF;text-decoration: none;font-size: 12px;margin-left: 25px;font-weight: 500;border-radius: 3px;background: rgba(43, 133, 255, 0.10);padding: 3px 6px;}}}}
.footer-list-card {text-align: left;
ul {padding: unset;
li {list-style: none;color: #565656;font-size: 16px;font-weight: 400;margin-top: 5px;
img {margin-right: 5px;display: inline-block;}}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.popular-jobs-section {.card {margin: 0px 2px;
.footer-heading {
p {color: #565656;font-size: 14px;font-weight: 400;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: flex-start;
span {a {margin-left: 0;}}}}padding: 20px !important;
.card-sub-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;
align-items: center;
.footer-heading {h3 {margin-bottom: 0px;font-size: 18px;font-weight: 500;}
p {margin-bottom: 0px;font-size: 12px;}}}}}}
@media only screen and (min-width:767px) and (max-width:992px) {
.popular-jobs-section {.card {margin: 0px 2px;
.footer-heading {p {color: #565656;font-size: 14px;font-weight: 400;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;span {a {margin-left: 0;}}
}}
padding: 20px !important;
.card-sub-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;
.footer-heading {h3 {margin-bottom: 0px;}
p {margin-bottom: 0px;align-items: flex-start;}}}}}}
@media only screen and (min-width:200px) and (max-width:320px) {
.popular-jobs-section {.card {.card-sub-dv {display: flex;flex-direction: row;flex-wrap: nowrap;
align-content: center;justify-content: flex-start;align-items: center;.footer-heading {h3 {
margin-bottom: 0px;font-size: 14px;font-weight: 500;}
p {margin-bottom: 0px;font-size: 12px;}}}}}}
@media only screen and (max-width:767px) {
.popular-jobs-section {.second-section {.similer-post-heading {h2 {
font-size: 25px;margin-left: 0px !important;font-weight: 700;text-align: left;
display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;}}
.slick-slider .slick-prev {right: 70px !important;left: unset;}
.slick-slider .slick-next,
.slick-slider .slick-prev {font-size: 0;line-height: 0;top: -15%;width: 20px;height: 20px;transform: translate(0, -50%);cursor: pointer;color: transparent;border: none;outline: 0;background: 0 0;}}}}