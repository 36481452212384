.project-search-section .search-resumes-section {
  padding: 50px;
}
.project-search-section .search-resumes-bg {
  border-radius: 4px;
  padding: 20px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.project-search-section .search-resumes-bg .tab-area .nav-tabs-custom li:nth-child(1) button {
  border-radius: 8px 0 0 8px !important;
}
.project-search-section .search-resumes-bg .tab-area .nav-tabs-custom li:nth-child(3) button {
  border-radius: 0 8px 8px 0 !important;
}
.project-search-section .search-resumes-bg .tab-area .nav-tabs-custom .nav-item .nav-link {
  border-radius: 0;
  color: #1B375C;
  border: 1px solid #1b375c;
}
.project-search-section .search-resumes-bg .tab-area .nav-tabs-custom .nav-item .nav-link.active {
  color: #fff;
  background-color: #1B375C !important;
}
.project-search-section .search-resumes-display {
  display: flex;
  justify-content: space-between;
}
.project-search-section .search-btn1 {
  border-radius: 10px;
  border: 1px solid #1B375C;
  font-size: 16px;
  background: #fff;
  color: #1B375C;
  padding: 10px 30px;
  text-decoration: none;
}
.project-search-section .search-btn2 {
  border-radius: 10px;
  border: 1px solid #1B375C;
  font-size: 16px;
  background: #fff;
  color: #1B375C;
  padding: 10px 20px;
  text-decoration: none;
}
.project-search-section .search-btn3 {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #1B375C;
  background: #1B375C;
  font-size: 16px;
  color: #FFF;
  text-decoration: none;
}
.project-search-section .search-heading h3 {
  color: #1B375C;
  font-size: 30px;
  font-weight: 700;
}
.project-search-section .custom-form-section {
  display: flex !important;
}
.project-search-section .search-custom {
  position: relative;
}
.project-search-section .search-custom .form-custom {
  height: 47px;
  width: 350px;
  padding-left: 40px;
}
.project-search-section .search-custom img {
  position: absolute;
  top: 12px;
  left: 11px;
  color: #b0a7a7;
}
.project-search-section .form-custom-btn {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #FFF;
  border: none;
  width: 144px;
  height: 47px;
  font-size: 13px;
  font-weight: 500;
}
.project-search-section .advance-icon p {
  color: #1B375C;
  margin-top: 9px;
  font-size: 18px;
  font-weight: 600;
}
.project-search-section .fitter-icon {
  height: 24px;
  width: 24px;
}
.project-search-section .filter-bg {
  border-radius: 4px;
  background: #FFF;
  padding: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.project-search-section .dropdown-custom-btn {
  border-radius: 4px;
  border: 1px solid var(--black-50, #53545C);
}
.project-search-section .dropdown-custom-section {
  display: flex;
  justify-content: flex-end;
}
.project-search-section .nav-tabs .nav-item .nav-link {
  border-radius: 0;
  color: #1B375C;
  border: none;
}
.project-search-section .nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background-color: #1B375C !important;
}
.project-search-section .nav-tabs-custom {
  border-bottom: none !important;
}
.project-search-section .candidates-heading h3 {
  color: #141414;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
}
.project-search-section .cust_row {
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  margin-top: 17px;
}
.project-search-section .photo-cus img {
  padding: 15px;
}
.project-search-section .text-left a {
  text-decoration: none;
  color: #1d375c;
}
.project-search-section .text-left a h5 {
  margin-top: 0px;
  font-size: 16px;
}
.project-search-section .cust_up ul {
  margin-top: 17px;
}
.project-search-section .filter-heading h5 {
  color: #141414;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
}
.project-search-section .cust_lis {
  padding-left: 0rem;
}
.project-search-section .day-btn button {
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(20, 20, 20, 0.1);
  background: #FFF;
  padding: 8px;
  font-weight: 400;
  font-size: 13px;
  color: #393F47;
  width: 70px;
}
.project-search-section .tab-view-custom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.project-search-section .craete-btn {
  padding: 10px 30px;
  border-radius: 10px;
  border: 1px solid #1B375C;
  color: #1B375C;
  font-size: 16px;
  background: #fff;
}
.project-search-section .card-display-section {
  display: flex !important;
  justify-content: space-between;
}
.project-search-section .project-list li {
  list-style: none;
  padding: 2px 60px 2px 1px;
}
.project-search-section .project-list ul {
  display: flex;
  padding: 0px;
}
.project-search-section .card-custom {
  padding: 20px;
}
.project-search-section .cust_main {
  color: #141414;
  font-size: 24px;
  font-weight: 500;
}
.project-search-section .create-modal-dv .btn-close {
  background: #F5F6F7 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border-radius: 50%;
}
.project-search-section .create-modal-dv .modal-body .form-group select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.project-search-section .create-modal-dv .footer-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.project-search-section .create-modal-dv .footer-btn .footer-modal-btn {
  background: #F5F6F7;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 20px;
}
.project-search-section .create-modal-dv .footer-btn .footer-modal-btn.create {
  background: #005CE8;
  color: #fff;
}

/*================================ media query start ========================================*/
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .project-search-section .search-resumes-display {
    display: flex;
    flex-direction: column;
  }
  .project-search-section .search-resumes-section {
    padding: 0px;
  }
  .project-search-section .search-btn2 {
    padding: 9px 23px;
    font-size: 12px;
  }
  .project-search-section .search-btn1 {
    margin-top: 10px;
    font-size: 12px;
  }
  .project-search-section .search-btn3 {
    padding: 9px 14px;
    font-size: 12px;
    margin-top: 10px;
  }
  .project-search-section .search-heading h3 {
    font-size: 20px;
  }
  .project-search-section .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .project-search-section .advance-icon {
    display: flex !important;
    flex-direction: column;
  }
  .project-search-section .dropdown-custom-section {
    display: flex;
    flex-direction: column;
  }
  .project-search-section .search-custom .form-custom {
    width: 237px;
    margin-top: 8px;
  }
  .project-search-section .advance-icon p {
    display: none;
  }
  .project-search-section .fitter-icon {
    display: none;
  }
  .project-search-section .craete-btn {
    padding: 0px;
    font-size: 14px;
  }
  .project-search-section .nav-link-custom-second {
    margin-top: 12px;
    padding: 5px;
  }
  .project-search-section .mx-2 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .project-search-section .mt-4 {
    margin-top: 0.5rem !important;
  }
}/*# sourceMappingURL=ProjectsSearch.css.map */