.personal-details-section {
.banner-section {border-radius: 8px;border: 1px solid rgba(20, 20, 20, 0.05);background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
.career-img {margin-top: 20px;}}
.list-section {
li {list-style: none;padding: 0px 60px 0px 0px;color: rgba(20, 20, 20, 0.7);font-size: 16px;font-style: normal;font-weight: 400;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
img {margin-right: 5px;}}}
.list-section {ul {display: flex;padding: 0;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;}}
.frame-img {border-radius: 4px;margin: 0px;}
.heading {a {padding: 2px 10px;border-radius: 3px;background: rgba(125, 90, 226, 0.1);color: #7D5AE2;font-size: 16px;font-style: normal;font-weight: 500;text-decoration: none;}}
.company-logo-name {.company-name {margin-bottom: 0px;}
.para {p {color: rgba(20, 20, 20, 0.70);font-size: 16px;font-style: normal;font-weight: 400;line-height: 30px;margin-bottom: 0px;}
span {color: #0A65CC;font-size: 16px;font-style: normal;font-weight: 500;line-height: 40px;margin-left: 80px;
a {text-decoration: none;}}}}
.bg-second {border-radius: 4px;background: #FFF;padding: 40px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
}
.bg-form {border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
}
.arror-icon {a {text-decoration: none;}}
.form-btn {background: #0A65CC;color: #FFF;text-align: center;padding: 15px 49px 17px 35px;}
.form-btn2 {border-radius: 40px;background: #1B375C;width: 300px;padding: 10px 40px;color: #FFF;font-size: 16px;font-weight: 500;}
.top-btn-continue {margin: 50px 0px;
.form-btn2 {border: none;outline: none;text-decoration: none;}}
.top-btn {text-align: center;}
.first-head {h2 {font-size: 24px;}}
.first-head {padding: 20px;}
.arror-icon {padding: 20px;}
.form-banner {padding: 0px 100px;
.before-input-cls {display: flex;}
form {input {padding: 15px 27px 17px 24px;}
select {padding: 15px 27px 17px 24px;}
.verify-icon {position: relative;
i {position: absolute;right: 35px;top: 20px;color: #06e775;}}}}}
@media only screen and (min-width:767px) and (max-width:992px) {.personal-details-section {
.form-btn {background: #0A65CC;color: #FFF;margin: 5px 0px 0px 0px;
}
.list-section {ul {flex-direction: row;li {list-style: none;padding: 0px 10px 0px 0px;color: rgba(20, 20, 20, 0.7);font-size: 16px;font-style: normal;font-weight: 400;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}}}
.para {span {line-height: inherit !important;margin-left: 0 !important;
a {
display: block;}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.personal-details-section {.form-btn {background: #0A65CC;color: #FFF;margin: 5px 0px 0px 0px;padding: 10px 10px;}
.list-section {ul {flex-direction: column;
li {list-style: none;padding: 0px 10px 0px 0px;color: rgba(20, 20, 20, 0.7);font-size: 16px;font-style: normal;font-weight: 400;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}}}
.heading {a {}}
.form-btn2 {width: 190px !important;}
.bg-second {padding: 0px !important;}
.company-logo-name {.heading {text-align: center;}
margin: 20px;
.company-name {margin-bottom: 0px;text-align: center;}}
.para {span {line-height: inherit !important;margin-left: 0 !important;
a {display: block;}}}
.form-banner {padding: 0px 9px !important;}
.top-btn {margin-top: 0px !important;}
.form-btn2 {margin-top: 10px !important;}}}