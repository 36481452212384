@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
.common-header-sec .header-second nav {z-index: 1;}
// --------------------Banner Area--------------------
.rec-header-banner {.banner-text {transform: translate(-50%, -33%);
h1 {font-size: 30px;}
button {padding: 10px 15px;font-size: 13px;}}}
.rec-bannerafter-header {.achievements-lft {h1 {font-size: 30px;text-align: center;}
p {text-align: center;}}}
.achievements-rit .counter-txt h2 {font-size: 22px;}
.job-demand .job-box2 {margin-bottom: 10px;}
.work-location h1 {font-size: 30px;}
.job-opening h1 {font-size: 30px;}
.job-opening .MuiTabs-flexContainer .MuiButtonBase-root {font-size: 15px;}
.benifits .key-benifits .heading-txt h1 {font-size: 30px;}
.job-demand h1 {font-size: 30px;}.impaneled-companies h1 {font-size: 30px;}
.job-fair h1 {font-size: 30px;}
.testimonial h1 {font-size: 30px;}
.heading-txt {margin: 10px 0 !important;}}
@media only screen and (min-width:320px) and (max-width:767px) {}
@media only screen and (min-width:200px) and (max-width:319px) {
.common-header-sec .main-header .row>.col-lg-6 {justify-content: center;}
.common-header-sec .main-header .head-sec-lft p {font-size: 13px;}.common-header-sec .main-header .head-sec-lft {
margin-bottom: 15px;}
.common-header-sec .header-second nav {z-index: 1;}
// --------------------Banner Area--------------------
.rec-header-banner {.banner-text {transform: translate(-50%, -40%);
h1 {font-size: 16px;margin-bottom: 5px;}
button {width: 125px;height: 35px;font-size: 10px;padding: 0;}
.footer-parent-sec {.ft-rt-dv {.inner-img-dv {text-align: center;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}}}}}
// --------------------Banner After Area--------------------
.rec-bannerafter-header {padding: 30px;
.achievements-lft {h1 {font-size: 26px;line-height: 38px;text-align: center;}
p {text-align: center;}}}
.achievements-rit {display: flex;justify-content: flex-start;align-items: center;flex-direction: row;flex-wrap: nowrap;align-content: center;  
.counts {justify-content: flex-start;}
.counter-txt {h2 {font-size: 22px;}}}
// --------------------Work Location Area--------------------
.rec-work-location {h1 {font-size: 30px;}
.heading-txt {margin: 0px 0 !important;}}
// --------------------Job Opening Area--------------------
.rec-job-opening {h1 {font-size: 30px;}
.MuiTabs-flexContainer {
.MuiButtonBase-root {font-size: 15px;margin: 0;}}}
// --------------------Benefits Area--------------------
.rec-benifits {.key-benifits {padding: 20px;
.heading-txt {h1 {font-size: 30px;}}}}
// --------------------Job Demand Area--------------------
.rec-job-demand {h1 {font-size: 30px;}
.job-box {h4 {font-size: 18px;}
.company button {font-size: 14px;}}
.job-box2 {margin-bottom: 20px;
h4 {font-size: 18px;}
.company button {font-size: 14px;}}}
// --------------------Impaneled Companie Area--------------------
.rec-impaneled-companies {h1 {font-size: 30px;}}
// --------------------Job Fair Area--------------------
.rec-job-fair {padding: unset;
h1 {font-size: 30px;}
.heading-txt {p {text-align: center;}}}
.software {margin-bottom: 15px;
.intro {h4 {font-size: 16px;}}}
// --------------------Testimonial Area--------------------
.rec-testimonial {h1 {font-size: 30px;}}}