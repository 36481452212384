.company-details-form {.form-head-dv {box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);border-radius: 40px;padding: 30px;margin: 60px 0px;
.checkbox-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin-top: 2rem;
.checkbox-1 {margin: 0px 10px;
input {margin-right: 10px;}
.a-tag-color {color: #1B375C;font-weight: 600;}}
.checkbox-2 {margin: 0px 10px;
input {margin-right: 10px;}
.a-tag-color {color: #1B375C;font-weight: 600;}}}
.check-circle-parent {.check-circle-relative {position: relative;
.greencheck-circle-absolute {position: absolute;top: 45%;right: 1%;transform: translate(-1%, -45%);}}}
.next-button {margin-top: 30px;
.next-btn {box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 125px;border: none;margin-top: 4rem;text-decoration: none;}}
.cd-heading {text-align: left;
h2 {font-size: 28px;}}
.cd-form-f {width: 100%;text-align: left;
label {margin-bottom: 5px;}
input {border: 1px solid rgba(173, 173, 173, 1);width: 100%;border-radius: 9px;padding: 7px 15px 7px 15px;}
.input-dropdown {border: 1px solid rgb(173, 173, 173);width: 100%;border-radius: 9px;padding: 7px 15px 7px 15px;}}
.cd-form-f-btn {input {background: linear-gradient(0deg, #0A65CC, #0A65CC),linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));border: 1px solid rgba(255, 255, 255, 0.35);color: #fff;padding: 8px 15px 8px 15px;}
button.verify-profile-btn {background: linear-gradient(0deg, #0A65CC, #0A65CC),linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));border: 1px solid rgba(255, 255, 255, 0.35);color: #fff;padding: 8px 15px 8px 15px;width: 100%;border-radius: 9px;}
margin-top: 26px;}}}
.overlay-form-submission {position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(255, 255, 255, 0.8);/* Adjust the background color and opacity */display: flex;justify-content: center;align-items: center;z-index: 999;
}
.create-an-account-popup {#signup-otp {backdrop-filter: blur(5px);}}
@media only screen and (min-width: 200px) and (max-width: 767px) {.company-details-form {.form-head-dv {.next-button {.next-btn {box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 70px;border: none;margin-top: 4rem;text-decoration: none;}}
.checkbox-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: center;align-items: flex-start;margin-top: 1rem;
.checkbox-1 {margin: 0px 10px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
label {font-size: 13px;}}
.checkbox-2 {margin: 0px 10px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
label {font-size: 13px;}}}}}}