.describe-the-job-sec {margin: 40px 0px;

.create-an-emp-inner {box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;padding: 40px;
.heading {h2 {font-weight: 600;margin-bottom: 8px;}}
.heading-inner {h3 {font-size: 22px;font-weight: 600;}}
.card-outer-dv {border: 1px solid rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);border-radius: 10px;padding: 15px 40px;margin: 20px 0px;
.job-type-btn {.btn-circle {border: 1px solid rgba(27, 55, 92, 1);color: #1B375C;border-radius: 40px;background-color: #fff;padding: 10px 0px;width: 100%;font-size: 15px;
&:hover {background-color: #1B375C;color: #fff;}}}
label {font-weight: 600;}
.inner-input {.text-area-custom {.textarea-combo-dv {border: 1px solid #66666659;border-radius: 12px;}
textarea {width: 100%;padding: 10px;border: none;border-radius: 12px;
&:focus-visible {border: none;outline: none;}
&:focus {border: none;outline: none;}}
.text-icons {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;padding: 10px 20px;border-radius: 12px;
.type-sub-task {img {margin-right: 10px;}
span {margin-right: 10px;}
.type-sub-task-a {text-decoration: none;color: inherit;}}.three-icons {img {margin: 0px 10px;}}}}}
.right-sd-btn {.continue-btn {background: rgba(27, 55, 92, 1);color: #fff;border-radius: 40px;padding: 10px 40px;text-decoration: none;
span {margin-left: 10px;}}}}
.card-outer-dv1 {padding: 30px 40px;}
.bottom-button-dv {margin: 40px 0px;
.bottom-btn {.a-tag-btn1 {border: 1px solid rgba(27, 55, 92, 1);text-decoration: none;border-radius: 40px;color: #1B375C;padding: 10px 50px;}
.a-tag-btn2 {border: 1px solid rgba(27, 55, 92, 1);text-decoration: none;border-radius: 40px;background: rgba(27, 55, 92, 1);color: #fff;padding: 10px 40px;}
.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: none;text-decoration: none;}}}}}
@media only screen and (min-width:768px) and (max-width:992px) {}
@media only screen and (min-width:200px) and (max-width:767px) {
.describe-the-job-sec {.create-an-emp-inner {padding: 10px;.card-outer-dv {padding: 10px 10px;
.inner-input {.text-area-custom {.textarea-combo-dv {border: 1px solid #66666659;border-radius: 12px;}
textarea {width: 100%;padding: 10px;border: none;border-radius: 12px;
&:focus-visible {border: none;outline: none;}
&:focus {border: none;outline: none;}}
.text-icons {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;padding: 10px 20px;border-radius: 12px;
.type-sub-task {img {margin-right: 10px;}
span {margin-right: 10px;}
.type-sub-task-a {text-decoration: none;color: inherit;font-size: 14px;}}
.three-icons {img {margin: 0px 10px;}}}}}}
.bottom-button-dv {text-align: center;margin: 40px 0px;
.bottom-btn {margin: 10px 0px;text-align: center;}}}}}