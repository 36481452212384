.jobs-section .apply-job-section .img {
  width: 75px;
  height: 75px;
}
.jobs-section .apply-job-section .img img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.jobs-section .apply-job-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: #2d2d2d;
}
.jobs-section .jobs h4 {
  font-size: 18px;
  font-weight: 600;
  color: #595959;
}
.jobs-section .progress-bar {
  background-color: #9d2b6b;
}
.jobs-section .progress {
  height: 8px;
}
.jobs-section .border-bottom {
  border-bottom: 1px solid #e4e2e0;
}
.jobs-section span {
  font-size: 13px;
}
.jobs-section .border-dotted {
  border-bottom: 1px dashed #949494;
}
.jobs-section .share-btn {
  padding: 8px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
}
.jobs-section .share-btn svg {
  fill: #767676 !important;
  width: 20px !important;
  height: 20px !important;
}
.jobs-section .share-btn:hover, .jobs-section .rating-btn:hover {
  color: rgb(22, 64, 129);
  background-color: rgb(238, 241, 254);
}
.jobs-section .rating-btn {
  padding: 5px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: #f3f2f1;
  border: none;
}
.jobs-section .feedback p {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  line-height: 24px;
}
.jobs-section .feedback svg {
  width: 13px;
  height: 15px;
  fill: #9d2b6b;
}
.jobs-section .useful-review {
  background: #eef1fe;
  border-radius: 8px;
  padding: 12px 15px;
}
.jobs-section .useful-review span {
  color: #164081;
}
.jobs-section .useful-review h2 {
  color: #164081;
  font-weight: 600;
  font-size: 17px;
}
.jobs-section .review-box-container {
  flex-wrap: wrap;
}
.jobs-section .review-box-container .review-box {
  padding: 6px 14px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  background: #f3f2f1;
}
.jobs-section h3 {
  color: #2d2d2d;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25;
}
.jobs-section h5, .jobs-section h4 {
  font-weight: 400;
  color: #595959;
}
.jobs-section h5 {
  font-size: 18px;
}
.jobs-section h4 {
  font-size: 16px;
}
.jobs-section .form-section .sort-rating, .jobs-section .form-section-2 .sort-rating {
  border-left: 1px solid #b6b6b6;
  border-radius: 6px 0 0 6px;
  border-right: 1px solid #b6b6b6;
}
.jobs-section .form-section .date, .jobs-section .form-section-2 .date {
  border-radius: 0px 6px 6px 0px;
  border-right: 1px solid #b6b6b6;
}
.jobs-section .form-section .sort-rating, .jobs-section .form-section .date, .jobs-section .form-section-2 .sort-rating, .jobs-section .form-section-2 .date {
  padding: 6px 14px;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.jobs-section .form-section label, .jobs-section .form-section-2 label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 6px;
}
.jobs-section .form-section .rating, .jobs-section .form-section-2 .rating {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.jobs-section .form-section .rating .box, .jobs-section .form-section-2 .rating .box {
  border: 1px solid #b6b6b6;
  padding: 6px 14px;
  background: #fff;
  border-radius: 50px;
}
.jobs-section .form-section .rating span, .jobs-section .form-section-2 .rating span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}
.jobs-section .form-section .rating svg, .jobs-section .form-section-2 .rating svg {
  width: 20px;
  fill: #9d2b6b;
}

.form-control:focus {
  border-color: #2557a7;
  box-shadow: inset 0 -3px 0 0 #2557a7;
}

.claimed-profile h4 {
  color: #595959;
  font-weight: 600;
  font-size: 16px;
}
.claimed-profile svg {
  fill: #1f662c;
  width: 25px;
}

.update-button {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}

.update-button:hover {
  background-color: rgba(59, 124, 227, 0.0705882353);
}

.accordion h4 {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #2d2d2d;
}

.rate-recent-company {
  background: #eef1fe;
  border-radius: 8px;
  padding: 30px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rate-recent-company .rate-star {
  background: #eef1fe;
  border-radius: 6px;
  padding: 6px 15px;
  width: -moz-fit-content;
  width: fit-content;
}
.rate-recent-company p {
  font-size: 14px;
}
.rate-recent-company h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5rem;
  color: #2d2d2d;
}
.rate-recent-company svg {
  width: 25px !important;
  height: 25px !important;
}

.rounded-box-container {
  border-radius: 0.5rem;
  border: 1px solid #d4d2d0;
}
.rounded-box-container .box-rounded-div {
  border-bottom: 1px solid #d4d2d0;
  padding: 15px 28px;
}
.rounded-box-container .box-rounded-div .job-dropdown-btn {
  background: none;
  border: none;
  border-radius: 6px;
  padding: 10px;
}
.rounded-box-container .box-rounded-div .job-dropdown-btn:hover {
  background: #eef1fe;
}
.rounded-box-container .box-rounded-div .job-update-btn {
  background-color: #f3f2f1;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}
.rounded-box-container .box-rounded-div h4 {
  font-size: 20px !important;
  font-weight: 600;
}
.rounded-box-container .box-rounded-div p {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
}

.box-rounded {
  border-radius: 0.5rem;
  padding: 15px 12px;
  border: 1px solid #d4d2d0;
}
.box-rounded .job-dropdown-btn {
  background: none;
  border: none;
  border-radius: 6px;
  padding: 10px;
}
.box-rounded .job-dropdown-btn:hover {
  background: #eef1fe;
}
.box-rounded .job-update-btn {
  background-color: #f3f2f1;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}
.box-rounded h4 {
  font-size: 20px !important;
  font-weight: 600;
}
.box-rounded p {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
}

.card-title,
.card-subtitle {
  font-size: 0.75rem !important;
  color: #495057;
}

.nested-list {
  margin-left: 1.5rem;
  font-weight: 400;
}

.list-unstyled li {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.list-style li {
  list-style: disc !important;
}

.role-box {
  max-height: 427px;
  overflow-y: auto;
  padding: 12px 28px;
}

.job-status-div {
  max-height: 585px;
  overflow-y: auto;
}

.relative {
  position: relative;
}
.relative svg {
  position: absolute;
  top: 8px;
  right: 10px;
  fill: #767676;
}

@media (max-width: 992px) {
  .find_jobs_btn {
    font-size: 14px !important;
    padding: 9px 12px !important;
  }
}
@media (max-width: 767px) {
  .find_jobs_btn {
    margin-top: 15px;
  }
}/*# sourceMappingURL=Jobs.css.map */