.recruiter-advice .header-banner {
  height: 450px;
  background-image: url(../../../../../public/assets/images/recruiter-advice/banner.png);
  background-position: 100%;
  background-size: cover;
}

.custm-rec-sec .recruiter-detail {
  padding: 20px 0 0 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-rec-sec .head-area h2 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 48px;
  font-weight: 700;
  padding: 0 0 30px 0;
}
.custm-rec-sec .custm-bud-text h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 28px;
  font-weight: 700;
  padding: 50px 0 0 0;
}
.custm-rec-sec .custm-bud-text p {
  text-align: left;
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
  padding: 0 60px 0 0;
}
.custm-rec-sec .custm-bud-img img {
  width: 400px;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  border-radius: 25px;
  border: 10px solid #fff;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .custm-rec-sec .head-area h2 {
    font-size: 22px;
    padding: 20px 0;
  }
  .custm-rec-sec .recruiter-detail {
    padding: unset;
  }
  .custm-rec-sec .recruiter-detail .custm-bud-text h4 {
    font-size: 22px;
    padding: unset;
  }
  .custm-rec-sec .recruiter-detail .custm-bud-text p {
    padding: unset;
    font-size: 15px;
  }
}/*# sourceMappingURL=recruiter-advice.css.map */