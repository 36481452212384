.review-page-section {
  margin: 20px 0px;
}
.review-page-section .you-used-a-previous-d {
  background-color: rgb(197, 214, 219);
  padding: 10px;
  border-radius: 5px;
}
.review-page-section .you-used-a-previous-d .verify-row {
  display: flex;
}
.review-page-section .job-details-head-parent {
  margin: 10px 0px;
}
.review-page-section .job-details-head-parent .submit-btn button {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 40px;
  padding: 10px 40px;
  text-decoration: none;
}
.review-page-section .job-details-head-parent .you-used-inner .heading-edit-icon-heading {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px;
}
.review-page-section .job-details-head-parent .you-used-inner .heading-edit-icon-heading p {
  margin-bottom: 0px;
  width: 33%;
}
.review-page-section .job-details-head-parent .you-used-inner .heading-edit-icon-heading .jobtitle-hide-js button {
  background: #1B375C;
  color: #fff;
  box-shadow: none;
  border: 1px solid #1B375C;
  margin-left: 10px;
  padding: 0px 12px;
}
.review-page-section .job-details-head-parent .you-used-inner .heading-edit-icon-heading .pen-cls {
  padding-right: 20px;
  text-align: right;
}

.review-page-banner-section .banner-inner-dv img.banner-img {
  width: 100% !important;
}/*# sourceMappingURL=JobFairCreateJobPage.css.map */