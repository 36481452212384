.blog-list .blog-head {
  position: relative;
  border-radius: 4px;
  background: #f4faff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
  padding: 15px 0;
}
.blog-list .blog-head h1 {
  color: #1b375c;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}
.blog-list .blog-head span {
  position: absolute;
  left: 0;
  padding-left: 20px;
  color: #0A65CC;
}
.blog-list .blog-head span .fa-arrow-left {
  margin-right: 10px;
}
.blog-list .blog-info {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  margin-bottom: 80px;
}
.blog-list .blog-info .blog-card .category img {
  width: 16px;
  height: 16px;
}
.blog-list .blog-info .blog-card .category h5 {
  margin: 0 12px;
  padding: 5px 35px;
  border-radius: 2px;
  background: #d3e7f5;
  color: var(--Text, #151875);
  font-size: 14px;
  font-weight: 400;
}
.blog-list .blog-info .blog-card .blog-category div.share-btn-blog .st-center .st-btn {
  display: inline-block !important;
}
.blog-list .blog-info .blog-card .date img {
  width: 16px;
  height: 16px;
}
.blog-list .blog-info .blog-card .date h5 {
  margin: 0 12px;
  padding: 5px 15px;
  border-radius: 2px;
  background: #ffe7f9;
  color: var(--Text, #151875);
  font-size: 14px;
  font-weight: 600;
}
.blog-list .blog-info .blog-card .blog-title h2 {
  text-align: left;
  margin: unset;
  color: #1b375c;
  font-family: Barlow;
  font-size: 28px;
  font-weight: 700;
}
.blog-list .blog-info .blog-card .blog-para {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  color: var(--sub-text-color, #8a8fb9);
  font-size: 16px;
  font-weight: 400;
}
.blog-list .blog-info .blog-card .blog-para2 p {
  text-align: left;
  color: #8a8fb9;
  font-size: 16px;
  font-weight: 400;
}
.blog-list .blog-info .blog-card .blog-para3 {
  background: #fafafb;
  padding: 0 10px;
  border-left: 2px solid #fc45a0;
}
.blog-list .blog-info .blog-card .blog-para3 p {
  text-align: left;
  color: #969cb4;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 40px;
}
.blog-list .blog-info .blog-card .read-btn a {
  background-color: unset;
  border: unset;
  color: #1b375c;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.blog-list .blog-info .view-all-btn a {
  background-color: unset;
  border: unset;
  color: #1b375c;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.blog-list .blog-info .blog-media .media-box1 {
  position: relative;
}
.blog-list .blog-info .blog-media .media-box1 img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-list .blog-info .blog-media .textarea p {
  text-align: left;
  color: #8a8fb9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
}
.blog-list .blog-info .category-box {
  margin-bottom: 40px;
}
.blog-list .blog-info .category-box h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-list .blog-info .category-box h5 {
  text-align: left;
  border-radius: 3px;
  background: #1B375C;
  color: #FFF;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.blog-list .blog-info .category-box ul {
  padding: unset;
  display: unset;
  justify-content: flex-start;
}
.blog-list .blog-info .category-box ul li {
  width: 100%;
  word-break: break-word;
  list-style: none;
}
.blog-list .blog-info .category-box ul li:hover {
  background-color: #1B375C;
  color: white;
}
.blog-list .blog-info .category-box ul.d-flex-remove {
  display: unset !important;
}
.blog-list .blog-info .search-box {
  margin-bottom: 30px;
}
.blog-list .blog-info .search-box h4 {
  text-align: left;
  color: #1b375c;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
}
.blog-list .blog-info .search-box .custm-search {
  position: relative;
}
.blog-list .blog-info .search-box .custm-search img {
  position: absolute;
  right: 12px;
  top: 12px;
}
.blog-list .pagination-section nav.nav-class ul.pagination {
  justify-content: start;
}
.blog-list li:hover {
  color: #1B375C;
}/*# sourceMappingURL=blog.css.map */