// Define variables for reusable styles
$modal-width: 350px;
$modal-height: 500px;
$primary-color: #00D0EA;
$secondary-color: #1b375c;
$border-radius: 10px;

// Mixin for Flexbox alignment
@mixin flex-center {display: flex;align-items: center;justify-content: center;}
// Chat Modal Styling
.modal-chat {width: $modal-width;height: $modal-height;position: fixed;bottom: 10px;right: 10px;max-width: 100%;margin: 0;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);border-radius: $border-radius;overflow: hidden;
.modal-content {height: 100%;display: flex;flex-direction: column;border: none;}
.modal-header {flex-shrink: 0;background-color: $primary-color;color: white;padding: 10px;border-bottom: none;
.btn-close {color: white;opacity: 1;
&:hover {color: $secondary-color;}}}
.modal-body {flex-grow: 1;overflow-y: auto;padding: 10px;background-color: #f9f9f9;
// Use existing styles for chat
.chat-progress {font-family: helvetica;display: flex;flex-direction: column;align-items: center;
.chat {width: 100%;border: solid 1px #EEE;display: flex;flex-direction: column;padding: 10px;}
.messages {margin-top: 30px;display: flex;flex-direction: column;}
.message {border-radius: 20px;padding: 8px 15px;margin-top: 5px;margin-bottom: 5px;display: inline-block;word-break: break-all;}
.yours {align-items: flex-start;
.message {margin-right: 25%;background-color: #eee;position: relative;word-break: break-all;
&.last {
&:before {content: "";position: absolute;z-index: 0;bottom: 0;left: -7px;height: 20px;width: 20px;background: #eee;border-bottom-right-radius: 15px;}
&:after {content: "";position: absolute;z-index: 1;bottom: 0;left: -10px;width: 10px;height: 20px;background: white;border-bottom-right-radius: 10px;}}}}
.mine {align-items: flex-end;
.message {color: white;margin-left: 25%;background: linear-gradient(to bottom, $primary-color 0%, $secondary-color 100%);background-attachment: fixed;position: relative;
&.last {
&:before {content: "";position: absolute;z-index: 0;bottom: 0;right: -8px;height: 20px;width: 20px;background: linear-gradient(to bottom, hsl(187, 100%, 46%) 0%, $secondary-color 100%);background-attachment: fixed;border-bottom-left-radius: 15px;}
&:after {content: "";position: absolute;z-index: 1;bottom: 0;right: -10px;width: 10px;height: 20px;background: white;border-bottom-left-radius: 10px;}}}}}}
.custom-footer {flex-shrink: 0;padding: 10px;background-color: white;border-top: 1px solid #e5e5e5;
.chat-input {border-color: #2557a7;box-shadow: inset 0 -3px 0 0 #2557a7;}
.form-control {width: calc(100% - 50px);display: inline-block;}
.btn {padding: 5px 10px;margin-left: 5px;}}}
// Responsive design
@media (max-width: 767px) {
.modal-chat {width: 100%;height: 100%;bottom: 0;right: 0;border-radius: 0;}}