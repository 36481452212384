.rec-notification-view-sec{.main-head {padding: 1px;border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;}
.main-head h2 {color: #1B375C;font-size: 22px;font-style: normal;margin: 0px;line-height: 54px;text-align: center;text-transform: capitalize;
}.main-section {padding: 40px;.banner-section{box-shadow: 0px 20px 60px 0px #F1F4F880;box-shadow: -4px -4px 20px 0px #1B375C33 inset;padding: 25px;}}
.first-head h2 {color: #1B375C;font-size: 60px;font-style: normal;font-weight: 700;line-height: 137.5%;margin-top: 152px;}
.first-head span {color: #0A65CC;font-size: 60px;font-style: normal;font-weight: 700;line-height: 137.5%;}
.point-icon {height: 10px;width: 10px;border: 2px solid red;background: red;border-radius: 10PX;}
.point-section {display: flex;justify-content: space-between;border-radius: 8px;background: #F9F9F9;padding: 10px;}
.point-section-second {border-radius: 8px;padding: 15px;}
.point-section-third {border-radius: 8px;background: #F9F9F9;padding: 15px;}
.point-para p {color: #1C1C1E;font-size: 14px;font-weight: 500;line-height: 18px;margin-bottom: 0px}
.point-para span {color: rgba(28, 28, 30, 0.72);font-size: 12px;font-weight: 500;line-height: 18px;}
.point-para a {color: #0049FC;text-decoration: none;font-size: 14px;font-style: normal;font-weight: 600;}}
@media only screen and (min-width:200px) and (max-width:767px) {.rec-notification-view-sec{.first-head h2 {font-size: 18px;}
.first-head span {font-size: 18px;}.main-section {padding: 0px;}}}