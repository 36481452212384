.resume-template .head-area h2 {
  color: #1B375C;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}
.resume-template .custm-bud-text h4 {
  text-align: left;
  color: #1B375C;
  font-size: 28px;
  font-weight: 700;
  padding: 50px 0 0 0;
}
.resume-template .custm-bud-text p {
  text-align: left;
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
  padding: 0 60px 0 0;
}
.resume-template .custm-bud-img img {
  width: -moz-fit-content;
  width: fit-content;
  height: 600px;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 10px;
  border-radius: 25px;
  border: 10px solid #fff;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .resume-template .custm-bud-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
       object-fit: fill;
    border-radius: 10px;
    border-radius: 25px;
    border: 10px solid #fff;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .sponsor {
    padding-top: 20px !important;
  }
  .sponsor .head-area {
    margin-bottom: 0 !important;
  }
  .sponsor .head-area h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
  }
  .sponsor .custm-bud-img img {
    width: 100%;
    height: auto;
  }
  .sponsor .custm-bud-text h4 {
    padding: 20px 0 0 0;
    font-size: 18px;
  }
  .sponsor .custm-bud-text p {
    padding: 0px;
    font-size: 15px;
  }
}/*# sourceMappingURL=resumeTemplate.css.map */