.terms-condition-sec {
  margin: 50px 0px;
}
.terms-condition-sec .term-condi-child-dv {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 0px 20px;
}
.terms-condition-sec .term-condi-child-dv .top-heading h2 {
  font-size: 22px;
  font-weight: 700;
  padding: 20px 0px;
}
.terms-condition-sec .term-condi-child-dv .list-items .list-items-heading h5 {
  font-size: 20px;
  font-weight: 700;
}
.terms-condition-sec .term-condi-child-dv .list-items .list-items-heading p {
  text-align: justify;
}
.terms-condition-sec .text-center .provide-hyperlink {
  cursor: pointer;
  text-decoration-line: underline;
  color: #007bff;
}

.privacy-policy-sec {
  margin: 50px 0px;
}
.privacy-policy-sec .term-condi-child-dv {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 0px 20px;
}
.privacy-policy-sec .term-condi-child-dv .top-heading h2 {
  font-size: 22px;
  font-weight: 700;
  padding: 20px 0px;
}
.privacy-policy-sec .term-condi-child-dv .list-items .list-items-heading h5 {
  font-size: 20px;
  font-weight: 700;
}
.privacy-policy-sec .term-condi-child-dv .list-items .list-items-heading p {
  text-align: justify;
}
.privacy-policy-sec .container .text-center .provide-hyperlink {
  cursor: pointer;
  text-decoration-line: underline;
  color: #007bff;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .terms-condition-sec {
    margin: 50px 0px;
  }
  .terms-condition-sec .term-condi-child-dv {
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    padding: 0px 20px;
  }
  .terms-condition-sec .term-condi-child-dv .top-heading h2 {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0px;
  }
  .terms-condition-sec .term-condi-child-dv p {
    line-break: anywhere;
  }
  .terms-condition-sec .term-condi-child-dv p a {
    line-break: anywhere;
  }
  .terms-condition-sec .term-condi-child-dv .after-last-updated-content p {
    font-size: 14px;
  }
  .terms-condition-sec .term-condi-child-dv .list-items .list-items-heading h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .terms-condition-sec .term-condi-child-dv .list-items .list-items-heading p {
    text-align: justify;
    font-size: 14px;
  }
  .privacy-policy-sec {
    margin: 50px 0px;
  }
  .privacy-policy-sec .term-condi-child-dv {
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    padding: 0px 20px;
  }
  .privacy-policy-sec .term-condi-child-dv .top-heading h2 {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0px;
  }
  .privacy-policy-sec .term-condi-child-dv .after-last-updated-content p {
    font-size: 14px;
  }
  .privacy-policy-sec .term-condi-child-dv .list-items .list-items-heading h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .privacy-policy-sec .term-condi-child-dv .list-items .list-items-heading p {
    text-align: justify;
    font-size: 14px;
  }
}/*# sourceMappingURL=OurPolicies.css.map */