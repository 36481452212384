.review-rate-modal-head-cls {.modal-content {border-radius: 20px;.modal-review {.rating_class {.rating-news-p {color: #ccc;margin-bottom: 0px;
b {color: #6e6e6e;}}
position: relative;
.rate {padding: 0 10px;display: flex;flex-direction: row-reverse;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;}
.rate:not(:checked)>input {position: absolute;top: -9999px;}
.rate:not(:checked)>label {float: right;width: 1em;overflow: hidden;white-space: nowrap;cursor: pointer;font-size: 30px;color: #ccc;}
.rate:not(:checked)>label:before {content: '★ ';}
.rate>input:checked~label {color: #ffc700;}
.rate:not(:checked)>label:hover,.rate:not(:checked)>label:hover~label {color: #deb217;}
.rate>input:checked+label:hover,.rate>input:checked+label:hover~label,.rate>input:checked~label:hover,.rate>input:checked~label:hover~label,.rate>label:hover~input:checked~label {color: #c59b08;}}
.rating_textarea {.textarea-tag {width: 100%;border-radius: 2px;padding: 5px 10px;border-color: #ccc;}}}
.modal-header {border-radius: 20px;padding: 10px 10px;}
.modal-footer {border-top: 0px;justify-content: space-between;
.later-btn-color {background-color: #ccc;border-color: #ccc;color: #000;}
.submit-btn-color {background-color: #1B375C;border-color: #1B375C;border-radius: 40px;}}}
}