.assessment-all-question-time-sec .boxshadow_outer {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  margin: 10px;
  padding: 20px;
}
.assessment-all-question-time-sec .cust-link {
  padding-left: 0px;
}
.assessment-all-question-time-sec .heading {
  font-size: 32px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 20px;
}
.assessment-all-question-time-sec .cust_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.assessment-all-question-time-sec .cust_outer {
  margin-top: 15px;
  margin-bottom: 15px;
}
.assessment-all-question-time-sec .marging_header {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and ((min-width: 320px) and (max-width: 576px)) {
  .assessment-all-question-time-sec .heading {
    font-size: 20px;
  }
}/*# sourceMappingURL=AssessmentAllQuestionTime.css.map */