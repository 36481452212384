.career-result-details-section {margin: 20px 0px;
.career-section {background-image: url("../../../../../../public/assets/career-start/career-start-banner.png");
background-position: 100%;background-size: cover;padding: 85px;
.career-top-heading {
h2 {text-align: center;font-size: 50px;font-weight: 400;}
span {text-align: center;color: #1B375C;font-size: 50px;font-weight: 700;}}
.career-second-heading {h6 {color: #1B375C;font-size: 18px;font-weight: 500;background-color: #fff;
width: 40%;border-radius: 10px;padding: 5px;}
span {color: #1B375C;font-size: 18px;font-weight: 700;}}
.custom-form-section {display: flex !important;}}
.search-custom {position: relative;width: 75vw;&:nth-child(1) {.form-custom {border-radius: 5px 0px 0px 5px;}
}
&:nth-child(2) {.form-custom {border-radius: 0px;}}
&:nth-child(3) {.form-custom {border-radius: 0px;}}
.form-custom {height: 53px;}
input {padding-left: 40px;}
select {padding-left: 40px;}
input {&::placeholder {padding: 0 0 0 50px;}}
select {&::placeholder {padding: 0 0 0 50px;}}
img {position: absolute;top: 11px;left: 15px;color: #b0a7a7;}}
.form-custom-btn {button {border-radius: 0px 8px 8px 0px;background: #1B375C;color: #FFF;width: 102px;height: 53px;font-size: 15px;font-weight: 500;}}
.dropdown-custom-btn {display: flex !important;justify-content: flex-start;margin-top: 20px;flex-direction: row;flex-wrap: wrap;align-content: center;align-items: center;.dropdown {margin: 10px 10px;}}
.dropdown-second-custom-btn {display: flex;justify-content: space-evenly;margin-top: 10px;}
.btn-custom {border-radius: 7px;background: #FFF;color: #1B375C;font-size: 18px;font-weight: 600;border: none;
}
.career-second-section {.ptn {
.top-para {margin-bottom: 0px;}
a.remove-link-style {text-decoration: none;color: black;}}.card-section {min-height: 100%;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;padding: 30px;
.pagintion-custom {.pagination-div {.page-item {.page-link {cursor: pointer !important;}}}}
.form-heading {h3 {color: #141414;font-size: 30px;font-weight: 600;}}
.form-desgin {border-radius: 8px;border: 1px solid #0A65CC;background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
padding: 19px;.search-result-section-img {border: 1px solid #ccc;width: 65px;height: 65px;border-radius: 5px;}}
.ptn {.top-para {p {color: #141414;font-size: 18px;font-weight: 400;}}
.heading {h2 {color: #141414;font-size: 24px;font-weight: 500;a {color: #000;text-decoration: none;}.tile {padding: 2px 6px;border-radius: 3px;background: rgba(125, 90, 226, 0.10);color: #7D5AE2;font-size: 16px;font-style: normal;font-weight: 500;text-decoration: none;
}}}
.list-section {ul {display: flex;justify-content: space-between;padding: unset;li {list-style: none;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;font-size: 12px;img {margin-right: 5px;}}}
}
div.para {p {display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical;overflow: hidden;
text-align: justify;}}}}
.info-section {border-radius: 4px;background: #FFF;
padding: 25px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.top-header {display: flex;justify-content: space-between;align-items: flex-start;
flex-direction: row;flex-wrap: wrap;align-content: center;
.top-header-child {display: flex;
.top-head {margin-left: 10px;
h3 {font-size: 21px;margin-bottom: 0px;}
p {margin-top: 10px;
span {a {text-decoration: none;color: #000;}
&:nth-child(2) {background: rgb(11, 160, 44);color: #fff;border-radius: 3px;padding: 2px 10px;margin: 0px 5px;}
&:nth-child(3) {background: rgba(255, 237, 237, 1);color: rgba(224, 81, 81, 1);border-radius: 20px;padding: 2px 10px;margin: 0px 5px;}}}}
.company_image {img {border: 1px solid #ccc;width: 65px;height: 65px;border-radius: 5px;max-width: 65px;max-height: 65px;}}
.company_image img[alt] {font-size: 0.75em;}}
.top-btn {a {text-decoration: none;}}}
.bookmark-apply-dv {margin-top: 20px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;
.bookmark-icon {}
.apply-btn-bookmark {padding: 15px 32px 15px 32px;background: rgba(10, 101, 204, 1);color: #fff;border-radius: 5px;margin-left: 10px;
.apply-tag {text-decoration: none;color: #fff;background-color: transparent;border: none;box-shadow: none;}}
.apply-btn-bookmark-applied {padding: 15px 32px 15px 32px;background: rgb(2, 175, 31);color: #fff;border-radius: 5px;margin-left: 10px;
.apply-tag {text-decoration: none;color: #fff;background-color: transparent;border: none;box-shadow: none;}}}
.salary-location-dv {margin: 20px 0px;border: 2px solid rgba(231, 240, 250, 1);padding: 15px;border-radius: 8px;
p {margin-bottom: 0px;}
.border-right {border-right: 2px solid #ccc;}
.left-section {h5 {margin-bottom: 0px;color: rgba(11, 160, 44, 1);}}
.left-section2 {h5 {margin-bottom: 0px;}}}
.left-card-second {border: 2px solid rgba(231, 240, 250, 1);border-radius: 8px;
.border-bottom-cus {border-bottom: 2px solid rgba(231, 240, 250, 1);}
.list-head {margin-bottom: 10px;h3 {font-size: 20px;}}
.list-top-heading {padding: 20px;
.card-list {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;
align-items: center;
.social-media-parent {margin-right: 10px;}}}
.card-pad {margin-bottom: 20px;
img {margin-bottom: 10px;}
p {margin-bottom: 0px;color: #7c7b7b;}
h6 {font-size: 15px;}}}
.info-section-heading {padding: 30px;
.job-description-heading {}
.info-dv-para-scroll {height: 600px;overflow-y: scroll;text-align: justify;&::-webkit-scrollbar {background-color: transparent;
width: 8px;box-shadow: unset;}
&::-webkit-scrollbar-track {-webkit-box-shadow: unset;background-color: transparent;}
.info-dv-para {padding: 0 5px;}}}}}}

@media only screen and (min-width:768px) and (max-width:992px) {
.career-result-details-section {
.career-section .dropdown-custom-btn {display: flex !important;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;
.dropdown {margin: 5px 7px;}}
.dropdown-custom-btn {display: flex !important;margin-top: 30px;flex-direction: column;}
.career-section .custom-form-section {display: flex !important;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;}
.dropdown-second-custom-btn {display: flex !important;flex-direction: row;flex-wrap: wrap;align-content: center;
justify-content: flex-start;align-items: center;margin-top: 0px;
.dropdown {margin: 5px 7px;}}

.search-custom {position: relative;margin-top: 10px;
.form-custom {height: 40px;border-radius: 5px !important;}}
.form-custom-btn {margin-top: 10px;
button {border-radius: 5px;background: #1B375C;color: #FFF;width: 100%;height: 40px;font-size: 15px;font-weight: 500;border: none;outline: none;padding: 0px 40px;}}
.career-section {padding: 20px 0px;
.career-top-heading {h2 {text-align: left;font-size: 30px;font-weight: 400;}
span {text-align: center;color: #1B375C;font-size: 30px;font-weight: 700;}}
.career-second-heading {h6 {color: #1B375C;font-size: 16px;font-weight: 500;}
span {color: #1B375C;font-size: 16px;font-weight: 700;}}}
.btn-custom {border-radius: 7px;background: #FFF;color: #1B375C;font-size: 11px;font-weight: 500;border: none;
}
.career-second-section {.card-section {
.form-heading {h3 {color: #141414;font-size: 18px;font-weight: 600;}}
.ptn {p {text-align: center;}
.heading {h2 {color: #141414;font-size: 20px;font-weight: 500;display: flex;flex-direction: column;flex-wrap: nowrap;
align-content: center;justify-content: center;align-items: center;}}
.list-section ul {display: flex;justify-content: space-around;padding: unset;flex-direction: row;flex-wrap: wrap;
align-content: center;align-items: center;}}}
.info-section {.top-header {display: flex;justify-content: center;align-items: center;flex-direction: row;flex-wrap: wrap;align-content: center;
.top-header-child {display: flex;flex-direction: column;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;
.top-head {margin: 0px auto;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;
align-items: center;p {margin-top: 10px;display: flex;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;
span {&:nth-child(3) {margin-top: 10px !important;}}}}}}
.salary-location-dv {.border-right {border-bottom: none;border-right: 2px solid #ccc;margin: 10px 0px;padding: 10px 0px;}}
.left-card-second {.list-top-heading {.card-list {display: flex;flex-direction: row;
flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;
.social-media-parent {margin-right: 10px;margin-top: 10px;}}}}}}.info-dv-para {text-align: justify;word-wrap: break-word;
word-break: break-all;}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.career-result-details-section {.career-second-section {.info-section {.info-section-heading {padding: 10px;}}}
.career-section .dropdown-custom-btn {display: flex !important;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;
.dropdown {margin: 5px 7px;}}
.dropdown-custom-btn {display: flex !important;margin-top: 30px;flex-direction: column;}
.career-section .custom-form-section {display: flex !important;flex-direction: column;}
.dropdown-second-custom-btn {display: flex !important;flex-direction: row;flex-wrap: wrap;align-content: center;
justify-content: flex-start;align-items: center;margin-top: 0px;
.dropdown {margin: 5px 7px;}}
.search-custom {position: relative;width: 100%;margin-top: 5px;
.form-custom {height: 40px;border-radius: 5px !important;}}
.form-custom-btn {button {border-radius: 5px;background: #1B375C;color: #FFF;width: 100%;height: 45px;font-size: 15px;font-weight: 500;border: none;outline: none;margin-top: 5px;}}
.career-section {padding: 20px 0px;
.career-top-heading {h2 {text-align: left;font-size: 24px;font-weight: 400;}
span {text-align: center;color: #1B375C;font-size: 24px;font-weight: 700;}}
.career-second-heading {h6 {color: #1B375C;font-size: 16px;font-weight: 500;width: 100%;}
span {color: #1B375C;font-size: 16px;font-weight: 700;}}}
.btn-custom {border-radius: 7px;background: #FFF;color: #1B375C;font-size: 11px;font-weight: 500;border: none;}
.career-second-section {.card-section {.form-heading {h3 {color: #141414;font-size: 18px;font-weight: 600;}}
.ptn {p {text-align: center;}
.heading {h2 {color: #141414;font-size: 20px;font-weight: 500;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;a {
color: #000;text-decoration: none;}}}
.list-section ul {display: flex;justify-content: space-around;padding: unset;flex-direction: row;flex-wrap: wrap;align-content: center;align-items: center;}}}
.info-section {.top-header {display: flex;justify-content: center;align-items: center;flex-direction: row;flex-wrap: wrap;
align-content: center;
.top-header-child {display: flex;flex-direction: column;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;
.top-head {margin: 0px auto;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;
align-items: center;
p {margin-top: 10px;display: flex;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;
span {&:nth-child(3) {margin-top: 10px !important;}}}}}}
.salary-location-dv {.border-right {border-bottom: 2px solid #ccc;border-right: none;margin: 10px 0px;padding: 10px 0px;}}

.left-card-second {.list-top-heading {.card-list {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;.social-media-parent {margin-right: 10px;margin-top: 10px;}}}}}}
.info-dv-para {text-align: justify;word-wrap: break-word;word-break: break-all;padding: 0 10px;}}}