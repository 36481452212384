.view-jobs-project-section {
  margin: 40px 0px;
}
.view-jobs-project-section .jobs-project-upper {
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 0px;
}
.view-jobs-project-section .jobs-project-upper .update-button-primary {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}
.view-jobs-project-section .jobs-project-upper .update-button-danger {
  border: 1px solid red;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: red;
}
.view-jobs-project-section .jobs-project-upper .update-button-unset {
  border: none;
  background: none;
  color: unset;
}
.view-jobs-project-section .jobs-project-upper .dropdown-toggle::after {
  display: none !important;
}
.view-jobs-project-section .jobs-project-upper .project-job-candidate-name {
  color: #1b375c;
  cursor: pointer;
}
.view-jobs-project-section .jobs-project-inner {
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.view-jobs-project-section .jobs-project-inner .justify-content {
  text-align: justify;
}
.view-jobs-project-section .jobs-project-inner .update-button-primary {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}
.view-jobs-project-section .jobs-project-inner .update-button-danger {
  border: 1px solid red;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: red;
}
.view-jobs-project-section .jobs-project-inner .update-button-unset {
  border: none;
  background: none;
  color: unset;
}
.view-jobs-project-section .jobs-project-inner .dropdown-toggle::after {
  display: none !important;
}
.view-jobs-project-section .jobs-project-inner .project-job-candidate-name {
  color: #1b375c;
  cursor: pointer;
}
.view-jobs-project-section .font-weight-bold {
  font-weight: bold;
}
.view-jobs-project-section .dropdown-menu.show {
  display: block;
  right: 10px !important;
  width: 200px;
}

@media (max-width: 640px) {
  .view-jobs-project-section .project_details_mob {
    justify-content: center !important;
  }
  .view-jobs-project-section .project_details_table {
    display: inherit;
  }
  .view-jobs-project-section .tab_project_mobile li a {
    font-size: 10px;
    padding: 10px 8px 10px 8px;
  }
}/*# sourceMappingURL=ViewJobsProject.css.map */