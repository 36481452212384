.like-post-your-job-sec {margin: 40px 0px;
.create-an-emp-inner {box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;padding: 40px;
.heading {h2 {font-weight: 600;margin-bottom: 8px;}}
.heading-inner {h3 {font-size: 22px;font-weight: 600;}}
.card-outer-dv {border: 1px solid rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);border-radius: 10px;padding: 10px 40px;margin: 20px 0px;
.left-sd-content {p {margin-bottom: 0px;}}
.right-sd-btn {.continue-btn {background: rgba(27, 55, 92, 1);color: #fff;border-radius: 40px;padding: 10px 40px;text-decoration: none;}}}
.table {.post-similar-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 40px;padding: 10px 20px;text-decoration: none;}}
}}
@media only screen and (min-width:768px) and (max-width:992px) {}
@media only screen and (min-width:200px) and (max-width:767px) {.like-post-your-job-sec {.create-an-emp-inner {padding: 10px;
.card-outer-dv {padding: 10px 20px;.right-sd-btn {margin: 30px 0px;}}}}}