.rec-choose-your-plan {.choose-you-plan-internal {box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);border-radius: 40px;padding: 40px 20px;margin: 30px 0px;
.left-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 430px;padding: 50px;border-radius: 10px;border: 1px solid rgba(183, 214, 255, 1);
.content {.second-p {margin-bottom: 10px;}}
h3 {margin-bottom: 10px;}
p {margin-bottom: 0px;}
h2 {margin-bottom: 10px;}
ul {padding-left: 0px;
li.right-mark {list-style: none;margin-top: 5px;text-align: left;margin-bottom: 10px;position: relative;margin-left: 35px;
&::before {position: absolute;content: url("../../../../../../public/assets/recruiter-images/chooseyourplan/right-mark2.png");
left: 0;top: 5px;color: #f15a24;font-size: 12px;margin-left: -35px;}}
li.wrong-mark {list-style: none;margin-top: 5px;text-align: left;margin-bottom: 10px;position: relative;margin-left: 35px;
&::before {position: absolute;content: url("../../../../../../public/assets/recruiter-images/chooseyourplan/wrong-mark2.png");
left: 0;top: 5px;color: #f15a24;font-size: 12px;margin-left: -35px;}}}
.get-started {border: 1px solid rgba(27, 55, 92, 1);padding: 16px 40px 16px 40px;text-decoration: none;border-radius: 8px;color: #1b375c;
&:hover {background: rgba(27, 55, 92, 1);color: #fff;}}}
.right-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 530px;padding: 50px;border-radius: 10px;border: 1px solid rgba(183, 214, 255, 1);
.content {.second-p {margin-bottom: 10px;}}
h3 {margin-bottom: 10px;}
p {margin-bottom: 0px;}
h2 {margin-bottom: 10px;}
ul {padding-left: 0px;
li {list-style: none;margin-top: 5px;text-align: left;margin-bottom: 10px;position: relative;margin-left: 35px;
&::before {position: absolute;content: url("../../../../../../public/assets/recruiter-images/chooseyourplan/right-mark.png");
left: 0;top: 5px;color: #f15a24;font-size: 12px;margin-left: -35px;}}}
.get-started {border: 1px solid rgba(27, 55, 92, 1);padding: 16px 40px 16px 40px;text-decoration: none;border-radius: 8px;color: #1b375c;
&:hover {background: rgba(27, 55, 92, 1);color: #fff;}}}
.next-button {text-align: center;
.next-a {border-radius: 32px;padding: 10px 80px;text-align: center;color: #fff;text-decoration: none;box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);background: rgba(27, 55, 92, 1);}}}
}
@media only screen and (min-width:768px) and (max-width:992px) {.rec-choose-your-plan {.choose-you-plan-internal {.left-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 100%;padding: 30px;border-radius: 10px;border: 1px solid rgb(183, 214, 255);}
.right-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 100%;padding: 30px;border-radius: 10px;border: 1px solid rgb(183, 214, 255);}}
}}
@media only screen and (min-width:200px) and (max-width:767px) {.next-button {text-align: center;
.next-a {padding: 10px 18px !important;}}.rec-choose-your-plan {.choose-you-plan-internal {.left-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 100%;padding: 20px;border-radius: 10px;border: 1px solid rgb(183, 214, 255);}
.right-side-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;min-height: 100%;padding: 20px;border-radius: 10px;border: 1px solid rgb(183, 214, 255);}}
}}