.profile-tab {
.banner {height: 277px;position: relative;
img {width: 100%;height: 100%;object-fit: cover;}
.button-box {position: absolute;top: 50%;left: 50%;width: 60px;height: 40px;background: #000;border-radius: 50px;
transform: translate(-50%, -50%);svg {fill: #fff;}}}
.text-base {font-size: 16px !important;}
.text-blue {color: #2557a7 !important;}
.color-light {color: #595959 !important;}
.cursor-poiner {cursor: pointer;}
.tab-page {
#loginPopUpModalProfile {button.popup-login-btn {padding: 5px 20px;border-radius: 40px;background: #1B375C;text-decoration: none;color: #FFF;text-align: center;font-size: 16px;font-weight: 500;border: 1px solid #1B375C;width: fit-content;}}
span {font-size: 12px;font-weight: 400;color: #595959;}
h2 {font-size: 1.75rem;line-height: 1.25;font-weight: 600;color: #2d2d2d;}
.nav-tabs {.nav-item {.nav-link {font-size: 15px;font-weight: 400;color: #000;}
.nav-link.active {font-weight: 500;border-top: none;border-left: none;border-right: none;border-bottom: 4px solid #2d2d2d;
}}}
h4 {font-size: 1.25rem;font-weight: 700;color: #2d2d2d;}
h5 {color: #2d2d2d;font-size: 1rem;font-weight: 700;}
p {color: #2d2d2d;font-weight: 400;}
.btn-gray {background: #2d2d2d;color: #fff;}
.img-box {img {width: 100%;height: 100%;object-fit: contain;}
border-radius: 6px;box-shadow: 0 0 8px rgba(0, 0, 0, .2);background-color: #fff;width: 100px;}
.btn-md {font-size: 18px;font-weight: 600;}}
.submit-btn {background-color: #2557a7;border: none;border-radius: 6px;font-size: 18px;font-weight: 600;color: #fff;padding: 6px 12px;}
.form-control {border-color: #767676;}
.justify-cotntent-between {justify-content: space-between;}
.w-fit {width: fit-content !important;}
.about-box {display: grid;grid-template-columns: repeat(4, 1fr);gap: 20px;}
.list-style-none {list-style: none;}
.companyInfo-box {span {font-size: 12px;color: #767676;}
border: 1px solid #d4d2d0;padding: 18px;border-radius: 6px;min-height: 120px;flex-direction: column;justify-content: space-between;
h5 {font-size: 14px;font-weight: 600;color: #2d2d2d;}
p {font-size: 16px;font-weight: 400;color: #2d2d2d}}
.text {font-size: 15px !important;font-weight: 400;color: #595959 !important;line-height: 25px;}
.font-semibold {font-weight: 600;}
p {text-align: justify;}
.flex-col {flex-direction: column;}
.border-none {border: none;}
.bg-none {background: none;}}
.custom-modal-btn {position: absolute;top: 0;right: 0;background-color: #fff;border-radius: 30px;z-index: 1;
height: 29px;width: 29px;display: flex;justify-content: center;align-items: center;}
.text {font-size: 15px !important;font-weight: 400;color: #595959 !important;line-height: 25px;}
.font-semibold {font-weight: 600 !important;}
.flex-col {flex-direction: column;}
.border-none {border: none;}
.bg-none {background: none;}
@media (max-width:992px){
.profile-tab{ .about-box {display: grid;grid-template-columns: repeat(2, 1fr);gap: 20px;}}}
@media (max-width:992px){
.profile-tab{ .companyInfo-box {margin-top: 20px;}}}
@media screen and (max-width:575px) {
.profile-tab {.submit-btn,.btn-secondary,.btn-light,.update-button {font-size: 15px;padding: 6px 10px;width: auto !important;}}}
@media (max-width:480px){
.profile-tab{ .about-box {display: grid;grid-template-columns: repeat(1, 1fr);gap: 20px;}}}
@media screen and (max-width:375px) {
.profile-tab {.profile-actions {display: flex;flex-direction: column;align-items: start;gap: 10px;}
.update-button {margin-right: 0px !important;}}}