.karlaoon-payemnt-success ._failed {
  border-bottom: solid 4px red !important;
}
.karlaoon-payemnt-success ._failed i {
  color: red !important;
}
.karlaoon-payemnt-success ._success {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.0980392157);
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}
.karlaoon-payemnt-success ._success i {
  font-size: 55px;
  color: #28a745;
}
.karlaoon-payemnt-success ._success i.success-page-loader {
  font-size: unset;
  color: unset;
}
.karlaoon-payemnt-success ._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}
.karlaoon-payemnt-success ._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}/*# sourceMappingURL=Payment.css.map */