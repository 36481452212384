.rec-document-varification-form-sec {.form-head-dv {box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);border-radius: 40px;padding: 30px;margin: 60px 0px;
.next-pre-btn {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin-top: 40px;}
.next-button {.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: none;text-decoration: none;}}
.prev-button {.prev-btn {background: #fff;color: rgb(27, 55, 92);border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: 1px solid rgb(27, 55, 92);text-decoration: none;}}
.cd-heading {text-align: left;
h2 {font-size: 28px;}}
.cd-form-file {label.label input[type="file"] {position: absolute;top: -1000px;}
.upload-other-btn {padding: 5px 15px;border-radius: 40px;background: #1B375C;text-decoration: none;color: #FFF;text-align: center;font-size: 16px;font-weight: 500;}
.show-pdf {padding: 5px 15px;border-radius: 40px;border: 2px solid #1B375C;background: #FFF;text-decoration: none;color: #1B375C;text-align: center;font-size: 16px;font-weight: 500;}
.label {cursor: pointer;border: 1px solid rgb(173, 173, 173);width: 100%;border-radius: 9px;padding: 7px 15px 7px 15px;
.upload-icon {float: right;}
span {color: #797b7e;}}
.label:hover {background: #ccc;}
.label:active {background: #ccc;}
.label:invalid+span {color: #000000;}
.label:valid+span {color: #ffffff;}}
.cd-form-f {width: 100%;text-align: left;
label {margin-bottom: 5px;}
input {border: 1px solid rgba(173, 173, 173, 1);width: 100%;border-radius: 9px;padding: 7px 15px 7px 15px;}}
.cd-form-f-btn {input {background: linear-gradient(0deg, #0A65CC, #0A65CC),linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));border: 1px solid rgba(255, 255, 255, 0.35);color: #fff;padding: 8px 15px 8px 15px;}
margin-top: 26px;}}}
@media only screen and (min-width:768px) and (max-width:992px) {}
@media only screen and (min-width:200px) and (max-width:767px) {.rec-document-varification-form-sec {.form-head-dv {.prev-button {.prev-btn {background: #fff;color: rgb(27, 55, 92);border-radius: 32px;padding: 10px 20px;margin: 4rem 5px 0px 5px;border: 1px solid rgb(27, 55, 92);text-decoration: none;}}
.next-button {.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 20px;margin: 4rem 5px 0px 5px;border: none;text-decoration: none;}}}}
}