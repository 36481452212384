.find-condidate-section {.candidate-pro-section-inner-dv {box-shadow: 0px 20px 60px 0px #F1F4F880;box-shadow: -4px -4px 20px 0px #1B375C33 inset;padding: 40px 20px;margin: 30px 0px;
.filter-parent-dv {.column-parent {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;
.column-inner {margin: 8px 10px;}
.filter-button {.dropdown {.filter-btn-cls {background: #F0F6FF;color: #1B375C;border: 1px solid #F0F6FF;}}}}}
.candidate-pro-btn {.post-btn {color: #fff;background-color: #1B375C;border-radius: 5px;padding: 10px 20px;text-decoration: none;}}
.open-closed-btn {grid-template-columns: repeat(6, 1fr);
.a-opn-cls-btn {margin-right: 15px;text-decoration: none;color: #000;font-size: 14px;
&:hover {text-decoration: revert;color: #0090FF;}}
.a-opn-cls-btn.active {text-decoration: revert;text-decoration: none;color: #000;background-color: #1B375C;color: white;padding: 8px;border-radius: 5px;}}
.right-side-search-filter-btns {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-end;align-items: center;margin: 30px 0px;
.inpt-search {position: relative;
input {padding-left: 30px;border-radius: 5px;border: 1px solid #ccc;}
.search-icon {position: absolute;top: 0;left: 5px;}}
.margin-rgt-lft {margin-left: 20px;
.dropdown-toggle {background: transparent;padding: 4px 10px;font-size: 13px;border-radius: 5px;}}
.add-candidate.margin-rgt-lft {margin-left: 20px;
.add-candidate-btn {background: transparent;padding: 4px 10px;font-size: 13px;border-radius: 5px;}}}
.all-match-candidate-cls {border-bottom: none;
.nav-item {border: 0;}
.candidate-btn {text-decoration: none;padding: 6px 8px;border: 1px solid #1B375C;color: #000;border-radius: 0;}
.candidate-btn.active {background: #1B375C;color: #fff;}}
.candidates-details-card {margin: 0px 30px;
.navigate-tooltips-custom {.dropdown {.dropdown-menu {box-shadow: 0 2px 5px 1px rgba(64, 60, 67, .16);border-radius: 0px;}
a {background-color: transparent;border: unset;
&::after {display: none;}}}}
.candidates-card {border: 1px solid #EAECEE;background: #F8F8F8;border-radius: 12px;text-align: center;padding: 20px;position: relative;
.pro-details {padding: 0px 20px;margin-top: 20px;height: 250px;overflow-y: scroll;scrollbar-color: transparent;
ul {padding: 0;li {p {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;list-style-type: disc !important;margin-bottom: 5px;
.heading {font-size: x-large;font-weight: 600;}}}}}
.pro-details::-webkit-scrollbar {display: none;}.pro-details {-ms-overflow-style: none;scrollbar-width: none;}
.candidate-img-details {img {margin: 15px 0px;border-radius: 50%;}
h5 {a {color: #000;text-decoration: none;}}}
.navigation-tooltips {position: absolute;right: 20px;top: 20px;}}}
.candidate-accept-reject {padding: 0px 30px;
.candidate-accept-reject-btn {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;padding: 5px;border: 1px solid #A6A6A6;border-radius: 7px;
.view-resume-modal-body {height: 75vh;}
a {padding: 10px;transition: all 0.5s ease-in-out;
&:hover {background-color: #ccc;}}}}}}
.custom-grid-btn {grid-template-columns: repeat(6, 1fr);}
@media only screen and (min-width:768px) and (max-width:991px) {.custom-grid-btn {grid-template-columns: repeat(4, 1fr);}
.find-condidate-section {.candidate-pro-section-inner-dv {.open-closed-btn {grid-template-columns: repeat(4, 1fr);}
.open-closed-btn {
.a-opn-cls-btn.active {-webkit-text-decoration: none;text-decoration: none;color: #0090FF;}}
.right-side-search-filter-btns {
.margin-rgt-lft,.add-candidate.margin-rgt-lft {margin-left: 2px;}}
.candidates-details-card {.candidates-card {.pro-details ul li p {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: space-between;align-items: flex-start;list-style-type: disc !important;margin-bottom: 5px;}}}
.post-job-four-small-card {.count-card {background: #fff;padding: 18px 18px;border: 1px solid #ccc;text-align: center;width: 25%;}}}}
}
@media only screen and (min-width:200px) and (max-width:767px) {.custom-grid-btn {grid-template-columns: repeat(3, 1fr);}
.find-condidate-section {.candidate-pro-section-inner-dv {.open-closed-btn {grid-template-columns: repeat(3, 1fr);}
.post-job-four-small-card {.count-card {background: #fff;padding: 0px 0px;border: 1px solid #ccc;text-align: center;width: 25%;
p {&:first-child {font-size: 14px;}}}}
.right-side-search-filter-btns {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;margin: 10px 0px;
.margin-rgt-lft {margin-left: 0px;margin-top: 5px;}}}}}
@media only screen and (min-width:200px) and (max-width:567px) {.custom-grid-btn {grid-template-columns: repeat(2, 1fr);}
.find-condidate-section {.candidate-pro-section-inner-dv {.open-closed-btn {grid-template-columns: repeat(1, 1fr);}
.candidate-pro-btn {.post-btn {padding: 10px 16px;}}
.candidates-details-card {.candidates-card {.pro-details ul li p {font-size: 13px;justify-content: start;}
padding: 9px 0px 9px 3px;}}}}}
@media screen and (max-width: 320px) {.find-condidate-section {.candidate-pro-section-inner-dv {.all-match-candidate-cls {.candidate-btn {font-size: 12px;}
}}}
.find-condidate-section {.candidate-pro-section-inner-dv {.post-job-four-small-card {.count-card {background: #fff;padding: 0px 0px;border: 1px solid #ccc;text-align: center;width: 25%;
p {&:first-child {font-size: 14px;}}}}
.right-side-search-filter-btns {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;margin: 10px 18px;
.margin-rgt-lft {margin-left: 0px;margin-top: 5px;}}}}
.find-condidate-section .candidate-pro-section-inner-dv .candidates-details-card .candidates-card .pro-details ul li p {font-size: 13px;justify-content: flex-start;}}