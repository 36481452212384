.set-preferences-sec {margin: 40px 0px;
.create-an-emp-inner {box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;padding: 40px;
.heading {h2 {font-weight: 600;margin-bottom: 8px;}}
.heading-inner {h3 {font-size: 22px;font-weight: 600;}}
.card-outer-parent-dv {border: 1px solid rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);border-radius: 10px;
margin: 20px 0px;.emp-have-closed-parent {.emp-have-closed {border: 0px solid rgba(0, 0, 0, 0.25);background: #F2F2F2;padding: 15px 40px;
p {margin-bottom: 0px;font-weight: 600;}}}
.card-outer-dv {padding: 15px 40px;
label {}.inner-input {.inner-input-dv {select {-webkit-appearance: auto;-moz-appearance: auto;appearance: auto;}
.mob-para {margin: 6px 0px 0px 0px;color: #66666699;}
a.mob-para {margin: 15px 0px 0px 0px;color: #0A65CC;text-decoration: none;font-size: 16px;font-weight: 600;}
span {margin-right: 20px;color: #66666699;}}}
.checkbox-size-dv {.inner-input-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;
input {margin-right: 10px;width: 18px;height: 18px;}
}}.check-y-n-parent {.check-yes-no {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;
.check-yes {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin-right: 40px;
input {margin-right: 10px;width: 18px;height: 18px;}}
.check-no {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
input {margin-right: 10px;width: 18px;height: 18px;}}}}
.right-sd-btn {.continue-btn {background: rgba(27, 55, 92, 1);color: #fff;border-radius: 40px;padding: 10px 40px;text-decoration: none;
span {margin-left: 10px;}}}}}
.bottom-button-dv {margin: 40px 0px;
.bottom-btn {.a-tag-btn1 {border: 1px solid rgba(27, 55, 92, 1);text-decoration: none;border-radius: 40px;color: linear-gradient(0deg, #1B375C, #1B375C);padding: 10px 50px;}
.a-tag-btn2 {border: 1px solid rgba(27, 55, 92, 1);text-decoration: none;border-radius: 40px;background: rgba(27, 55, 92, 1);color: #fff;padding: 10px 40px;}
.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: none;text-decoration: none;} }}
}}
@media only screen and (min-width:768px) and (max-width:992px) {}
@media only screen and (min-width:200px) and (max-width:767px) {.set-preferences-sec {.create-an-emp-inner {padding: 10px;
.card-outer-parent-dv {.emp-have-closed-parent {.emp-have-closed {border: 0px solid rgba(0, 0, 0, 0.25);background: #F2F2F2;padding: 15px 10px;font-size: 15px;}}
.card-outer-dv {.inner-input {.inner-input-dv {.mob-para {margin: 6px 0px 0px 0px;color: rgba(102, 102, 102, 0.6);display: flex;flex-direction: column;}}}
.checkbox-size-dv {.inner-input-dv {input {margin-right: 10px;width: 22px;height: 22px;}}}
padding: 10px 10px !important;}}.bottom-button-dv {text-align: center;margin: 40px 0px;.bottom-btn {margin: 10px 0px;text-align: center;}}}}}