.rec-before-footer-play-sec {
    .second-bg-section {
        background: #1DB5BE;
    }

    .second-bg-head {
        h2 {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            text-align: left;
        }
    }

    .second-bg-head {
        p {
            color: #111827;
            text-align: justify;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
        }

    }

    .mark-top {

        // margin-top: 120px;
        .get-the-app-heading {
            h4 {
                text-align: left;
            }
        }

        .play-app-stor-btns {
            float: left;

        }
    }
}



@media only screen and (min-width: 200px) and (max-width: 767px) {
    .rec-before-footer-play-sec {
        .mark-top {
            .play-app-stor-btns {
                .goole-play-img img {
                    margin-right: 20px;
                }
            }
        }
    }
}