.why-sponsor-onboarding-guide {
  background: rgb(246, 246, 246);
}
.why-sponsor-onboarding-guide .why-spon-heading h2 {
  color: #1B375C;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}
.why-sponsor-onboarding-guide .img-area h3 {
  color: var(--Dark-blue, #282938);
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0;
}
.why-sponsor-onboarding-guide .img-area a {
  color: #0A65CC;
  font-size: 16px;
  text-decoration: none;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .why-sponsor-onboarding-guide .img-area {
    margin: 0 0 20px 0;
  }
  .why-sponsor-onboarding-guide .img-area h3 {
    font-size: 16px;
    height: auto;
  }
  .why-sponsor-onboarding-guide .img-area a {
    color: #0A65CC;
    font-size: 14px;
    text-decoration: none;
  }
}/*# sourceMappingURL=WhySponsorAJob.css.map */