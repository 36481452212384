.custm-chart {padding: 40px;

.main-head-cus {
padding: 1px;
border-radius: 4px;
background: #F4FAFF;
text-align: center;
margin-bottom: 20px;
box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;

h2 {
font-size: 22px;
font-weight: 700px;
line-height: 54px;
}
}

// =================TabArrea==============
.nav-pills {

li {
width: 180px;
border: 1px solid #1B375C;

.nav-link {
width: 180px;
border-radius: 5px;
color: #1B375C;
text-align: center;
font-size: 16px;
}

.nav-link.active {
background: #1B375C;
color: #fff;
border-radius: 0;
}
}
}

// =================TabArrea==============
.overview-head {
.textarea {
h4 {
font-size: 24px;
color: #000000;
font-weight: 600;
}

font-size: 14px;
}

.dropdown {
select {
width: 120px;
padding: 0 5px;
height: 50px;
}
}
}

.review {
input {
width: 140px;
}
}
}

// ================Performance=================
.performance {
.view-billing-summary-parent {
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;

.bottom-link {}
}

.container:nth-child(1) {
border-bottom: 1px solid #bbb8b6;
}

.campaign-card {
border: 1px solid #cfcecd;
border-radius: 10px;

h4 {
font-size: 20px;
font-weight: 600;
padding: 20px 0;
}

h1 {
font-size: 50px;
font-weight: 700;
}

button#dropdownMenu2 {
border: 0;
margin: 0 0 30px 0;
}


}

.per-crd {
h4 {
font-size: 18px;
font-weight: 600;
}

p {
font-size: 16px;
}

.sum-btn {
border: 1px solid #aaa8a6;
border-radius: 10px;
background-color: #ffffff00;
padding: 5px 10px;
font-weight: 600;
}
}


.container:nth-child(2) {
.bottom-link {
border-bottom: 1px solid black;

a {
color: #000000;
text-decoration: none;

&:hover {
color: #0a65cc;
}
}
}
}

.url-img-logo {
width: 15px;
}
}

@media only screen and (min-width:768px) and (max-width:992px) {
.performance {
.campaign-card {
h4 {
font-size: 16px;
font-weight: 600;
padding: 20px 0;
}
}
}
}

@media only screen and (min-width:200px) and (max-width:767px) {
.custm-chart {padding: 15px;
.nav-pills {padding: 0px;
li {width: 100%;border: 1px solid #1B375C;
.nav-link {width: 100%;border-radius: 5px;color: #1B375C;text-align: center;font-size: 16px;}}}
.overview-head {flex-direction: column;}}
.performance {.campaign-card {h4 {font-size: 16px;font-weight: 600;padding: 20px 0;}}
.view-billing-summary-parent {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
.bottom-link {margin: 5px 0px;}}}}