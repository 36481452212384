.review-page-section {margin: 20px 0px;
.you-used-a-previous-d {background-color: rgb(197 214 219);padding: 10px;border-radius: 5px;
.verify-row {display: flex;
.verify-col {}}
.verify-img {}}
.job-details-head-parent {margin: 10px 0px;
.you-used-heading-dv {}
.submit-btn button {background: rgb(27, 55, 92);color: #fff;border-radius: 40px;padding: 10px 40px;text-decoration: none;}
.you-used-inner {.heading-edit-icon-heading {display: flex;flex-direction: row;align-content: center;justify-content: flex-start;align-items: center;margin: 15px 0px;
p {margin-bottom: 0px;width: 33%;}
.jobtitle-hide-js {button {background: #1B375C;color: #fff;box-shadow: none;border: 1px solid #1B375C;margin-left: 10px;padding: 0px 12px;}}
.pen-cls {padding-right: 20px;text-align: right;}}}}}
.review-page-banner-section {.banner-inner-dv {img.banner-img {width: 100% !important;}}}