$color_1: #fff;
$background-color_1: #0A65CC;
$background-color_2: #ccc;

.career-stepper-sec{
    .steps {display: flex;justify-content: space-between;align-items: center; position: relative;}    
    .step-button { width: 20px;height: 20px;border-radius: 50%;border: none;background-color: $background-color_1;transition: .4s;}
    .step-button[aria-expanded="true"] { width: 20px;height: 20px;background-color: $background-color_1;color: $color_1;}
     .done {background-color: $background-color_1; color: $color_1;}    
    .step-item {z-index: 10;text-align: center;}    
    #progress {position: absolute; width: 93%;z-index: 5;top: 8px;left: 40px;height: 7px; appearance: unset; text-align: center;    
        &::-webkit-progress-value {background-color: $background-color_1; transition: .5s ease;}    
        &::-webkit-progress-bar {background-color: $background-color_2; }}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {

}
@media only screen and (min-width: 200px) and (max-width: 767px) {
    .career-stepper-sec{.cus-step {.accordion{.step-dv-parent *{font-size: 12px !important; }}}
        #progress {position: absolute; width: 100%;z-index: 5; top: 8px; left: 0px;height: 7px;  appearance: unset; text-align: center;}

    }

}