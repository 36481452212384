.career-preferences-section {
  padding: 50px 0px;
}
.career-preferences-section .job-title-strip-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 25px 0px;
  cursor: pointer;
}
.career-preferences-section .job-title-strip-parent:nth-child(1) {
  border-top: 1px solid #ccc;
}
.career-preferences-section .job-title-strip-parent .icon-heading-name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.career-preferences-section .job-title-strip-parent .icon-heading-name svg {
  inline-size: 1.5rem;
  block-size: 1.5rem;
  color: rgb(118, 118, 118);
}
.career-preferences-section .job-title-strip-parent .icon-heading-name h5 {
  margin-bottom: 0px;
  padding-left: 20px;
}
.career-preferences-section .job-title-strip-parent .plus-icon-dv svg {
  inline-size: 1.5rem;
  block-size: 1.5rem;
  color: rgb(45, 45, 45);
}/*# sourceMappingURL=CareerPreferences.css.map */