.career-guide-category-new-content-section {
  padding: 50px 0px;
}
.career-guide-category-new-content-section .feature-article-heading {
  padding: 20px 0px;
  text-align: center;
}
.career-guide-category-new-content-section .feature-article-left {
  margin-top: 10px;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  position: relative;
  height: 376px;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv .cursor-pointer-career-guide {
  cursor: pointer;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.47);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv img {
  border-radius: 10px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv .img-parent-heading {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
  z-index: 1;
}
.career-guide-category-new-content-section .feature-article-left .img-parent-dv .img-parent-heading h4 {
  font-size: 32px;
  color: #fff;
}
.career-guide-category-new-content-section .feature-article-right {
  margin-top: 10px;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  position: relative;
  height: 376px;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv .cursor-pointer-career-guide {
  cursor: pointer;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.47);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv img {
  border-radius: 10px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv .img-parent-heading {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
  z-index: 1;
}
.career-guide-category-new-content-section .feature-article-right .img-parent-dv .img-parent-heading h4 {
  font-size: 32px;
  color: #fff;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-listing-parent-heading {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(242, 242, 242);
  padding: 20px 20px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-listing-parent-heading .blog-list-heading-left h5 {
  margin-bottom: 0px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-listing-parent-heading .blog-list-heading-url h6 {
  margin-bottom: 0px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-listing-parent-heading .blog-list-heading-url h6 a {
  text-decoration: none;
  color: rgb(0, 120, 140);
}
.career-guide-category-new-content-section .blog-listing-parent .blog-listing-parent-heading .blog-list-heading-url h6 a svg {
  width: 20px;
  height: 20px;
  fill: rgb(0, 120, 140);
}
.career-guide-category-new-content-section .blog-listing-parent .blog-list-content-parent h6 {
  margin-top: 20px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-list-content-parent h6 span {
  background-color: rgba(176, 81, 170, 0.75);
  padding: 2px 5px;
  font-size: 14px;
  border-radius: 5px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-list-content-parent h3 {
  margin-bottom: 0px;
}
.career-guide-category-new-content-section .blog-listing-parent .blog-list-content-parent p {
  margin-bottom: 0px;
}

.line-clamp-3-career-guide-category-new {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}/*# sourceMappingURL=CareerGuideCategoryNew.css.map */