$color_1: #1B375C;
$color_2: #FFF;
$color_3: #b0a7a7;
$color_4: #393F47;
$color_5: #141414;
.saved-search-section-start {.search-resumes-section {padding: 50px;}
.search-resumes-bg {border-radius: 4px;padding: 20px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.tab-area {.nav-pills {li:nth-child(1) button {border-radius: 8px 0 0 8px !important;}
li:nth-child(3) button {border-radius: 0 8px 8px 0 !important;}
.nav-item {.nav-link {border-radius: 0;color: #1B375C;border: 1px solid #1b375c;}
.nav-link.active {color: #fff;background-color: #1B375C !important;}}}}}
.search-resumes-display {display: flex;justify-content: space-between;}
.search-btn1 {border-radius: 10px;border: 1px solid #1B375C;font-size: 16px;background: #fff;color: $color_1;padding: 10px 30px;text-decoration: none;}
.search-btn2 {border-radius: 10px;border: 1px solid #1B375C;font-size: 16px;background: #fff;color: $color_1;padding: 10px 20px;text-decoration: none;}
.search-btn3 {padding: 10px 20px;border-radius: 10px;border: 1px solid #1B375C;background: #1B375C;font-size: 16px;color: $color_2;text-decoration: none;}
.search-heading {h3 {color: $color_1;font-size: 30px;font-weight: 700;}}
.custom-form-section {display: flex !important;}
.search-custom {position: relative;
.form-custom {height: 47px;width: 351px;padding-left: 40px;}
  img {position: absolute;top: 12px;left: 11px;color: $color_3;}
.form-custom.form-cus-one {border-radius: 8px 0px 0px 8px;}
.form-custom.form-cus-two {border-radius: 0px;}}
.form-custom-btn {border-radius: 0px 8px 8px 0px;background: #1B375C;color: $color_2;border: none;width: 144px;height: 47px;font-size: 13px;font-weight: 500;}
.advance-search-parent {margin-left: auto;}
.advance-icon {p {color: $color_1;margin-top: 9px;font-size: 18px;font-weight: 600;color: $color_1;margin-top: 9px;font-size: 18px;font-weight: 600;}}
.fitter-icon {height: 24px;width: 24px;}
.filter-bg {border-radius: 4px;background: #FFF;padding: 30px;padding-bottom: 80px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(29, 95, 181, 0.20) inset;
label {font-size: 14px;}}
.day-btn {button {border-radius: 2px 0px 0px 2px;border: 1px solid rgba(20, 20, 20, 0.10);background: #FFF;padding: 8px;font-weight: 400;font-size: 12px;color: $color_4;width: 60px;}}
.filter-heading {h5 {color: $color_5;margin-top: 25px;font-size: 20px;font-weight: 700;}}
.filter-heading-second {h5 {color: $color_5;margin-top: 25px;font-size: 20px;font-weight: 700;}}
.cust_btn1 {border: 2px solid #1B375C;display: inline-flex;justify-content: center;align-items: center;}
.cust_a1 {color: $color_1;text-align: center;font-size: 15px;font-style: normal;font-weight: 700;}
.cust_btn2 {border: 2px solid #1B375C;}
.cust_main {color: $color_5;font-size: 20px;font-weight: 500;}
.cust_lis {padding-left: 0rem;margin-right: 2 rem;}
.cust_row {border-radius: 8px;border: 1px solid rgba(20, 20, 20, 0.05);background: #FFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);margin-top: 17px;}
.photo-cus {img {padding: 15px;}
.createimagefrominitials {width: 65px;height: 65px;border: 1px solid black;border-radius: 5px;padding: 0;}}
.text-left {a {text-decoration: none;
h5 {color: #000;margin-top: 17px;font-size: 18px;}}}
.cust_up {ul {margin-top: 17px;
li {.shareprofilebtn {border: none;background: none;}}
li:nth-child(4) {a {color: #0090FF;}}}}
.candidates-heading {h3 {color: $color_5;margin-top: 20px;font-size: 30px;font-weight: 700;}}}
/*================================ media query start ========================================*/
@media only screen and (min-width:768px) and (max-width:920px) {.saved-search-section-start {.custom-form-section {display: flex !important;flex-direction: column;}
.search-custom .form-custom {margin-top: 7px;}
.form-custom-btn {margin-top: 18px;}
.search-btn3 {margin-top: 15px;}}}
@media only screen and (min-width:568px) and (max-width:992px) {.saved-search-section-start {.custom-form-section {flex-direction: row;}
.form-search-area {flex-wrap: wrap;}}}
@media only screen and (min-width:200px) and (max-width:767px) {.saved-search-section-start {.search-resumes-display {display: flex;
flex-direction: column;}
.search-resumes-section {padding: 0px;}
.search-btn2 {padding: 9px 3px;font-size: 12px;}
.search-btn1 {padding: 9px 3px;font-size: 12px;}
.search-btn3 {padding: 9px 3px;font-size: 12px;}
.search-heading h3 {font-size: 20px;}
.custom-form-section {display: flex !important;flex-direction: column;}
.advance-icon {display: flex !important;flex-direction: column;}
.search-custom .form-custom {width: 237px;margin-top: 8px;}
.advance-icon p {display: none;}
.fitter-icon {display: none;}
.cust_main {font-size: 18px;}
.tab-area {.nav-pills {.nav-item {margin: 0 2px 10px 2px;border-radius: 8px !important;
.nav-link {border-radius: 8px !important;font-size: 14px;padding: 6px !important;}}}}}
}