.contact-us-section {
.main-section {padding: 40px;
.main-head {padding: 1px;border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;
h2 {color: #1B375C;font-size: 22px;font-style: normal;margin-top: 20px;line-height: 54px;text-align: center;text-transform: capitalize;}}}
.first-head {margin-top: 110px;
.display-flex-custom {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;}
h2 {color: #1B375C;font-size: 60px;font-style: normal;font-weight: 700;line-height: 137.5%;margin-top: 152px;}
span {color: #0A65CC;font-size: 60px;font-style: normal;font-weight: 700;line-height: 137.5%;}}
.banner-section {border-radius: 4px;background: #FFF;padding: 15px 15px 0px 15px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
background: rgb(255, 255, 255);background: linear-gradient(94deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 45%, rgba(0, 147, 255, 0.04805672268907568) 78%, rgba(0, 147, 255, 0.06486344537815125) 100%);
.form-field-class {text-align: left;
label {text-align: left;}}}
.banner-second {padding: 55px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.banner-head {h2 {color: #303A42;font-size: 35px;font-style: normal;font-weight: 700;text-align: left;}}
.form-custom {border-radius: 10px;background: rgba(0, 0, 0, 0.04);padding: 15px;}
.form-custom2 {border-radius: 10px;background: rgba(0, 0, 0, 0.04);padding: 15px;}
.form-custom3 {border-radius: 10px;background: rgba(0, 0, 0, 0.04);padding: 15px 21px 45px 18px;}
.join-btn button {border-radius: 40px;background: #1B375C;color: #FFF;font-size: 22px;font-weight: 700;margin-top: 20px;padding: 6px 105px 11px;border: 1px solid;}
.quote {background-color: #fff;border-top-left-radius: 8px;border-top-right-radius: 8px;color: #000000;margin: 9px;padding: 13px;max-width: 100%;font-size: 15px;border-radius: 10px;text-align: center;box-shadow: 0px 0px 168px 0px rgba(0, 0, 0, 0.15);}
.second-box {.quote-box {.quote {position: relative;
p {margin-bottom: 0px;
&::after {border-color: transparent transparent transparent #fff;border-radius: 98% 2% 100% 0% / 100% 0% 100% 0%;border-image: none;border-style: solid;border-width: 22px;bottom: -21px;right: 0;content: " ";display: block;right: 40%;position: absolute;transform: rotate(358deg);}}}}}
.first-box {.quote-box {.quote {position: relative;
p {margin-bottom: 0px;
&::after {border-color: transparent #fff transparent transparent;
border-radius: 98% 2% 100% 0%/100% 0% 100% 0%;border-image: none;border-style: solid;border-width: 21px;bottom: -13px;content: " ";display: block;right: 59%;position: absolute;transform: rotate(116deg);}}}}}
.first-head {blockquote {position: relative;top: 20px;}}}
// ===========================================
// ---------------COntact Card----------------
// ===========================================
.call-card {.card-area {height: 195px;padding: 15px;background: var(--White, #FFF);box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
.textarea {
h4 {color: #1B375C;font-size: 25px;font-weight: 500;}
h2 {color: var(--Black, #373737);font-size: 30px;font-weight: 600;}}
p {color: var(--gray-paragraph, #7B7B7B);font-size: 18px;margin: 0 0 5px 10px;
span {color: #1B375C;font-size: 20px;}}}}
// --------------------End-------------------
// ===========================================
@media (max-width:1200px){
.contact-us-section { .form-custom3 {padding: 15px 21px 20px 18px;}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.contact-us-section {.first-head h2 {font-size: 18px;}
.first-head span {font-size: 18px;}
.form-custom2 {width: 265px !important;}
.form-custom3 {width: 100% !important;}
.join-btn button {font-size: 17px !important;padding: 6px 68px 10px !important;}
.main-section {padding: 0px;}
.quote {display: none !important;}}}
.call-card {.card-area {margin: 0 0 10px 0;}}
@media only screen and (min-width:200px) and (max-width:567px) {
.call-card {.card-area {height: auto;p {margin: 0;}}
.card-area>div {display: block !important;}}}