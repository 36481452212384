.before-footer-play-sec .second-bg-section {
  background: #1DB5BE;
}
.before-footer-play-sec .second-bg-head h2 {
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}
.before-footer-play-sec .second-bg-head p {
  color: #111827;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  word-spacing: -1px;
}
.before-footer-play-sec .mark-top .get-the-app-heading h4 {
  text-align: left;
}
.before-footer-play-sec .mark-top .play-app-stor-btns {
  float: left;
}
.before-footer-play-sec .mark-top .play-app-stor-btns .goole-play-img img {
  cursor: pointer;
}
.before-footer-play-sec .mark-top .play-app-stor-btns .app-store-dv img {
  cursor: pointer;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .before-footer-play-sec .second-bg-section {
    background: #1DB5BE;
  }
  .before-footer-play-sec .second-bg-head h2 {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
  }
  .before-footer-play-sec .second-bg-head p {
    color: #111827;
    text-align: justify;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    word-break: break-all;
    word-spacing: -2px;
  }
}/*# sourceMappingURL=BeforeFooterPlayStore.css.map */