.reviews-section {.useful-review{h4{font-size: 17px;font-weight: 600;color: #2d2d2d;}p{font-size: 14px !important;}}.interview-process {h4{font-size: 19px !important;font-weight: 600;color: #2d2d2d !important;}span{color: #2d2d2d  ;}h5{font-size: 17px;font-weight: 700;color: #595959;}}.form-label{font-size: 15px !important;}.rounded-l{border-radius: 0.5rem 0 0px 0.5rem;}.rounded-r{border-radius: 0px 0.5rem 0.5rem 0px;}.progress {height: 20px;background-color: #e9ecef;}.progress-bar {background-color: #007bff;}.label {font-weight: bold;}.bordered-section {border: 1px solid #ccc;padding: 15px;margin-bottom: 15px;}.progress-bar-light {background-color: #6c757d;}.interview-experience {h4 {color: #2d2d2d;font-size: 22px !important;font-weight: 600;}
h5 {color: #2d2d2d;font-size: 18px !important;line-height: 24px;font-weight: 600;}}
label {font-weight: 600;}
.box-rounded svg {fill: #9d2b6b;}
.progress-bar {background-color: #9d2b6b;}
.progress {height: 8px;}
.border-bottom {border-bottom: 1px solid #e4e2e0;}
span {font-size: 13px;}
.border-dotted {border-bottom: 1px dashed #949494;}
.share-btn {padding: 8px 18px;border-radius: 0.5rem;font-size: 16px;font-weight: 500;background: none;border: none;
svg {fill: #767676 !important;width: 20px !important;height: 20px !important;}}
.share-btn:hover,.rating-btn:hover {color: rgb(22, 64, 129);background-color: rgb(238, 241, 254);}
.rating-btn {padding: 5px 18px;border-radius: 0.5rem;font-size: 16px;font-weight: 500;background: #f3f2f1;border: none;}
.feedback {p {font-size: 14px;font-weight: 400;color: #595959;line-height: 24px;}
svg {width: 13px;height: 15px;fill: #9d2b6b;}}
.useful-review {background: #eef1fe;border-radius: 8px;padding: 12px 15px;
span {color: #164081;}
h2 {color: #164081;font-weight: 600;font-size: 17px;}}
.review-box-container {flex-wrap: wrap;
.review-box {padding: 6px 14px;border-radius: 30px;font-size: 16px;font-weight: 500;background: #f3f2f1;}}
h3 {color: #2d2d2d;font-weight: 700;font-size: 1.5rem;line-height: 1.25;}
h5,h4 {font-weight: 400;color: #595959;}
h5 {font-size: 18px;}
h4 {font-size: 16px;}
}
.claimed-profile {h4 {color: #595959;font-weight: 600;font-size: 16px;}
svg {fill: #1f662c;width: 25px;}}
.update-button {border: 1px solid #4771c0;background: none;border-radius: 0.5rem;background-color: white;padding: 6px 14px;font-size: 16px;font-weight: 600;color: #4771c0;}
.update-button:hover {background-color: #3b7ce312;}
.accordion {h4 {font-size: 16px !important;font-weight: 600;line-height: 1.5;color: #2d2d2d;}}
.rate-recent-company {.rate-star {background: #eef1fe;border-radius: 6px;padding: 6px 15px;width: fit-content;}
background: #eef1fe;border-radius: 8px;padding: 30px 22px;display: flex;flex-direction: column;justify-content: center;align-items: center;
p {font-size: 14px;}
h4 {font-size: 20px;font-weight: 600;line-height: 1.5rem;color: #2d2d2d;}
svg {width: 25px !important;height: 25px !important;}}
.box-rounded {border-radius: .5rem;padding: 1.5rem;border: 1px solid #d4d2d0;
h4 {font-size: 16px !important;font-weight: 600;line-height: 1.5;color: #2d2d2d;}
p {font-size: 14px !important;font-weight: 400;line-height: 1.5;}}
@media (max-width:767px){.project_action{white-space: nowrap;}}