.rec-confirmation-sec {.form-head-dv {box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);border-radius: 40px;padding: 30px;margin: 60px 0px;
.checkbox-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin-top: 2rem;
.checkbox-1 {margin: 0px 10px;
input {margin-right: 10px;}
.a-tag-color {color: #1B375C;font-weight: 600;}}
.checkbox-2 {margin: 0px 10px;
input {margin-right: 10px;}
.a-tag-color {color: #1B375C;font-weight: 600;}}}
.next-pre-btn {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin-top: 40px;}
.next-button {.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: none;text-decoration: none;}}
.prev-button {.prev-btn {background: #fff;color: rgb(27, 55, 92);border-radius: 32px;padding: 10px 70px;margin: 4rem 10px 0px 10px;border: 1px solid rgb(27, 55, 92);text-decoration: none;}}
.cd-heading {text-align: left;
h2 {font-size: 28px;}}
.cd-form-file {label.label input[type="file"] {position: absolute;top: -1000px;}
.label {cursor: pointer;border: 1px solid rgb(173, 173, 173);width: 100%;border-radius: 9px;padding: 7px 15px 7px 15px;
.upload-icon {float: right;}
span {color: #797b7e;}}
.label:hover {background: #ccc;}
.label:active {background: #ccc;}
.label:invalid+span {color: #000000;}
.label:valid+span {color: #ffffff;}}
   .cd-form-f-btn {input {background: linear-gradient(0deg, #0A65CC, #0A65CC),linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));border: 1px solid rgba(255, 255, 255, 0.35);color: #fff;padding: 8px 15px 8px 15px;}
margin-top: 26px;}
.cd-form-f {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.confirm-icon-content {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.content {margin-left: 20px;
a {text-decoration: none;}
.remove-border {border: none !important;outline: none;}  }}}}}
@media only screen and (min-width:200px) and (max-width:767px) {.rec-confirmation-sec {.form-head-dv {.cd-form-f {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.confirm-icon-content {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.content {margin-left: 3px;width: 150px;word-wrap: break-word;
a {text-decoration: none;font-size: 12px;margin-bottom: 0px;}}}}
.checkbox-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: flex-start;justify-content: center;align-items: flex-start;margin-top: 1rem;
.checkbox-1 {margin: 0px 10px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
label {font-size: 13px;}}
.checkbox-2 {margin: 0px 10px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
label {font-size: 13px;}}}
.next-button {.next-btn {background: rgb(27, 55, 92);color: #fff;border-radius: 32px;padding: 10px 15px;margin: 4rem 0px 0px 0px;border: none;text-decoration: none;}}
.prev-button {.prev-btn {background: #fff;color: rgb(27, 55, 92);border-radius: 32px;padding: 10px 30px;margin: 4rem 10px 0px 10px;border: 1px solid rgb(27, 55, 92);text-decoration: none;}}}}}