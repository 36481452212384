@media only screen and (min-width: 200px) and (max-width: 992px) {
  .msg-wid {
    width: 70% !important;
  }
  .snd-wid {
    width: 20% !important;
  }
}
.seeker-chat-container div.seeker-chat-list .list-bef-none .list-group-item::before {
  display: none;
}
.seeker-chat-container div.seeker-chat-list li.seeker-chat-list-item {
  cursor: pointer;
}
.seeker-chat-container div.seeker-chat-list li.seeker-chat-list-item img.seeker-chat-list-company-logo {
  max-height: -webkit-fill-available;
  max-width: 45px;
  margin-right: 5px;
  border-radius: 50%;
}
.seeker-chat-container div.seeker-chat-list li.seeker-chat-list-item span.chat-message-line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.seeker-chat-messages-container img.seeker-chat-message-company-logo {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress {
  font-family: helvetica;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40%;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .chat {
  width: 100%;
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
  height: 350px;
}
@media only screen and (min-width: 200px) and (max-width: 992px) {
  .seeker-chat-messages-container .seeker-chat-messages .chat-progress .chat {
    height: calc(100vh - 275px);
  }
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .yours {
  align-items: flex-start;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
  word-break: break-all;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .mine {
  align-items: flex-end;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #1b375c 100%);
  background-attachment: fixed;
  position: relative;
  word-break: break-all;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, hsl(187, 100%, 46%) 0%, #1b375c 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}
.seeker-chat-messages-container .seeker-chat-messages .chat-progress .mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
.seeker-chat-messages-container .seeker-chat-messages .custom-footer .chat-input {
  border-color: #2557a7;
  box-shadow: inset 0 -3px 0 0 #2557a7;
}/*# sourceMappingURL=Chat.css.map */