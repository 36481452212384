.view-jobs-project-section {
    margin: 40px 0px;

    .jobs-project-upper {
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 0px;

        .update-button-primary {
            border: 1px solid #4771c0;
            background: none;
            border-radius: 0.5rem;
            background-color: white;
            padding: 6px 14px;
            font-size: 16px;
            font-weight: 600;
            color: #4771c0;
        }

        .update-button-danger {
            border: 1px solid red;
            background: none;
            border-radius: 0.5rem;
            background-color: white;
            padding: 6px 14px;
            font-size: 16px;
            font-weight: 600;
            color: red;
        }

        .update-button-unset {
            border: none;
            background: none;
            color: unset
        }

        .dropdown-toggle::after {
            display: none !important;
        }

        .project-job-candidate-name {
            color: #1b375c;
            cursor: pointer;
        }
    }

    .jobs-project-inner {
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 40px;

        .justify-content {
            text-align: justify;
        }

        .update-button-primary {
            border: 1px solid #4771c0;
            background: none;
            border-radius: 0.5rem;
            background-color: white;
            padding: 6px 14px;
            font-size: 16px;
            font-weight: 600;
            color: #4771c0;
        }

        .update-button-danger {
            border: 1px solid red;
            background: none;
            border-radius: 0.5rem;
            background-color: white;
            padding: 6px 14px;
            font-size: 16px;
            font-weight: 600;
            color: red;
        }

        .update-button-unset {
            border: none;
            background: none;
            color: unset
        }

        .dropdown-toggle::after {
            display: none !important;
        }

        .project-job-candidate-name {
            color: #1b375c;
            cursor: pointer;
        }
    }

    .font-weight-bold {
        font-weight: bold;
    }
    .dropdown-menu.show {
        display: block;
        right: 10px !important;
        width: 200px;
    }
}
@media (max-width:640px){
    .view-jobs-project-section {
        .project_details_mob{
            justify-content: center !important;
        }
        .project_details_table{
            display: inherit;
        }
        .tab_project_mobile li a{
            font-size: 10px;
            padding: 10px 8px 10px 8px;
        }
    }
}