.otp-password-section {.user-envlop-cls {position: relative;
span {position: absolute;left: 15px;top: 8px;}
input {padding-left: 40px;}}
.user-envlop-cls2 {position: relative;
.img {position: absolute;left: 27px;top: 12px;}
input {padding-left: 40px;}}
li {list-style: none;padding: 5px 10px 10px;}
a {text-decoration: none;}
.input-text {width: 100%;height: 44px;border: 1px solid #a59b9b;border-radius: 10px;padding: 10px;}
.pt-2 {color: #a59b9b;}
ul {display: flex;justify-content: center;padding-left: 0px;}
.pt-3 {text-align: center;font-size: 16px;font-weight: 500;margin-top: 30px;color: #a59b9b;}
.para {margin-bottom: 0px;margin-top: 30px;color: #a59b9b;text-align: left;}
.row-top {margin-top: 15px;}
form {margin: 30px;}
.bottm-signup {text-align: center;font-size: 16px;font-weight: 500;margin-top: 20px;margin-bottom: 0px;color: #a59b9b;}
.bottm-signin {text-align: center;font-size: 16px;font-weight: 500;margin-top: 10px;color: #a59b9b;}
.second-section {border-radius: 13px;margin-top: 80px;margin-bottom: 80px;background: #FFF;-webkit-box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;-moz-box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;}
.para-3 {margin-bottom: 1px;margin-top: 22px;}
.password-custom {position: relative;}
.password-custom span {position: absolute;right: 25px;top: 14px;color: #939191;}
.login-btn {width: 100%;a {border-radius: 30px;background: rgba(27, 55, 92, 1);color: white;font-size: 17px;box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);padding: 10px 145px;}}
.otp-custom-dv {.otp-field {flex-direction: row;column-gap: 10px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: nowrap;}
.otp-field input {height: 45px;width: 42px;border-radius: 6px;outline: none;font-size: 1.125rem;text-align: center;border: 1px solid #ddd;}
.otp-field input:focus {box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);}
.otp-field input::-webkit-inner-spin-button,.otp-field input::-webkit-outer-spin-button {display: none;}
.resend {font-size: 12px;.resend-btn {:hover {cursor: pointer;}}}}}
@media only screen and (min-width: 768px) and (max-width: 992px) {}
@media only screen and (min-width: 200px) and (max-width: 767px) {.otp-password-section {.login-btn {a {border-radius: 30px;background: rgb(27, 55, 92);color: white;font-size: 17px;box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);padding: 10px 54px;}}
.pt-2 {color: #a59b9b;text-align: left;a {color: #a59b9b;}}}}