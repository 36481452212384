.career-guide-nav {
  background: #FE6108;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.career-guide-nav .custom-toggler:focus {
  box-shadow: none;
}
.career-guide-nav .navbar {
  background: #FE6108;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  height: 70px;
}
.career-guide-nav .navbar .navbar-toggler-icon {
  color: #fff;
}
.career-guide-nav .navbar .navbar-collapse {
  background-color: #FE6108;
}
.career-guide-nav .navbar a {
  color: #fff;
}
.career-guide-nav .navbar .navbar-nav a {
  padding: 10px 20px;
}

.career-search {
  background-image: url(../../../../../public/assets/images/career-guide/career.png);
  background-position: 100%;
  background-size: cover;
  min-height: 440px;
}
.career-search h1 {
  color: #141414;
  text-align: center;
  font-family: Barlow;
  font-size: 75px;
  font-weight: 400;
}
.career-search h6 {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-family: Barlow;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0 0 0;
}
.career-search .serach-input {
  position: relative;
}
.career-search .serach-input img {
  position: absolute;
  left: 12px;
  top: 13px;
}
.career-search .serach-input input {
  padding: 14px 0;
  border-radius: 5px 0px 0px 5px;
}
.career-search .serach-input input::-moz-placeholder {
  padding: 0 0 0 40px;
  color: rgba(20, 20, 20, 0.5);
  font-size: 16px;
  font-weight: 400;
}
.career-search .serach-input input::placeholder {
  padding: 0 0 0 40px;
  color: rgba(20, 20, 20, 0.5);
  font-size: 16px;
  font-weight: 400;
}
.career-search a {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  text-decoration: none;
}
.career-search .search-career-guide-btn {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  text-decoration: none;
}
.career-search .search-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.career-search .search-list li {
  list-style: none;
  position: relative;
  margin: 10px 10px;
}
.career-search .search-list li:nth-child(1) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list li:nth-child(2) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list li:nth-child(3) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list li:nth-child(4) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list li:nth-child(5) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list li:nth-child(6) img {
  position: absolute;
  right: 10px;
  top: 2px;
}
.career-search .search-list a {
  border: unset;
  border-radius: 7px;
  background: #FFF;
  color: #1B375C;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 40px 12px 10px;
}

.career-advice h1 {
  text-align: left;
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.career-advice .custm-career-box {
  background: #F6F6F6;
  cursor: pointer;
}
.career-advice .custm-career-box img {
  height: 200px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-advice .custm-career-box .textarea {
  height: 220px;
  padding: 10px 20px;
}
.career-advice .custm-career-box .textarea h3 {
  text-align: left;
  color: #1B375C;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.career-advice .custm-career-box .textarea div.blah-blah {
  color: #777;
  text-align: justify;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  max-height: 155px;
  overflow-y: scroll;
  padding: 0px 8px;
  word-wrap: break-word;
}
.career-advice .custm-career-box .textarea div.blah-blah::-webkit-scrollbar-track {
  -webkit-box-shadow: unset;
  background-color: transparent;
}
.career-advice .custm-career-box .textarea div.blah-blah::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.career-advice .custm-career-box .textarea div.blah-blah::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.career-advice .custm-career-box .textarea a {
  display: flex;
  text-decoration: none;
  color: #0A65CC;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
}

.career-test .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-test .title h1 {
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.career-test .title .font-size-inc {
  font-size: 25px;
}
.career-test .title a {
  text-decoration: none;
  padding: 8px 50px;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
}
.career-test .custm-info-box {
  background: #F6F6F6;
  border-radius: 0 5px 10px 10px;
}
.career-test .custm-info-box .textarea {
  padding: 20px 20px;
}
.career-test .custm-info-box .textarea h3 {
  text-align: left;
  color: var(--Main, #073E87);
  font-size: 25px;
  font-weight: 600;
  margin: 15px 0;
}
.career-test .custm-info-box .textarea p {
  text-align: left;
  color: var(--Sub-ink, #747D8C);
  font-size: 15px;
  font-weight: 400;
}
.career-test .custm-info-box .textarea ul {
  padding: unset;
}
.career-test .custm-info-box .textarea ul li {
  list-style: none;
}
.career-test .custm-info-box .textarea ul li img {
  padding: 0 10px 4px 0;
  -o-object-fit: none;
     object-fit: none;
}

.feature-blog .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.feature-blog .custm-sec-lft h1 {
  text-align: left;
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
  padding: 0 0 15px 0;
}
.feature-blog .custm-sec-lft .new-job-info h5 {
  text-align: left;
  color: #666;
  font-size: 12px;
  font-weight: 400;
}
.feature-blog .custm-sec-lft .new-job-info h2 {
  text-align: left;
  color: #222;
  font-size: 24px;
  font-weight: 600;
}
.feature-blog .custm-sec-lft .new-job-info ul {
  padding: unset;
}
.feature-blog .custm-sec-lft .new-job-info ul li {
  list-style: none;
  color: #777;
  font-size: 12px;
  font-weight: 400;
}
.feature-blog .custm-sec-lft .new-job-info ul li img {
  padding: 0 5px 0 0;
}
.feature-blog .custm-sec-lft .new-job-info ul li:nth-child(2) {
  padding: 0 20px;
}
.feature-blog .custm-sec-lft .new-job-info h4 {
  text-align: left;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
}
.feature-blog .custm-sec-rit .recent-post {
  padding: 0 5px;
}
.feature-blog .custm-sec-rit .recent-post h3 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 600;
}
.feature-blog .custm-sec-rit .recent-post h4 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 600;
}
.feature-blog .custm-sec-rit .recent-post .custm-posts .post-detail .post-text h4 {
  text-align: left;
  color: var(--sale-tage-coloe, #3F509E);
  font-size: 14px;
  font-weight: 400;
}
.feature-blog .custm-sec-rit .recent-post .custm-posts .post-detail .post-text h5 {
  text-align: left;
  color: var(--sub-text-color, #8A8FB9);
  font-size: 11px;
  font-weight: 600;
}
.feature-blog .view-btn a {
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.interview-question .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.interview-question .custm-ques-sec {
  cursor: pointer;
}
.interview-question .custm-ques-sec .question-logo {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 5px;
}
.interview-question h1 {
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.interview-question h3 {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
}
.interview-question p {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.interview-question .col-lg-4 {
  padding: 30px;
}
.interview-question .col-lg-4 img {
  padding: 0 0 15px 0;
}
.interview-question .col-lg-4:nth-child(2) {
  border-bottom: 1px solid #DDD;
}
.interview-question .col-lg-4:nth-child(3) {
  border: 1px solid #DDD;
  border-top: unset;
}
.interview-question .col-lg-4:nth-child(4) {
  border-bottom: 1px solid #DDD;
}
.interview-question .col-lg-4:nth-child(6) {
  border: 1px solid #DDD;
  border-bottom: unset;
  border-top: unset;
}
.interview-question .view-btn a {
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.resumes .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.resumes .resume-info h5 {
  text-align: left;
  color: #666;
  font-size: 12px;
  font-weight: 400;
}
.resumes .resume-info h2 {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}
.resumes .resume-info ul {
  padding: unset;
}
.resumes .resume-info ul li {
  list-style: none;
  color: #777;
  font-size: 12px;
  font-weight: 400;
}
.resumes .resume-info ul li img {
  padding: 0 5px 0 0;
}
.resumes .resume-info ul li:nth-child(2) {
  padding: 0 20px;
}
.resumes .resume-info h4 {
  text-align: left;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
}
.resumes .view-btn a {
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .career-search {
    height: auto;
  }
  .career-search .search-career-guide-btn {
    border-radius: 8px 8px 8px 8px;
  }
  .career-search .career-title h1 {
    font-size: 45px;
  }
  .career-search .custm-serch {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-search .custm-serch a {
    font-size: 12px;
    padding: 16px 7px;
  }
  .career-search .custm-serch a.my-5 {
    margin: 0px !important;
    margin-bottom: 20px !important;
    width: 100%;
    border-radius: 0px;
  }
  .career-search .custm-serch .serach-input {
    margin: 0px !important;
    width: 100%;
  }
  .career-search .custm-serch .serach-input input {
    border-radius: 0px;
  }
  .career-search .search-list {
    display: block !important;
  }
  .career-search .search-list li {
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 40px 12px 10px;
  }
  .career-search .search-list li a {
    padding: 0;
  }
  .career-search .search-list li img {
    top: 11px !important;
  }
  .career-advice .col-lg-3 {
    margin: 0 0 10px 0;
  }
  .career-advice .col-lg-3 .custm-career-box .textarea {
    height: 180px;
    padding: 15px 20px;
  }
  .career-advice .col-lg-3 .custm-career-box img {
    width: 100% !important;
  }
  .career-advice .custm-career-box {
    margin: 5px 0px;
  }
  .career-advice .custm-career-box .textarea {
    height: 100%;
    padding: 35px 20px;
  }
  .career-test .title {
    display: block !important;
  }
  .career-test .title h1 {
    font-size: 25px;
  }
  .career-test .title a {
    font-size: 14px;
    padding: 8px 22px;
  }
  .career-test .custm-info-box .textarea h3 {
    font-size: 18px;
  }
  .career-test .custm-info-box .textarea ul {
    display: block !important;
  }
  .feature-blog .custm-sec-lft .title h1 {
    font-size: 25px;
  }
  .feature-blog .custm-sec-lft .new-job-info h2 {
    font-size: 16px;
  }
  .feature-blog .custm-sec-lft .new-job-info ul {
    display: block !important;
  }
  .feature-blog .custm-sec-lft .new-job-info ul li {
    margin: 0 0 10px 0;
  }
  .feature-blog .custm-sec-lft .new-job-info ul li:nth-child(2) {
    padding: unset;
  }
  .feature-blog .custm-sec-lft .new-job-img {
    padding: unset !important;
  }
  .feature-blog .custm-sec-rit .recent-post h3 {
    font-size: 25px;
  }
  .feature-blog .view-btn a {
    padding: 4px 22px;
    font-size: 14px;
  }
  .interview-question h1 {
    font-size: 25px;
  }
  .interview-question h3 {
    font-size: 18px;
  }
  .interview-question .view-btn a {
    padding: 4px 22px;
    font-size: 14px;
  }
  .resumes {
    padding: unset !important;
  }
  .resumes .resume-info ul {
    display: block !important;
  }
  .resumes .resume-info ul li {
    margin: 0 0 10px 0;
  }
  .resumes .resume-info ul li:nth-child(2) {
    padding: unset;
  }
  .resumes .resume-img {
    padding: unset !important;
  }
  .resumes .view-btn a {
    padding: 4px 22px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .text-md-start {
    text-align: left;
  }
  .career-search {
    height: auto;
  }
  .career-search .search-career-guide-btn {
    border-radius: 8px 8px 8px 8px;
  }
  .career-search .career-title h1 {
    font-size: 45px;
  }
  .career-search .custm-serch {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-search .custm-serch a {
    font-size: 12px;
    padding: 16px 7px;
  }
  .career-search .custm-serch a.my-5 {
    margin: 0px !important;
    margin-bottom: 20px !important;
    width: 100%;
    border-radius: 0px;
  }
  .career-search .custm-serch .serach-input {
    margin: 0px !important;
    width: 100%;
  }
  .career-search .custm-serch .serach-input input {
    border-radius: 0px;
  }
  .career-search .search-list {
    display: block !important;
  }
  .career-search .search-list li {
    margin-bottom: 20px;
    width: 100%;
    background: #fff;
    padding: 10px 40px 12px 10px;
  }
  .career-search .search-list li a {
    padding: 0;
  }
  .career-search .search-list li img {
    top: 11px !important;
  }
  .career-advice .col-lg-3 {
    margin: 0 0 10px 0;
  }
  .career-advice .custm-career-box img {
    width: 100% !important;
  }
  .feature-blog .custm-sec-lft .new-job-info ul {
    display: block !important;
  }
  .feature-blog .custm-sec-lft .new-job-info ul li {
    margin: 0 0 10px 0;
  }
  .feature-blog .custm-sec-lft .new-job-info ul li:nth-child(2) {
    padding: unset;
  }
}/*# sourceMappingURL=careerguide.css.map */