.impact-technology-content-section {
  margin: 0px 0px 50px 0;
}
.impact-technology-content-section .technology-section {
  border-radius: 4px;
  background: #FFF;
  padding: 35px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.impact-technology-content-section .technology-back-btn a {
  text-decoration: none;
  font-weight: 500;
}
.impact-technology-content-section .tech-btn .techno-btn {
  padding: 6px 12px;
  border-radius: 6px;
  background: #4B6BFB;
  color: #FFF;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
.impact-technology-content-section .tech-head h2 {
  color: #181A2A;
  margin-top: 15px;
  font-weight: 600;
  font-size: 28px;
}
.impact-technology-content-section .tech-para img {
  margin-top: 15px;
}
.impact-technology-content-section .tech-para p {
  color: #696A75;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
}
.impact-technology-content-section .para-technology p {
  color: #3B3C4A;
  margin-top: 20px;
  text-align: justify;
  font-size: 17px;
  font-weight: 400;
}
.impact-technology-content-section .technology-center-section .technology-heading p {
  text-align: justify;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .impact-technology-content-section .main-section {
    padding: 0px;
  }
  .impact-technology-content-section .technology-section {
    padding: 15px;
  }
  .impact-technology-content-section .tech-btn .techno-btn {
    margin-top: 15px;
  }
  .impact-technology-content-section .tech-head h2 {
    color: #181A2A;
    margin-top: 15px;
    font-weight: 600;
    font-size: 22px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .impact-technology-content-section .main-section {
    padding: 0px;
  }
  .impact-technology-content-section .technology-section {
    padding: 15px;
  }
  .impact-technology-content-section .tech-btn .techno-btn {
    margin-top: 15px;
  }
  .impact-technology-content-section .tech-head h2 {
    color: #181A2A;
    margin-top: 15px;
    font-weight: 600;
    font-size: 18px;
  }
}/*# sourceMappingURL=ImpactOfTechnology.css.map */