@media only screen and (max-width:1201px) {}
@media only screen and (min-width:992px) and (max-width:1200px) {}
@media only screen and (min-width:1024px) and (max-width:1199px) {
.impaneled-companies{.company-slider{width: 225px !important ;}}}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-banner{.carousel{ .carousel-inner{ .carousel-item{img{width:100%;filter:brightness(0.7);height:400px;object-fit:cover;} } }} } .common-header-sec .header-second nav{z-index:1; } .header-banner .banner-text h1{font-size:30px; } .header-banner .banner-text{transform:translate(-50%,-55%); } .header-banner .banner-text button{padding:10px 15px;font-size:13px; } .bannerafter-header{.achievements-lft{h1{ font-size:30px; text-align:center; br{display:none; } }} } .bannerafter-header .achievements-lft p{text-align:center; } .achievements-rit .counter-txt h2{font-size:22px; } .job-demand .job-box2{margin-bottom:10px; } .job-demand{.job-box{ .company{ .comp-logo-65p{max-width:65px; } .company-txt{.line-clamp-comp-name{max-width:150px;} } }} } .work-location h1{font-size:30px; } .job-opening h1{font-size:30px; } .job-opening .MuiTabs-flexContainer .MuiButtonBase-root{font-size:15px; } .benifits .key-benifits .heading-txt h1{font-size:30px; } .job-demand h1{font-size:30px; } .impaneled-companies{h1{font-size:30px;}.company-slider{width:210px !important;border-radius:20px;background:#FFF;padding:0 10px;box-shadow:5px 5px 13px 0px rgba(224,224,224,0.5);margin:0px 7px;} } .job-fair h1{font-size:30px; } .testimonial h1{font-size:30px; } .heading-txt{margin:10px 0 !important; }}
@media only screen and (min-width:200px) and (max-width:767px) {
.job-opening{.latest-job-card-two{height:300px; .company-group{ margin-bottom:0px; flex-direction:column; display:flex; align-content:center; align-items:center; }} } .header-banner{.carousel{ .carousel-inner{ .carousel-item{img{width:100%;filter:brightness(0.7);height:200px;object-fit:cover;} } }} } .job-opening{.css-1ujnqem-MuiTabs-root{min-height:48px;-webkit-overflow-scrolling:touch;} } .common-header-sec .main-header .row>.col-lg-6{justify-content:center; } .common-header-sec .main-header .head-sec-lft p{font-size:12px; } .common-header-sec .main-header .head-sec-lft{align-items:center;justify-content:space-around;padding:0 0 5px; } .common-header-sec .header-second nav{z-index:1; } .header-banner .banner-text{transform:translate(-50%,-50%);top:50%; } .header-banner .banner-text .find-job-btn{padding:5px 15px;font-size:12px; } .header-banner .banner-text h1{font-size:16px;margin-bottom:8px; } .header-banner .banner-text button{width:125px;height:35px;font-size:10px;padding:0; } .footer-parent-sec .ft-rt-dv .inner-img-dv{text-align:center;display:flex;flex-direction:column;flex-wrap:nowrap;align-content:center;justify-content:center;align-items:center; } .bannerafter-header{.achievements-lft{h1{ font-size:22px; line-height:38px; text-align:center; br{display:none; } }} } .bannerafter-header .achievements-lft p{text-align:center; } .bannerafter-header{padding:20px 5px; }
// ==================Achievement==================
.achievements-rit{display:flex;justify-content:space-around;align-items:center;flex-direction:row;flex-wrap:nowrap;align-content:center;.counts{justify-content:flex-start;}.counter-img img{padding:0px;}.counter-txt{h2{ font-size:16px; margin-bottom:0px; }p{ margin-bottom:0px; }} }
// ==================Work Location==================
.work-location{h1{font-size:30px;}.heading-txt{margin:0px 0 !important;}.slick-slider{ .slick-next{ right:11px; } .slick-prev{ left:11px; }} }
// ==================Job Demand==================
.benifits{.key-benifits{padding:20px; .heading-txt{ h1{font-size:30px; } }} }
// ==================Job Demand==================
.job-demand{h1{font-size:30px;}.job-box{h4{ font-size:18px; } .company{ button{font-size:14px; } .company-txt{margin:0 0 0 10px !important; } .comp-logo-65p{max-width:65px; } .company-txt{.line-clamp-comp-name{max-width:65px;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;} } } .job-type{ p{margin:0 15px 0 5px !important; } }} }
// =================Impaneled Companies=================
.impaneled-companies{h1{font-size:30px;}.company-slider{width:90% !important;border-radius:20px;background:#FFF;padding:0 10px;box-shadow:5px 5px 13px 0px rgba(224,224,224,0.5);} }
// ==================Job Demand==================
.job-fair{h1{font-size:30px;}.software{ .intro{ h4{font-size:16px; } }} }
// ==================Testimonial==================
.testimonial {h1 {font-size: 30px;}}
// ==================Job Opening==================
.job-opening{h1{font-size:30px;}.MuiTabs-flexContainer{ .MuiButtonBase-root{ margin:0; font-size:15px; }} }
// ==================Job Fair=================
.job-fair {.software {margin-bottom: 15px;}}}