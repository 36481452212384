.company-profile-details-sec{.comp_dtl_page {
.subscrip-card {
border-radius: 4px;
padding: 4px 21px 24px 19px;
background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
h1 {color: #1B375C;font-size: 30px;font-style: normal;font-weight: 700;letter-spacing: -0.84px;}
.cus-btn {.btn-1 {border-radius: 10px;border: 1px solid #1B375C;color: #1B375C;text-align: center;font-size: 18px;font-style: normal;font-weight: 500;line-height: normal;}
.btn-2 {color: #FFF;text-align: center;font-size: 18px;font-style: normal;font-weight: 500;line-height: normal;border-radius: 10px;background: #1B375C;}}
.cus-msg {h3 {color: #2F343A;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;}
p {color: #2F343A;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;}
a {color: #6913D8;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;text-decoration: none;}}
.cus-int {display: flex;justify-content: start;border-radius: 4px;align-items: center;border: 1px solid #E9EAED;background: #FFF;padding: 12px 52px 12px 16px;
p {color: var(--neutral-90, #2F343A);font-size: 14px;font-style: normal;font-weight: 500;line-height: 20px;margin-bottom: 0px;}}
.cus-msg-2 {
h3 {color: #2F343A;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;}}
.cus-team {border-radius: 4px;border: 1px solid #E9EAED;background: #FFF;padding: 6px;text-decoration: none;
h5 {color: #2F343A;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;}
p {color: #6F747A;font-size: 14px;font-style: normal;font-weight: 500;line-height: 20px;margin-bottom: 0px;}}
.cus-team-2 {text-decoration: none;
h5 {color: #2F343A;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;}
p {color: #6F747A;font-size: 14px;font-style: normal;font-weight: 500;line-height: 20px;margin-bottom: 0px;}
.vl {border-left: 1px solid #E9EAED;height: 80px;}}
.outer-form2 {.right-mark-dv{position: relative;.right-mark-img{position: absolute;top: 10px;right: 10px;}}
label {color: #000;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;}
span {color: #FF3A44;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;}
.outer-form-area2 {h6 {color: #000;font-size: 22px;font-style: normal;font-weight: 500;line-height: normal;}}}
}}}
@media only screen and (min-width:993px) and (max-width:1200px) { }
@media only screen and (min-width:768px) and (max-width:992px) { 
.company-profile-details-sec {.comp_dtl_page {.subscrip-card {.cus-int {display: flex;justify-content: start;border-radius: 4px;align-items: center;border: 1px solid #E9EAED;background: #FFF;padding: 12px 0px 12px 10px;}}}}}
@media only screen and (min-width:200px) and (max-width:767px) { }
