.otp-password-section .user-envlop-cls {
  position: relative;
}
.otp-password-section .user-envlop-cls span {
  position: absolute;
  left: 15px;
  top: 8px;
}
.otp-password-section .user-envlop-cls input {
  padding-left: 40px;
}
.otp-password-section .user-envlop-cls2 {
  position: relative;
}
.otp-password-section .user-envlop-cls2 .img {
  position: absolute;
  left: 27px;
  top: 12px;
}
.otp-password-section .user-envlop-cls2 input {
  padding-left: 40px;
}
.otp-password-section li {
  list-style: none;
  padding: 5px 10px 10px;
}
.otp-password-section a {
  text-decoration: none;
}
.otp-password-section .input-text {
  width: 100%;
  height: 44px;
  border: 1px solid #a59b9b;
  border-radius: 10px;
  padding: 10px;
}
.otp-password-section .pt-2 {
  color: #a59b9b;
}
.otp-password-section ul {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
.otp-password-section .pt-3 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  color: #a59b9b;
}
.otp-password-section .para {
  margin-bottom: 0px;
  margin-top: 30px;
  color: #a59b9b;
  text-align: left;
}
.otp-password-section .row-top {
  margin-top: 15px;
}
.otp-password-section form {
  margin: 30px;
}
.otp-password-section .bottm-signup {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #a59b9b;
}
.otp-password-section .bottm-signin {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  color: #a59b9b;
}
.otp-password-section .second-section {
  border-radius: 13px;
  margin-top: 80px;
  margin-bottom: 80px;
  background: #FFF;
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.otp-password-section .para-3 {
  margin-bottom: 1px;
  margin-top: 22px;
}
.otp-password-section .password-custom {
  position: relative;
}
.otp-password-section .password-custom span {
  position: absolute;
  right: 25px;
  top: 14px;
  color: #939191;
}
.otp-password-section .login-btn {
  width: 100%;
}
.otp-password-section .login-btn a {
  border-radius: 30px;
  background: rgb(27, 55, 92);
  color: white;
  font-size: 17px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 145px;
}
.otp-password-section .otp-custom-dv .otp-field {
  flex-direction: row;
  -moz-column-gap: 10px;
       column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.otp-password-section .otp-custom-dv .otp-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}
.otp-password-section .otp-custom-dv .otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.otp-password-section .otp-custom-dv .otp-field input::-webkit-inner-spin-button, .otp-password-section .otp-custom-dv .otp-field input::-webkit-outer-spin-button {
  display: none;
}
.otp-password-section .otp-custom-dv .resend {
  font-size: 12px;
}
.otp-password-section .otp-custom-dv .resend .resend-btn :hover {
  cursor: pointer;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .otp-password-section .login-btn a {
    border-radius: 30px;
    background: rgb(27, 55, 92);
    color: white;
    font-size: 17px;
    box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 54px;
  }
  .otp-password-section .pt-2 {
    color: #a59b9b;
    text-align: left;
  }
  .otp-password-section .pt-2 a {
    color: #a59b9b;
  }
}/*# sourceMappingURL=OtpSendEnterSeeker.css.map */