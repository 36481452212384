.rec-header-footer {.main-header {background: #1B375C;padding: 10px 0;display: flex;align-items: center;
.head-sec-lft {display: flex;align-items: center;
i {color: #fff;}
p {color: #fff;margin-bottom: unset;}
.translator {ul {margin-bottom: unset;list-style: none;padding-left: 0;color: #fff;}}}
.head-sec-rit {display: flex;align-items: center;justify-content: end;
#dropdownMenuButton1 {border: unset;}
.btn-lft button {background-color: unset;border: 1px solid #fff;border-radius: 50px;padding: 5px 20px;}
.btn-rit button {background-color: #fff;color: #000;border-radius: 50px;padding: 5px 20px;}
.dropdown-toggle {color: #fff;}
.dropdown-menu li {display: flex;align-items: center;padding: 5px;}}}
.header-second {background: #FFF;display: flex;align-items: center;box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
nav {background-color: #fff;}
.dropdown-menu li {display: flex;align-items: center;padding: 5px;}
.navbar-nav li {margin: 0 0px;
a {font-weight: 600;color: #000;font-size: 15px;}}
.navbar-brand {color: #1B375C;font-size: 20px;font-style: normal;font-weight: 600;line-height: 22px;text-transform: uppercase;
img{width: 180px;height: 34px;object-fit: cover;}}
.header-sec-btn1 {
a {background-color: unset;border: 1px solid #0A65CC;color: #0A65CC;border-radius: 50px;padding: 8px 10px;text-decoration: none;}}
.header-sec-btn2 {a {background-color: #0A65CC;color: #fff !important;border-radius: 50px;padding: 8px 20px;text-decoration: none;}}}}
// ========================Footer css start==============================
.rec-footer-parent-sec {background: rgba(27, 55, 92, 1);padding: 85px 0px 30px 0px;
.ft-left-dv {text-align: left;.ft-left-logo{width: 180px;height: 34px;object-fit: cover;background-color: #fff;}
.ft-after-logo {.ft-a-tag-last {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;margin-top: 20px;
a {margin-right: 15px;}}
.ft-a-tag {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;padding: 10px 0px;
a {color: #fff;text-decoration: none;margin-right: 20px;}
border-bottom: 1px solid rgba(255, 255, 255, 1);}
.ft-a-tag-2 {padding: 10px 0px;text-align: left;
span {color: #fff;}
a {color: #fff;text-decoration: none;}
border-bottom: 1px solid rgba(255, 255, 255, 1)}
.border-bottom-none {border-bottom: none;}}}
.ft-rt-dv {border: 1px solid rgb(255, 255, 255);height: 292px;border-radius: 8px;display: flex;align-content: center;align-items: center;justify-content: center;flex-direction: column;flex-wrap: nowrap;padding: 0px 40px;
.ft-input {width: 100%;position: relative;
input {width: 100%;border-radius: 5px;outline: none;box-shadow: none;border: none;padding: 5px;border: 0.5px solid rgba(183, 183, 183, 1);}
button {position: absolute;right: 0;top: 0;background: linear-gradient(274.05deg, #FF354D -37.86%, #FE6108 125.4%);box-shadow: 0px 1px 12px 0px rgba(254, 97, 8, 0);border-radius: 5px;border: none;padding: 6px;}}
.inner-img-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;}}
.right-img-dv {text-align: left;
p {margin-bottom: 0px;color: #fff;font-size: 12px;}
h3 {font-size: 28px;color: #FE6108;text-transform: uppercase;}}}
.rec-bottom-st-section {.bottom-strip-head {.bottom-strip {background: rgba(0, 0, 0, 1);padding: 15px 0px;text-align: center;
p {margin-bottom: 0px;color: rgba(209, 213, 219, 1);}
.right-policies {a {text-decoration: none;color: #fff;
&:nth-child(1) {margin-right: 20px;}
&:hover {text-decoration: underline;}}}}}}
@media only screen and (min-width:768px) and (max-width:1080px) {
.rec-header-footer {
.header-second{
.navbar-nav {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;li {
margin: 0px !important;
a {font-weight: 600;color: #000;font-size: 14px;margin: 0px 4px;}}}
.custom-container{padding-left: 0px;padding-right: 0px;.custom-fluid-container{padding-left: 0px;padding-right: 0px;}}
.navbar-nav{li {a {font-weight: 600;color: #000;font-size: 12px;}}}}}
.rec-footer-parent-sec {.align-items-center-md {align-items: center;}
.ft-left-dv {.ft-after-logo {.ft-a-tag-last {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;margin: 10px 0px 20px 0px;}}}
.right-img-dv {text-align: center;}
.ft-rt-dv {
.inner-img-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.inner-img {width: 120px;
img {width: 100%;}}}
.ft-input {margin-top: 10px;
input {padding: 5px 100px 5px 5px;}
button {padding: 6px 10px;color: #fff;}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.rec-header-footer {.header-second {display: block !important;
.header-sec-btn1 {margin: 10px 0 !important;}
nav {z-index: 1;}}
.main-header {.head-sec-lft {display: flex;align-items: center;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;}
.head-sec-rit {margin: 5px 0;display: flex;align-items: center;justify-content: center;align-content: center;flex-wrap: nowrap;flex-direction: row;
.btn-lft {button {padding: 3px 15px;font-size: 15px;}}
.btn-rit {button {padding: 3px 15px;font-size: 15px;}}}
.head-sec-lft {p {font-size: 13px;}}}}
.rec-common-header-sec {.header-second {display: block;}}
.rec-footer-parent-sec {.ft-left-dv {.ft-after-logo {.ft-a-tag-last {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;margin: 10px 0px 20px 0px;}}}
.right-img-dv {text-align: center;}
.ft-rt-dv {height: auto;padding-top: 10px;padding-bottom: 10px;
.inner-img-dv {align-items: center;justify-content: center;.inner-img {img {}}}
.ft-input {margin-top: 10px;
input {padding: 5px 100px 5px 5px;}
button {padding: 6px 10px;color: #fff;}}}}}
