.choose-your-subscription-plan-sec {
.main-subs-plan {.Subscrip-card {flex-shrink: 0;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;padding: 30px;}
.Subscrip-card {padding: 30px;}
.Subscrip-card h1 {color: #1B375C;text-align: center;font-family: Barlow;font-size: 35px;font-style: normal;font-weight: 700;}
.Subscrip-card p {color: #1B375C;text-align: center;font-family: Inter;font-size: 18px;font-style: normal;font-weight: 400;}
.cus-subs-2 .cus-sec {display: flex;padding: 20px 25px;flex-direction: column;align-items: flex-start;gap: 30px;align-self: stretch;border-radius: 20px;border: 2px solid #1B375C;background: #FFF;position: relative;}
// ====================================================cards Section===================
.cus-subs-2 {
.card {.basic {h3 {p {color: #1B375C;font-family: Inter;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal;}}}}
.cus-sec {
.basic {
h3 {color: #1B375C;font-family: Inter;font-size: 18px;font-style: normal;font-weight: 700;line-height: normal;margin-top: 15px;}
p {color: #1B375C;font-family: Inter;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal;text-align: justify;}
.cus-gap {display: flex;flex-direction: column;align-items: flex-start;gap: 38px;margin-top: 35px;}
.cus-price {color: #1B375C;font-family: Inter;font-size: 36px;font-style: normal;font-weight: 700;line-height: normal;margin-bottom: 0px;}
.cus-mon {color: #AEAEAE;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal;}
.new-p {color: #1B375C;font-family: Inter;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal;}
.cus-bottom {height: 1.5px;background-color: #aeaeae66;width: 100%;margin-bottom: 10px;}
.cus-inner-p {color: #1B375C;font-family: Inter;font-size: 12px;font-style: normal;font-weight: 500;line-height: 130%;}
.cus-btn {display: flex;align-items: flex-start;gap: 10px;align-self: stretch;}
.btn-inner {border-radius: 8px;background: #1B375C;color: #fff;}
.free-plan {border-radius: 8px;border: 2px solid #1B375C;background: #fff !important;color: #1B375C !important;
&:hover {border-radius: 8px;background: #1B375C;color: #fff;}}
.cus-list {.cus-head {h2 {color: #1B375C;font-family: Inter;font-size: 15px;font-style: normal;font-weight: 800;line-height: normal;}}}
.cus-inner-list {li {color: #1B375C;font-family: Inter;font-size: 14px;font-style: normal;font-weight: 500;line-height: normal;
i.fa-check-circle {color: green}
i.fa-circle-xmark {color: red}}
.cus-tab {color: #FFF;width: 18px;height: 12px;margin-left: 8px;border-radius: 4px;background: #1B375C;padding: 3px 3px 3px 3px;font-size: 9px;text-align: center;}}}
.cus-ribbon {position: absolute;right: 0px;top: 0px;margin-right: -3px;border-radius: 0px 20px;background: #1B375C;color: #fff;font-size: 13px;padding: 6px 18px;color: #FFF;text-align: right;font-family: Inter;font-size: 15px;font-style: normal;font-weight: 600;line-height: normal}}}}}
@media only screen and (min-width:992px) and (max-width:1080px) {.choose-your-subscription-plan-sec {.main-subs-plan {.cus-subs-2 {.cus-sec {display: flex;padding: 20px 25px;flex-direction: column;align-items: flex-start;gap: 30px;align-self: stretch;border-radius: 20px;border: 2px solid #1B375C;background: #FFF;position: relative;min-height: 665px;}}}}}
@media only screen and (min-width:768px) and (max-width:992px) {.choose-your-subscription-plan-sec {.main-subs-plan {.cus-subs-2 {.cus-sec {display: flex;padding: 20px 25px;flex-direction: column;align-items: flex-start;gap: 30px;align-self: stretch;border-radius: 20px;border: 2px solid #1B375C;background: #FFF;position: relative;min-height: 665px;}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {.choose-your-subscription-plan-sec {.main-subs-plan {.cus-subs-2 {.cus-sec {display: flex;padding: 20px 25px;flex-direction: column;align-items: flex-start;gap: 30px;align-self: stretch;border-radius: 20px;border: 2px solid #1B375C;background: #FFF;position: relative;min-height: 665px;}}}}}