.rec-after-login-sec {
  text-align: center;
}
.rec-after-login-sec .profile-image-parent .box .content .percent {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.rec-after-login-sec .profile-image-parent .box .content .circle-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}
.rec-after-login-sec .profile-image-parent .box .content .percent svg {
  position: relative;
  width: 150px;
  height: 150px;
  transform: rotate(270deg);
}
.rec-after-login-sec .profile-image-parent .box .content .percent svg circle {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 3;
  stroke: rgba(0, 0, 0, 0.05);
  transform: translate(5px, 5px);
}
.rec-after-login-sec .profile-image-parent .box .content .percent svg circle:nth-child(2) {
  stroke: rgb(10, 101, 204);
  stroke-dasharray: 440;
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 2.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rec-after-login-sec .profile-image-parent .box .content .percent .dot {
  position: absolute;
  inset: 5px;
  z-index: 10;
}
.rec-after-login-sec .profile-image-parent .box .content .percent .dot .profile-com-number {
  position: absolute;
  top: -4px;
  left: 56px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}
@keyframes animateDot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(288deg);
  }
}
.rec-after-login-sec .profile-image-parent .box .content .percent .dot::before {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background: rgb(10, 101, 204);
  border-radius: 50%;
}
.rec-after-login-sec .main-header {
  background: #1B375C;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.rec-after-login-sec .main-header .head-sec-lft {
  display: flex;
  align-items: center;
}
.rec-after-login-sec .main-header .head-sec-lft i {
  color: #fff;
}
.rec-after-login-sec .main-header .head-sec-lft p {
  color: #fff;
  margin-bottom: unset;
}
.rec-after-login-sec .main-header .head-sec-lft .translator ul {
  margin-bottom: unset;
  list-style: none;
  padding-left: 0;
  color: #fff;
}
.rec-after-login-sec .main-header .head-sec-rit {
  display: flex;
  align-items: center;
  justify-content: end;
}
.rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv .recent-search-dv {
  color: #fff;
  margin-right: 20px;
}
.rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv .recent-input-dv {
  position: relative;
}
.rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv .recent-input-dv .recent-input {
  padding: 10px 40px 10px 10px;
  border-radius: 50px;
  border: none;
  outline: none;
}
.rec-after-login-sec .main-header .head-sec-rit .search-top-area-dv .recent-input-dv .recent-input-atag {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add {
  text-align: right;
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add h3 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add p {
  color: #fff;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-img {
  padding: 0.375rem 0.75rem;
}
.rec-after-login-sec .main-header .head-sec-rit .head-right-user .user-img img {
  width: 30px;
  height: 30px;
}
.rec-after-login-sec .main-header .head-sec-rit .btn-lft button {
  background-color: unset;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 5px 20px;
}
.rec-after-login-sec .main-header .head-sec-rit .btn-rit button {
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  padding: 5px 20px;
}
.rec-after-login-sec .main-header .head-sec-rit .dropdown-toggle {
  color: #fff;
}
.rec-after-login-sec .main-header .head-sec-rit .dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 5px;
}
.rec-after-login-sec .header-second {
  background: #FFF;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.rec-after-login-sec .header-second .menu-tab {
  margin-right: 20px;
}
.rec-after-login-sec .header-second nav {
  background-color: #fff;
}
.rec-after-login-sec .header-second .navbar-nav li {
  margin: 0 4px;
}
.rec-after-login-sec .header-second .navbar-nav li .after-login-nav-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.rec-after-login-sec .header-second .navbar-nav li a {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 0 6px;
  text-transform: capitalize;
}
.rec-after-login-sec .header-second .navbar-nav li .rec-drop-down-ul li a {
  padding: 8px 10px;
}
.rec-after-login-sec .header-second .navbar-brand {
  color: #1B375C;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 100% */
  text-transform: uppercase;
}
.rec-after-login-sec .header-second .navbar-brand img {
  width: 180px;
  height: 34px;
  -o-object-fit: cover;
     object-fit: cover;
}
.rec-after-login-sec .header-second .header-sec-btn1 button {
  background-color: unset;
  border: 1px solid #0A65CC;
  color: #0A65CC;
  border-radius: 50px;
  padding: 8px 10px;
}
.rec-after-login-sec .header-second .header-sec-btn2 button {
  background-color: #0A65CC;
  color: #fff;
  border-radius: 50px;
  padding: 8px 20px;
}
.rec-after-login-sec .header-second .header-sec-btn2 a {
  background: rgb(10, 101, 204);
  width: 54px;
  height: 54px;
  border-radius: 8px;
  line-height: 54px;
}
.rec-after-login-sec .header-second .navbar-ul-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-after-login-sec .header-second .navbar-ul-custom .nav-item-custom-space {
  margin: auto 6rem;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu {
  height: 100%;
  width: 320px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transform: translateX(-130%);
  transition: 0.5s ease-in-out;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link {
  height: 450px;
  overflow-y: scroll;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link::-webkit-scrollbar-thumb {
  background-color: #1B375C;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-menu-btn {
  width: 60px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0px;
  cursor: pointer;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-menu-btn p {
  margin-bottom: 0px;
  font-size: 16px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist {
  list-style: none;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li img {
  width: 28px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li:last-child {
  margin-bottom: 80px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li span {
  margin-left: 26px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-unlist li span a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  font-weight: 600;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .profile-images {
  margin-top: 30px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .profile-images img {
  width: 110px;
  border-radius: 10px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu .user-name h4 {
  color: #000;
  font-size: 24px;
  margin-top: 20px;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu-after::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.4392156863);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}
.rec-after-login-sec .sidebar-menu-parent .sidebar-menu-toggle {
  height: 100%;
  width: 320px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transform: translateX(0%) !important;
  transition: 0.5ss ease-in-out;
  z-index: 999;
}
.rec-after-login-sec .menu-tab-btn {
  cursor: pointer;
  padding: 15px 5px;
}
.rec-after-login-sec .modal-body-content {
  padding: 30px 40px;
}
.rec-after-login-sec .modal-body-content .modal-body-btn {
  margin-top: 30px;
}
.rec-after-login-sec .modal-body-content .modal-body-btn .modal-body-content-body {
  line-height: 40px;
}
.rec-after-login-sec .modal-body-content .modal-body-btn .logout-cancel-btn {
  width: 40%;
  height: 45px;
  top: 317px;
  left: 108px;
  border-radius: 40px;
  line-height: 45px;
  border: 1px solid rgb(27, 55, 92);
  margin: 0px 10px;
}
.rec-after-login-sec .modal-body-content .modal-body-btn .logout-btn {
  width: 40%;
  height: 45px;
  top: 317px;
  left: 108px;
  border-radius: 40px;
  line-height: 45px;
  background: rgb(27, 55, 92);
  border: none;
  outline: none;
  color: #fff;
  margin: 0px 10px;
}

@media only screen and (min-width: 200px) and (max-width: 360px) {
  .rec-after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-menu-btn {
    width: 60px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
  }
}/*# sourceMappingURL=RecAfterLoginNavbar.css.map */