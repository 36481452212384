.rejected-job-section .job-from-rec {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid rgba(20, 20, 20, 0.05);
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0px;
}
.rejected-job-section .job-from-rec .right-logo-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content {
  margin-left: 20px;
  text-align: left;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content p {
  margin-bottom: 0px;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls h4 {
  margin-bottom: 0px;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls h4 a {
  color: inherit;
  text-decoration: none;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls h6 {
  font-size: 12px;
  color: rgb(125, 90, 226);
  background: rgba(125, 90, 226, 0.1);
  padding: 5px;
  border-radius: 3px;
  margin-left: 20px;
  margin-bottom: 0px;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  margin-left: 0;
  margin-top: 10px;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul li {
  list-style-type: none;
  padding: 0px 30px 0px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul li span {
  padding: 0 3px;
}
.rejected-job-section .job-from-rec .left-btn-flex {
  width: 15%;
}
.rejected-job-section .job-from-rec .left-btn-flex .left-btn {
  background: rgb(244, 32, 57);
  color: #fff;
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
  border: none;
  outline: none;
}
.rejected-job-section .job-from-rec .left-btn-flex .left-btn img {
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
  }
  .rejected-job-section .job-from-rec {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid rgba(20, 20, 20, 0.05);
    padding: 5px;
    border-radius: 8px;
    margin: 10px 0px;
  }
  .rejected-job-section .job-from-rec .right-logo-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content {
    margin-left: 0;
    text-align: left;
    padding: 10px;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content p {
    margin-bottom: 0px;
    text-align: justify;
    word-spacing: 3px;
    word-wrap: break-word;
    word-break: break-all;
    text-align: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    flex-direction: row;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul li {
    padding: 8px 0px 8px 0px;
  }
  .rejected-job-section .job-from-rec .left-btn-flex {
    width: 100%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rejected-job-section .job-from-rec {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid rgba(20, 20, 20, 0.05);
    padding: 5px;
    border-radius: 8px;
    margin: 10px 0px;
  }
  .rejected-job-section .job-from-rec .right-logo-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content {
    margin-left: 0;
    text-align: left;
    padding: 10px;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content p {
    margin-bottom: 0px;
    text-align: justify;
    word-spacing: 3px;
    word-wrap: break-word;
    word-break: break-all;
    text-align: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .post-cls {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    flex-direction: row;
  }
  .rejected-job-section .job-from-rec .right-logo-flex .company-content .icon-working-show ul li {
    padding: 8px 0px 8px 0px;
  }
  .rejected-job-section .job-from-rec .left-btn-flex {
    width: 100%;
  }
}/*# sourceMappingURL=RejectedCard.css.map */