.rec-myprofile-section {.main-head {padding: 1px;border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;}
.main-head h2 {color: #1B375C;font-size: 22px;font-style: normal;line-height: 54px;text-align: center;text-transform: capitalize;
}.main-section {padding: 40px;}.banner-section {border-radius: 4px;background: #FFF;padding: 10px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.banner-second {padding: 55px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.banner-bg {z-index: 9;position: relative;
.banner-bg-child-img-content-parent {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;.profile-images {img {width: 150px;height: 150px;}}
.first-head-bg {h4 {font-size: 24px;margin: 0 0 5px 0;}
p {font-size: 14px;word-break: break-all;}}}}
.banner-bg img {padding: 12px;}
.banner-right-bg .banner-play-btn {border-radius: 10px;border: 1px solid #0A65CC;background: #0A65CC;color: #FFF;font-size: 16px;font-weight: 500;padding: 6px 10px;text-decoration: none;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.banner-right-bg {display: flex;align-items: center;
.banner-right-img {padding: 6px;}}
.first-head-bg {margin-top: 18px;margin-left: 17px;}
.bg-img {padding: 10px;}
.profile-banner-head {position: relative;
.profile-heading-top {position: absolute;top: 35px;left: 50px;
h2 {font-weight: 700;}}}
.user-section {background: #FFF;padding: 20px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -2px -2px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.top-user-section {display: flex;justify-content: space-between;
.top-user-head {h6 {font-size: 24px;}
h4 {margin-bottom: 0px;font-size: 20px;}
.first-para {color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));margin-bottom: 0px;text-decoration: none;font-size: 14px;font-weight: 500;}}}
.see-more-para {span {a {text-decoration: none;}}}
.top-user-section .edit-btn {padding: 10px 20px;
color: #1B375C;font-size: 18px;font-weight: 600;border: 1px solid #51b6dd;border-radius: 13px;background: #fff;text-decoration: none;}
.user-details {display: flex;justify-content: space-between;
p {color: #000;font-weight: 600;word-break: break-all;}
h6 {color: var(--light-text-disabled, rgb(124, 121, 121));}}
.edit-btn2 {color: #51b6dd;font-size: 18px;font-weight: 600;border: 1px solid #51b6dd;border-radius: 13px;background: #fff;height: 45px;width: 121px;}
.border-bottom {border: 1px solid rgba(28, 28, 30, 0.12);width: 100%;}
.card-icon-section {display: flex;justify-content: space-between;
h4 {font-size: 20px;}}
.card-custom {border-radius: 8px;border: 1px solid #F2F2F7;}
.icon-crd-cus {margin-bottom: 10px;}
.icon-card-section {display: flex;align-items: center;align-content: center;justify-content: space-between;flex-direction: row;flex-wrap: nowrap;
.icon-card-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;width: 100%;padding: 0px 12px;word-break: break-all;}
display: flex;align-items: center;align-content: center;justify-content: flex-start;flex-direction: row;flex-wrap: nowrap;
img.social-img {margin-right: 20px;}
p {margin-bottom: 0px;}
.icon-card-custom {border-radius: 10px;border: 1px solid rgba(52, 67, 75, 0.15);}
.trush-icon {display: flex;margin-left: 70px;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.second-bg-section {background: #1DB5BE;}
.second-bg-head h2 {color: #FFF;font-feature-settings: 'liga' off;
font-size: 40px;font-style: normal;font-weight: 700;
letter-spacing: -1.2px;}
.second-bg-head p {color: #111827;text-align: justify;font-feature-settings: 'liga' off;
font-size: 18px;font-style: normal;font-weight: 400;
}.mark-top {margin-top: 120px;}}
.last-element-show-more {margin-bottom: 15px;
a {text-decoration: none;color: #000;font-weight: 500;}}}
@media only screen and (min-width:768px) and (max-width:992px) {.rec-myprofile-section {.banner-bg{.banner-bg-child-img-content-parent {.profile-images {img {width: 150px;height: 150px;}}}}.first-head h2 {font-size: 18px;}
.first-head span {font-size: 18px;}
.profile-banner-head {position: relative;
.profile-heading-top {position: absolute;top: 25px;left: 50px;
h2 {font-weight: 700;}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {.rec-myprofile-section {margin: 10px 0px;.banner-bg {.banner-bg-child-img-content-parent {.profile-images{img {width: 150px;height: 150px;}}}
.banner-bg-child-img-content-parent {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: center;align-items: center;}.first-head-bg {h4 {font-size: 16px;margin: 0 0 5px 0;}}}
.top-user-section .edit-btn {padding: 0px 10px;font-size: 16px;text-decoration: none;}
.user-section {padding: 0px;}
.card-icon-section {display: flex;justify-content: space-between;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;
.trash-btn {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}}
.top-user-section {.d-flex {flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;}
.display-btn-head {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin: 15px 0px;}}
.profile-banner-head {position: relative;
.profile-heading-top {position: absolute;top: 0px;left: 50px;
h2 {font-weight: 700;}}} 
.main-section {padding: 0px !important;}
.icon-card-section {.icon-card-dv {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;width: 100%;padding: 5px;
p {word-break: break-all;}}
img.social-img {margin-right: 3px;}}
.top-user-section {display: flex;justify-content: center;flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;}
.first-head h2 {font-size: 18px;}
.first-head span {font-size: 18px;}}
.reclogincontent-section .login-btn a {padding: 10px 100px;}}