// =============Chart============
.custm-chart {.container {border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.total-counts-dv {margin: 20px 0px;
.total-view-count {.total-cv-downloads {a {text-decoration: none;color: #000;
p {text-align: center;border-radius: 20px;border: 1px solid #ccc;margin-bottom: 0;padding: 2px 5px;}}}}}
.heading-freq {h2 {color: var(--light-text-primary, #1C1C1E);font-size: 24px;}
.cursor-pointer-profile-performance{cursor: pointer;}}
.canvasjs-chart-credit {display: none !important;}
.chart-data {h1 {color: var(--light-text-primary, #1C1C1E);font-size: 32px;font-weight: 700;}
h2 {color: var(--light-text-primary, #1C1C1E);font-size: 24px;}
p {color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));font-size: 14px;}}}
// =============Profile title============
.profile-title {.title {border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;
h1 {color: #1B375C;font-family: Barlow;font-size: 22px;font-weight: 700;}}}
// =============Profile Viewer============
.profile-viewer {
img.logo-img {border: 1px solid black;border-radius: 50%;height: 65px;width: 65px;}
.slick-slider .slick-prev {right: 90px !important;left: unset;}
.slick-slider .slick-next,.slick-slider .slick-prev {font-size: 0;line-height: 0;top: -20%;width: 20px;height: 20px;transform: translate(0, -50%);cursor: pointer;color: transparent;border: none;outline: 0;background: 0 0;}
.slick-slider .slick-prev:before {position: absolute;content: "\f053";color: #ffffff;font-size: 12px;font-family: fontawesome;background: rgb(27, 55, 92);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgb(27, 55, 92);}
.slick-slider .slick-next {right: 40px !important;}
.slick-slider .slick-next,.slick-slider .slick-prev {font-size: 0;line-height: 0;top: -50px;width: 20px;height: 20px;transform: translate(0, -50%);cursor: pointer;color: transparent;border: none;outline: 0;background: 0 0;}
.slick-slider .slick-next:before {position: absolute;content: "\f054";color: #ffffff;font-size: 12px;font-family: fontawesome;background: rgb(27, 55, 92);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgb(27, 55, 92);}
.container {border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.title {h3 {color: var(--light-text-primary, #1C1C1E);font-size: 24px;}}
.profile {.profile-text {h3 {color: var(--light-text-primary, #1C1C1E);font-size: 16px;}
h4 {color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));font-size: 12px;
span {color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));font-size: 12px;}}
a {text-decoration: none;color: #0A65CC;font-size: 16px;font-weight: 600;}}}}

