.resume-builder .back-btn a {
  color: #0A65CC;
  font-size: 16px;
  text-decoration: none;
}
.resume-builder .avatar img {
  width: 68px;
  height: 68px;
  border-radius: 100px;
}
.resume-builder .avatar h4 {
  color: #0D0D12;
  font-size: 16px;
  margin: unset;
}
.resume-builder .avatar h5 {
  color: #36394A;
  font-size: 15px;
  margin: unset;
}
.resume-builder .edit-btn a {
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 50px;
  border: 1px solid #0A65CC;
  color: #0A65CC;
  font-size: 14px;
}
.resume-builder .profile-head ul {
  border-radius: 40px;
  background: #F6F8FA;
  padding: 10px;
}
.resume-builder .profile-head ul li {
  list-style: none;
  color: #36394A;
  font-size: 16px;
}
.resume-builder .profile-head ul li img {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.resume-builder .cv-info {
  /* ==============Skills=============== */
  /* ==============Tools=============== */
  /* ==============Portfolio=============== */
  /* ==============Language=============== */
  /* ==============Education=============== */
  /* ==============Social Links=============== */
}
.resume-builder .cv-info .summary {
  border-radius: 10px;
  border: 1px solid #ECEFF3;
  background: #FFF;
  padding: 15px;
}
.resume-builder .cv-info .summary .heading {
  border-bottom: 1px dashed #ECEFF3;
}
.resume-builder .cv-info .summary h4 {
  color: #36394A;
  font-size: 14px;
}
.resume-builder .cv-info .summary h5 {
  color: #666D80;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 999px;
  border: 1px solid #ECEFF3;
  background: #F6F8FA;
  padding: 8px 15px;
}
.resume-builder .cv-info .summary ul li {
  color: #36394A;
  font-size: 14px;
}
.resume-builder .cv-info .experience .exp-info img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}
.resume-builder .cv-info .experience .exp-info h3 {
  color: #36394A;
  font-size: 16px;
  margin: 0 0 6px 0;
}
.resume-builder .cv-info .experience .exp-info h4 {
  color: #666D80;
  font-size: 14px;
}
.resume-builder .cv-info .experience .exp-info .textarea ul {
  padding: unset;
  margin: 0 0 10px 0;
}
.resume-builder .cv-info .experience .exp-info .textarea ul li {
  list-style: none;
  padding: 0 15px 0 0;
  color: #36394A;
  font-size: 14px;
}
.resume-builder .cv-info .experience .exp-info .textarea ul li:nth-child(4) {
  color: #818898;
  font-size: 16px;
}
.resume-builder .cv-info .experience .exp-info .textarea .list ul {
  padding: 0 0 0 15px;
}
.resume-builder .cv-info .experience .exp-info .textarea .list ul li {
  color: #36394A;
  font-size: 14px;
  list-style: disc;
}
.resume-builder .cv-info .experience .exp-info .textarea .list2 {
  padding: 0 0 0 15px;
}
.resume-builder .cv-info .experience .exp-info .textarea .list2 li {
  list-style: disc;
}
.resume-builder .cv-info .certification .exp-info img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}
.resume-builder .cv-info .certification .exp-info h3 {
  color: #36394A;
  font-size: 16px;
  margin: 0 0 6px 0;
}
.resume-builder .cv-info .certification .exp-info h4 {
  color: #666D80;
  font-size: 16px;
}
.resume-builder .cv-info .certification .exp-info .textarea ul {
  padding: unset;
  margin: 0 0 10px 0;
}
.resume-builder .cv-info .certification .exp-info .textarea ul li {
  list-style: none;
  padding: 0 15px 0 0;
  color: #36394A;
  font-size: 14px;
}
.resume-builder .cv-info .certification .exp-info .textarea ul li:nth-child(4) {
  color: #818898;
  font-size: 16px;
}
.resume-builder .cv-info .certification .exp-info .textarea .list ul {
  padding: 0 0 0 15px;
}
.resume-builder .cv-info .certification .exp-info .textarea .list ul li {
  color: #36394A;
  font-size: 16px;
  list-style: disc;
}
.resume-builder .cv-info .certification .exp-info .textarea .list2 {
  padding: 0 0 0 15px;
}
.resume-builder .cv-info .certification .exp-info .textarea .list2 li {
  list-style: disc;
}
.resume-builder .cv-info .certification .exp-info p {
  color: #818898;
  font-size: 14px;
}
.resume-builder .cv-info .skills {
  border-radius: 10px;
  border: 1px solid #ECEFF3;
  background: #FFF;
  padding: 15px;
}
.resume-builder .cv-info .skills .heading {
  border-bottom: 1px dashed #ECEFF3;
}
.resume-builder .cv-info .skills h4 {
  color: #36394A;
  font-size: 14px;
}
.resume-builder .cv-info .skills h5 {
  color: #666D80;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 999px;
  border: 1px solid #ECEFF3;
  background: #F6F8FA;
  padding: 8px 15px;
}
.resume-builder .cv-info .skills ul li {
  color: #36394A;
  font-size: 15px;
  font-weight: 400;
}
.resume-builder .cv-info .tools ul {
  padding: unset;
}
.resume-builder .cv-info .tools ul li {
  list-style: none;
  padding: 0 4px;
  margin: 3px 3px;
  color: #36394A;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #ECEFF3;
}
.resume-builder .cv-info .portfolio h3 {
  margin: 5px 0 5px 0;
  color: #36394A;
  font-size: 16px;
  font-weight: 500;
}
.resume-builder .cv-info .portfolio p {
  color: #818898;
  font-size: 14px;
}
.resume-builder .cv-info .portfolio .portfolio-link img {
  width: 15px;
  height: 15px;
}
.resume-builder .cv-info .portfolio .portfolio-link p {
  color: #5479FF;
  font-size: 14px;
}
.resume-builder .cv-info .portfolio .custm-port-info {
  padding: 5px;
  border-radius: 8px;
  background: #F6F8FA;
}
.resume-builder .cv-info .language h3 {
  margin: 5px 0 5px 0;
  color: #36394A;
  font-size: 16px;
  font-weight: 500;
}
.resume-builder .cv-info .language .custm-lang {
  color: #666D80;
  font-size: 15px;
}
.resume-builder .cv-info .education .custm-education h6 {
  color: #36394A;
  font-size: 15px;
}
.resume-builder .cv-info .education .custm-education p {
  margin: unset;
  color: #666D80;
  font-size: 14px;
}
.resume-builder .cv-info .social p {
  color: #666D80;
  font-size: 14px;
}
.resume-builder .cv-info .social p a {
  color: #36394A;
  font-size: 14px;
  text-decoration-line: underline;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .profile-head ul {
    display: block !important;
  }
  .cv-info .experience .exp-info {
    display: block !important;
  }
  .cv-info .experience .exp-info ul {
    display: block !important;
  }
}/*# sourceMappingURL=resumebuilder.css.map */