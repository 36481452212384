.add-job-titles-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-job-titles-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-job-titles-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-job-titles-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-job-titles-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-job-titles-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-job-titles-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-job-titles-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-job-titles-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-job-titles-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}

.add-job-types-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-job-types-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-job-types-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-job-types-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-job-types-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-job-types-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-job-types-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-job-types-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-job-types-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-job-types-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}

.add-work-schedule-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-work-schedule-body-parent .add-work-schedule-body {
  height: 50vh;
  overflow-y: scroll;
}
.add-work-schedule-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-work-schedule-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-work-schedule-body-parent .add-job-types-body h5 {
  font-size: 19px;
  font-weight: 600;
  padding-top: 30px;
}
.add-work-schedule-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-work-schedule-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-work-schedule-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-work-schedule-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-work-schedule-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-work-schedule-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-work-schedule-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}

.add-pay-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-pay-body-parent .add-work-schedule-body {
  height: 50vh;
  overflow-y: scroll;
}
.add-pay-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-pay-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-pay-body-parent .add-job-types-body h5 {
  font-size: 19px;
  font-weight: 600;
  padding-top: 30px;
}
.add-pay-body-parent .add-job-types-body .eye-notice {
  background-color: rgb(238, 241, 254);
  border: 1px solid rgb(199, 210, 246);
  padding: 20px 10px;
  border-radius: 5px;
}
.add-pay-body-parent .add-job-types-body .eye-notice p {
  margin-bottom: 0;
}
.add-pay-body-parent .add-job-types-body .eye-notice p svg {
  inline-size: 1.5rem;
  block-size: 1.5rem;
  color: rgb(63, 115, 211);
}
.add-pay-body-parent .add-job-types-body .eye-notice p span {
  margin-left: 20px;
}
.add-pay-body-parent .add-job-types-body .minimum-base-pay-inpt {
  margin-top: 20px;
}
.add-pay-body-parent .add-job-types-body .minimum-base-pay-inpt label {
  font-weight: 600;
}
.add-pay-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-pay-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-pay-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-pay-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-pay-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-pay-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-pay-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}

.add-relocation-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-relocation-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-relocation-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-relocation-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-relocation-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-relocation-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-relocation-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-relocation-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-relocation-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-relocation-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}

.add-remote-body-parent.show {
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.add-remote-body-parent .modal-header h1 {
  font-size: 22px;
}
.add-remote-body-parent .add-job-types-body h4 {
  font-size: 19px;
  font-weight: 600;
}
.add-remote-body-parent .add-job-types-body .input-seletion-dv {
  display: flex;
  padding: 6px 0px;
}
.add-remote-body-parent .add-job-types-body .input-seletion-dv input {
  width: 22px;
  height: 22px;
}
.add-remote-body-parent .add-job-types-body .input-seletion-dv label {
  margin-left: 10px;
  font-size: 16px;
}
.add-remote-body-parent .cancel-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: unset;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #000;
}
.add-remote-body-parent .cancel-btn:hover {
  background-color: #0067cc;
  color: #fff;
}
.add-remote-body-parent .save-btn {
  padding: 8px 17px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid rgba(10, 101, 204, 0.5);
  background-color: #0067cc;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
}
.add-remote-body-parent .save-btn:hover {
  background-color: transparent;
  color: #000;
}/*# sourceMappingURL=CareerPreferencesPopups.css.map */