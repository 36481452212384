
.company-review-form{
h1{font-size: 35px;line-height: 1.5;font-weight: 700;}
.form-label{font-size: 16px;line-height: 1.5;font-weight: 600;color: #000;margin-bottom: 15px;}
.rounded-md{border-radius: 6px;border: 1px solid #f1f1f1;box-shadow: 0px 0px 5px 0px #00000047;}
.radio-group {display: flex;justify-content: start;align-items: center;  }
.radio-group input[type="radio"] {display: none;}  
.radio-group label {padding: 10px 20px;border: 1px solid #ccc;margin-right: -1px;cursor: pointer;  }
.radio-group label:hover {background-color: #f0f0f0;  }
.radio-group input[type="radio"]:checked + label {background-color: #0056b3;color: white;border-color: #0056b3;}
.radio-group label:first-of-type {border-radius: 5px 0 0 5px;}  
.radio-group label:last-of-type {border-radius: 0 5px 5px 0;}  
  .radio-group label:not(:first-of-type):not(:last-of-type) {border-radius: 0;}  .submit-btn{width: fit-content;padding: 6px 12px;border-radius: 6px;background: #1B375C;box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);color: #fff;font-size: 17px;font-weight: 500;border: none;text-decoration: none;  }}