.Questions-section .modal-body p {
  font-size: 14px !important;
  font-weight: 400;
  color: #595959 !important;
}
.Questions-section .modal-title {
  font-size: 20px;
  color: rgb(45, 45, 45);
  font-weight: 700;
}
.Questions-section .reset-filters {
  font-size: 15px;
  color: #1b375c;
  cursor: pointer;
}
.Questions-section .help-full-ans h3 {
  font-size: 18px;
  font-weight: 700;
  color: #2d2d2d;
  line-height: 1.5;
}
.Questions-section .help-full-ans li p {
  font-size: 0.875rem !important;
  font-weight: inherit !important;
  color: #2d2d2d !important;
  line-height: 24px !important;
}
.Questions-section .ans {
  background-color: #fceef4;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
.Questions-section .ans p {
  color: #9d2b6b !important;
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  line-height: 18px !important;
}
.Questions-section .font-semibold {
  font-weight: 600;
}
.Questions-section .nav-tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.Questions-section .nav-link {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  border: 1px solid transparent;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #f8f9fa;
}
.Questions-section .nav-link img {
  margin-right: 0.5rem;
  height: 16px;
}
.Questions-section .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #ddd;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}
.Questions-section .tab-content {
  border-top: none;
}
.Questions-section .tab-pane {
  display: none;
}
.Questions-section .tab-pane.active {
  display: block;
}
.Questions-section .box-rounded svg {
  fill: #9d2b6b;
}
.Questions-section .progress-bar {
  background-color: #9d2b6b;
}
.Questions-section .progress {
  height: 8px;
}
.Questions-section .border-bottom {
  border-bottom: 1px solid #e4e2e0;
}
.Questions-section .border-dotted {
  border-bottom: 1px dashed #949494;
}
.Questions-section .share-btn {
  padding: 8px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
}
.Questions-section .share-btn svg {
  fill: #767676 !important;
  width: 20px !important;
  height: 20px !important;
}
.Questions-section .share-btn:hover, .Questions-section .rating-btn:hover {
  color: rgb(22, 64, 129);
  background-color: rgb(238, 241, 254);
}
.Questions-section .rating-btn {
  padding: 5px 18px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  background: #f3f2f1;
  border: none;
}
.Questions-section .feedback span {
  font-size: 0.75rem;
  color: #767676 !important;
}
.Questions-section .feedback p {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  line-height: 24px;
}
.Questions-section .list-style-none {
  list-style: none;
}
.Questions-section .useful-review {
  background: #eef1fe;
  border-radius: 8px;
  padding: 12px 15px;
}
.Questions-section .useful-review span {
  color: #164081;
}
.Questions-section .useful-review h2 {
  color: #164081;
  font-weight: 600;
  font-size: 17px;
}
.Questions-section .review-box-container {
  flex-wrap: wrap;
}
.Questions-section .review-box-container .review-box {
  padding: 6px 14px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  background: #faf9f8;
  border: 1px solid #949494;
}
.Questions-section .review-box-container .review-box:hover {
  background-color: #eef1fe;
  border-color: #6792f0;
}
.Questions-section .review-box-container .active-question-type {
  background: #1b375c;
  color: #fff;
}
.Questions-section .review-box-container .active-question-type:hover {
  background-color: #4f525f;
  border-color: #000;
}
.Questions-section h3 {
  color: #2d2d2d;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25;
}
.Questions-section h5 {
  font-weight: 400;
  color: #595959;
}
.Questions-section h5 {
  font-size: 18px;
}
.Questions-section h4 {
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
}
.Questions-section .rounded-t {
  border-radius: 0.5rem 0.5rem 0px 0px;
}
.Questions-section .rounded-b {
  border-radius: 0px 0px 0.5rem 0.5rem;
}
.Questions-section .form-section, .Questions-section .form-section-2 {
  padding: 1.5rem 1rem 1.5rem;
  background-color: #f3f2f1;
}
.Questions-section .form-section .sort-rating, .Questions-section .form-section-2 .sort-rating {
  border-left: 1px solid #b6b6b6;
  border-radius: 6px 0 0 6px;
  border-right: 1px solid #b6b6b6;
}
.Questions-section .form-section .date, .Questions-section .form-section-2 .date {
  border-radius: 0px 6px 6px 0px;
  border-right: 1px solid #b6b6b6;
}
.Questions-section .form-section .sort-rating, .Questions-section .form-section .date, .Questions-section .form-section-2 .sort-rating, .Questions-section .form-section-2 .date {
  padding: 6px 14px;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.Questions-section .form-section label, .Questions-section .form-section-2 label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 6px;
}
.Questions-section .form-section .rating, .Questions-section .form-section-2 .rating {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.Questions-section .form-section .rating .box, .Questions-section .form-section-2 .rating .box {
  border: 1px solid #b6b6b6;
  padding: 6px 14px;
  background: #fff;
  border-radius: 50px;
}
.Questions-section .form-section .rating span, .Questions-section .form-section-2 .rating span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}
.Questions-section .form-section .rating svg, .Questions-section .form-section-2 .rating svg {
  width: 20px;
  fill: #9d2b6b;
}

.claimed-profile h4 {
  color: #595959;
  font-weight: 600;
  font-size: 16px;
}
.claimed-profile svg {
  fill: #1f662c;
  width: 25px;
}

.update-button {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}

.update-button:hover {
  background-color: rgba(59, 124, 227, 0.0705882353);
}

.accordion h4 {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #2d2d2d;
}

.rate-recent-company {
  background: #eef1fe;
  border-radius: 8px;
  padding: 30px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rate-recent-company .rate-star {
  background: #eef1fe;
  border-radius: 6px;
  padding: 6px 15px;
  width: -moz-fit-content;
  width: fit-content;
}
.rate-recent-company p {
  font-size: 14px;
}
.rate-recent-company h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5rem;
  color: #2d2d2d;
}
.rate-recent-company svg {
  width: 25px !important;
  height: 25px !important;
}

.box-rounded {
  border-radius: 0.5rem;
  padding: 1.5rem;
  border: 1px solid #d4d2d0;
}
.box-rounded h4 {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #2d2d2d;
}
.box-rounded p {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
}

.Answers-section .review-box {
  padding: 6px 14px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  background: #faf9f8;
  border: 1px solid #949494;
}
.Answers-section .review-box:hover {
  background-color: #eef1fe;
  border-color: #6792f0;
}
.Answers-section .useful-review {
  background: #eef1fe;
  border-radius: 8px;
  padding: 12px 15px;
}
.Answers-section .useful-review span {
  color: #164081;
}
.Answers-section .useful-review h2 {
  color: #164081;
  font-weight: 600;
  font-size: 17px;
}
.Answers-section .radio-group {
  display: flex;
  justify-content: start;
  align-items: center;
}
.Answers-section .radio-group input[type=radio] {
  display: none;
}
.Answers-section .radio-group label {
  padding: 8px 20px;
  border: 1px solid #ccc;
  margin-right: -1px;
  cursor: pointer;
}
.Answers-section .radio-group label:hover {
  background-color: #f0f0f0;
}
.Answers-section .radio-group input[type=radio]:checked + label {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}
.Answers-section .radio-group label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.Answers-section .radio-group label:last-of-type {
  border-radius: 0 5px 5px 0;
}
.Answers-section .radio-group label:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
.Answers-section h3 {
  font-size: 15px;
  font-weight: 600;
  color: #595959;
}
.Answers-section p {
  font-size: 14px !important;
  font-weight: 400;
  color: #595959;
}/*# sourceMappingURL=Questions.css.map */