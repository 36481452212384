.company-review-form {h4 {font-size: 23px;color: #2d2d2d;font-weight: 600;}
#loginPopUpModalWriteReview {btn.popup-login-btn {padding: 5px 20px;border-radius: 40px;background: #1B375C;text-decoration: none;color: #FFF;text-align: center;font-size: 16px;font-weight: 500;border: 1px solid #1B375C;width: fit-content;}}
p {color: #595959;font-size: 15px;font-weight: 400;}
.rounded-box {border: 1px solid #ccc;border-radius: 5px;padding: 10px 15px;}
.text-xs {font-size: 12px;}
.text-sm {font-size: 14px;}
h1 {font-size: 35px;line-height: 1.5;font-weight: 700;}
.form-label {font-size: 16px;line-height: 1.5;font-weight: 600;color: #000;margin-bottom: 15px;}
.rounded-md {border-radius: 6px;border: 1px solid #f1f1f1;box-shadow: 0px 0px 5px 0px #00000047;}
.radio-group {display: flex;justify-content: start;align-items: center;}
.radio-group input[type="radio"] {display: none;}
.radio-group label {padding: 10px 20px;width: 100%;border: 1px solid #ccc;margin-right: -1px;cursor: pointer;display: flex;justify-content: center;align-items: center;}
.radio-group label:hover {background-color: #f0f0f0;}
.radio-group input[type="radio"]:checked+label {background-color: #0056b3;color: white;border-color: #0056b3;}
.radio-group label:first-of-type {border-radius: 5px 0 0 5px;}
.radio-group label:last-of-type {border-radius: 0 5px 5px 0;}
.radio-group label:not(:first-of-type):not(:last-of-type) {border-radius: 0;}
.submit-btn {width: 100%;padding: 8px 12px;border-radius: 6px;background: #1B375C;box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);color: #fff;font-size: 17px;font-weight: 500;border: none;text-decoration: none;}
@media (max-width:1200px){.how-long-till-interview { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-row-gap: 10px; grid-column-gap: 10px;
 } }
@media (max-width:767px){ .how-long-till-interview {display: grid;grid-template-columns: 1fr 1fr;grid-row-gap: 10px;grid-column-gap: 10px;}}
@media (max-width:480px){.how-long-till-interview { display: grid; grid-template-columns:1fr; grid-row-gap: 10px; grid-column-gap: 10px; } }}