.news-header {
.news-heading {border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;
h1 {color: #1B375C;font-family: Barlow;font-size: 22px;font-weight: 700;margin: unset;}}}
// =============News Banner===============
.news-banner {.custm-banner-area {background-size: cover;background-position: 100%;height: 450px;
.banner-info {height: 100%;display: flex;flex-direction: column;justify-content: space-around;}
.holy-btn {padding: 0 0 0 20px;
a {padding: 8px 10px;background: #08F;color: #FFF;font-size: 14px;text-decoration: none;display: inline-block;}}
.banner-text {padding: 20px;
h2 {color: #FFF;font-size: 24px;font-weight: 700;margin: 0 0 30px 0;}
h3 {color: #FFF;font-size: 18px;font-weight: 400;line-height: 125.6%;}}}
.banner-cards {padding: unset;
.card-img {position: relative;width: 40%;
a {position: absolute;left: 8px;top: 8px;padding: 2px 5px;text-decoration: none;color: #FFF;font-size: 8px;}}
.upper-title {width: 60%;
.para {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}}
.c1 {a {background: #08F;}}
.c2 {a {background: #49CFE8;}}
.c3 {a {background: #2ECC71;}}
ul {padding: unset;margin: 0 0 5px 0;
li {list-style: none;}
li:nth-child(1) {color: #393939;font-size: 10px;}
li:nth-child(2) {color: rgba(57, 57, 57, 0.60);font-size: 10px;}}
h3 {color: #393939;font-size: 15px;font-weight: 500;}}}
// =============Entertainment===============
.entertainment {
h1 {color: #F65050;font-size: 24px;font-style: normal;font-weight: 500;}
.col-lg-9 {hr {margin: unset;width: 90%;height: 1px;background-color: #000000;border: none;}}
.news-display {img {margin: 0 0 20px 0;}
ul {padding: unset;margin: 0 0 5px 0;
li {list-style: none;}
li:nth-child(1) {color: #393939;font-size: 10px;}
li:nth-child(2) {color: rgba(57, 57, 57, 0.60);font-size: 10px;}}
h3 {color: #393939;font-size: 18px;font-weight: 500;}
p {color: rgba(57, 57, 57, 0.60);font-size: 14px;line-height: 111.1%;}}
.news-list {ul {padding: unset;margin: 0 0 5px 0;
li {list-style: none;}
li:nth-child(1) {color: #393939;font-size: 10px;}
li:nth-child(2) {color: rgba(57, 57, 57, 0.60);font-size: 10px;}}
h3 {color: #393939;font-size: 15px;font-weight: 500;}}
.nav-tabs {.nav-link {border: unset;color: #000000 !important;font-size: 14px;padding: 5px 10px 10px 10px;}
.nav-link.active {color: #F65050 !important;font-size: 14px;border-bottom: 1px solid #F65050;}}}
// =============Sports===============
.sports {
h1 {color: #F65050;font-size: 24px;font-style: normal;font-weight: 500;}
.col-lg-9 {hr {margin: unset;width: 90%;height: 1px;background-color: #000000;border: none;}}
.news-display {img {margin: 0 0 20px 0;}
.first-description{text-align: justify;}ul {padding: unset;margin: 0 0 5px 0;
li {list-style: none;}
li:nth-child(1) {color: #393939;font-size: 10px;}
li:nth-child(2) {color: rgba(57, 57, 57, 0.60);font-size: 10px;}}
h3 {color: #393939;font-size: 18px;font-weight: 500;}p {color: rgba(57, 57, 57, 0.60);font-size: 14px;line-height: 111.1%;}
}
.news-list {ul {padding: unset;margin: 0 0 5px 0;
li {list-style: none;}
li:nth-child(1) {color: #393939;font-size: 10px;}
li:nth-child(2) {color: rgba(57, 57, 57, 0.60);font-size: 10px;}}
h3 {color: #393939;font-size: 15px;font-weight: 500;}}
.title {h3 {color: #F65050;font-size: 14px;font-style: normal;font-weight: 500;line-height: normal;}}
.social-box {background: #4281FF;width: 130px;height: 40px;img {width: 16px;height: 16px;}
.followers {h4,h5 {margin: 5px;}
h4 {color: #FFF;font-size: 8px;}h5 {color: #FFF;font-size: 8px;}}}
.cus-ins {background: #C23785 !important;}
.cus-in {background: #087DD8 !important;}
.cus-tw {background: #42C0F5 !important;}}
@media only screen and (min-width: 200px) and (max-width: 767px) {
.news-banner {.custm-banner-area {height: 300px;}
.line-clamp-2 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}}}
.line-clamp-2 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.line-clamp-4-news {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;}
.remove-link-property-news-page {text-decoration: none;}
.remove-link-property-news-page-text-white {text-decoration: none;color: white;}
span.share-buttons-news {.st-center {.st-btn {display: inline-block !important;}}}
.blog-listing-parent {.news-list-content-parent {h6 {.category-tag {background-color: #0D6EFD;padding: 2px 5px;
font-size: 14px;}
.category-tag-trending {background-color: rgba(12, 141, 5, 0.75);padding: 2px 5px;font-size: 14px;}}
.date {h5 {margin: 0 12px;padding: 5px 20px;border-radius: 2px;background: #ffe7f9;color: var(--Text, #151875);
font-size: 14px;font-weight: 600;}}}}
.blog-list {.blog-info {.blog-card {.blog-category {.date {
span.make-span-colored-tag {background: #08F;color: white}
span.make-span-colored-tag-trending {background: rgba(12, 141, 5, 0.75);color: white}}}
.news-detail-page-description{text-align: justify;}}}}