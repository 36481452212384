.assessment-score-sec {
  /*  a tag for  View all question  */
}
.assessment-score-sec .boxshadow_head {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5019607843);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  border: 1px solid rgba(241, 244, 248, 0.5019607843);
}
.assessment-score-sec .cust_back {
  margin-left: 1px;
}
.assessment-score-sec .cust_performance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.assessment-score-sec .heading_style {
  color: #1B375C;
  font-size: 20px;
  font-weight: 700;
  margin-right: 1px;
}
.assessment-score-sec .heading_style1 {
  color: #1B375C;
  font-size: 20px;
  font-weight: 700;
  margin-left: 1px;
}
.assessment-score-sec .boxshadow_inner {
  border-radius: 4px;
  border: 1px solid rgba(241, 244, 248, 0.5019607843);
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  margin: 5px;
}
.assessment-score-sec .circle {
  width: 150px;
  height: 150px;
  padding: 0px;
  background: #fff;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif; /* shadow */
  border-radius: 100px;
  border: 1px solid #1B375C;
  background: var(--gray-white, #FFF);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25), -1px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.assessment-score-sec .circle_content {
  margin-left: 17%;
  margin-right: 20px;
  margin-top: 46px;
  font-size: 44px;
}
.assessment-score-sec .cust_gray {
  color: gray;
}
.assessment-score-sec .color {
  background-color: goldenrod;
}
.assessment-score-sec a {
  color: #0A65CC;
  font-size: 16px;
}
.assessment-score-sec .text_sujjection {
  color: #1B375C;
  font-size: 24px;
  font-weight: 500;
}
.assessment-score-sec .button_width {
  width: 200px;
}
.assessment-score-sec .button_width1 {
  width: 200px;
  background-color: #1B375C;
}
.assessment-score-sec .boxshadow_progressbar {
  box-shadow: 2.4771192074px 2.4771192074px 18.5783939362px 0px rgba(166, 171, 189, 0.2509803922);
  box-shadow: -1.2385596037px -1.2385596037px 16.1012744904px 0px #FAFBFF;
}
.assessment-score-sec .cust_img {
  max-width: 120px;
  max-height: 120px;
}

.semicircle-container span {
  top: 50%;
  font-size: 28px;
  font-weight: 600;
}

/* Media querry sm  */
@media screen and ((min-width: 320px) and (max-width: 576px)) {
  .assessment-score-sec .cust_img {
    max-width: 85px;
    max-height: 85px;
  }
  .assessment-score-sec .cust_back {
    margin-top: 0px;
    font-size: 18px;
    margin-left: -27px;
  }
  .assessment-score-sec .heading_color {
    font-size: 18px;
  }
  .assessment-score-sec .heading_style {
    font-size: 15px;
    padding: 8px;
    margin-right: 0px;
  }
  .assessment-score-sec .heading_style1 {
    font-size: 15px;
    padding: 2px;
    margin-left: 21px;
  }
  .assessment-score-sec .circle {
    width: 140px;
    height: 140px;
    text-align: center;
    margin: 0 auto;
  }
  .assessment-score-sec .boxshadow_inner {
    margin: 0px;
  }
  .assessment-score-sec .text_sujjection {
    font-size: 18px;
  }
}/*# sourceMappingURL=AssessmentScore.css.map */