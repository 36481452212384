// ===================new slider css start=======================
.career-with-large-employement {
.similer-post-heading {margin-bottom: 50px;}
/*=============================================== silder-start-css=============================== */
.footer-list-card ul li {list-style: none;color: #565656;font-size: 16px;font-weight: 400;margin-top: 16px;}
.footer-list-card ul li img {margin-right: 10px;}
.footer-list-card ul {padding: unset;}
.card-custom {border: none;padding: 20px !important;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.card-first-custom {border: none;padding: 40px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
img.industry-logo {max-width: 65px;min-width: 65px;max-height: 65px;min-height: 65px;}}
.card-second-custom {border: none;padding: 50px;
border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.card-inner-item {text-align: center;
img {text-align: center;}}}
.card-custom-2 {border: none;padding: 35px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
}.card-custom-3 {border: none;padding: 30px;
border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.footer-heading h3 {color: #141414;
font-size: 16px;font-weight: 500;}
.footer-heading span a {color: #2B85FF;text-decoration: none;font-size: 15px;margin-left: 15px;font-weight: 500;border-radius: 3px;background: rgba(43, 133, 255, 0.10);}
.footer-icon span a {color: #FE6108;text-decoration: none;font-size: 15px;margin-left: 15px;font-weight: 500;border-radius: 3px;background: rgba(254, 97, 8, 0.10);
}
.footer-heading p {color: #565656;font-size: 15px;font-weight: 400;}
.second-section {border-radius: 4px;background: #FFF;margin-top: 30px;padding: 30px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.cursor-pointer{cursor: pointer;}}
.card-heading h3 {color: #141414;font-size: 26px;font-weight: 700;}
.card-second-heading h6 {color: #141414;text-align: center;font-size: 20px;font-weight: 500;display: -webkit-box;
-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;}}

@media only screen and (min-width:200px) and (max-width:767px) {.career-with-large-employement {.career-section .custom-form-section {
display: flex !important;flex-direction: column;}
.search-custom .form-custom {height: 41px;width: 296px;margin-top: 6px;}
.career-section {padding: 0px;}
.form-custom-btn {width: 89px;height: 41px;font-size: 15px;margin-top: 11px;}
.career-section .career-top-heading h2 {font-size: 28px;}
.career-section .career-top-heading span {font-size: 28px;}}}
// ===============old slider css start=======================
.career-with-large-employement {margin: 10px 0px 30px 0px;
.second-section {
.slick-slider {
.slick-next:before,
.slick-prev:before {font-size: 26px;line-height: 1;opacity: .75;color: rgba(27, 55, 92, 1);}
.slick-next,
.slick-prev {font-size: 0;line-height: 0;top: -20%;width: 20px;height: 20px;-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);transform: translate(0, -50%);cursor: pointer;color: transparent;border: none;outline: 0;background: 0 0;}
.slick-next {right: 40px !important;}
.slick-prev {right: 90px !important;left: unset;}
.slick-next:before {position: absolute;content: "\f054";color: #ffffff;font-size: 12px;font-family: fontawesome;background: rgba(27, 55, 92, 1);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgba(27, 55, 92, 1);}
.slick-prev:before {position: absolute;content: "\f053";color: #ffffff;font-size: 12px;font-family: fontawesome;
background: rgba(27, 55, 92, 1);border-radius: 50%;width: 25px;height: 25px;line-height: 24px;border: 1px solid rgba(27, 55, 92, 1);}}}
/* silder css start */
.second-section {border-radius: 4px;background: #FFF;margin-top: 30px;padding: 30px 15px 15px 15px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.similer-post-heading {
h2 {font-size: 32px;font-weight: 700;ext-align: left;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;}}}
.card {padding: 20px !important;margin: 0px 10px;text-align: center;justify-content: center;align-items: center;height: 180px;.card-sub-dv {display: flex;}
.footer-heading {text-align: left;h3 {color: #141414;font-size: 20px;font-weight: 500;}
p {color: #565656;font-size: 14px;font-weight: 400;span {
a {color: #2B85FF;text-decoration: none;font-size: 12px;margin-left: 25px;font-weight: 500;border-radius: 3px;background: rgba(43, 133, 255, 0.10);padding: 3px 6px;
}}}}
.footer-list-card {text-align: left;ul {padding: unset;
li {list-style: none;color: #565656;font-size: 16px;font-weight: 400;margin-top: 5px;
img {margin-right: 10px;display: inline-block;}}}}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.career-with-large-employement {
.card {margin: 0px 2px;
.footer-heading {
p {color: #565656;font-size: 14px;font-weight: 400;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
span {a {margin-left: 0;}}
}
}

padding: 20px !important;.card-sub-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;
align-items: center;}}}
}

@media only screen and (min-width:767px) and (max-width:992px) {
.career-with-large-employement {
.card {margin: 0px 2px;
.footer-heading {p {color: #565656;
font-size: 14px;font-weight: 400;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;

span {a {margin-left: 0;
}}}}

padding: 20px !important;

.card-sub-dv {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}}}}