.rec-create-an-account-popup .card {
  border: none;
  width: 496px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rec-create-an-account-popup .card h6 {
  color: var(--grey-grey-1, #0D0D0D);
  font-size: 32px;
  font-weight: 600;
  line-height: 39.4px;
  text-transform: capitalize;
}
.rec-create-an-account-popup .card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
}
.rec-create-an-account-popup .card-left {
  margin: 45px;
}
.rec-create-an-account-popup .modal-body {
  padding: 0px !important;
}
.rec-create-an-account-popup .card-2 .content {
  margin-top: 50px;
}
.rec-create-an-account-popup .card-2 .content a {
  color: red;
}
.rec-create-an-account-popup .validate {
  height: 40px;
  text-align: center;
  border-radius: 40px;
  background-color: #1B375C;
  border: 1px solid #1B375C;
  width: 140px;
}
.rec-create-an-account-popup .otp-btn {
  text-align: center;
}
.rec-create-an-account-popup .resend {
  text-decoration: none;
}
.rec-create-an-account-popup .inputs input {
  width: 40px;
  height: 40px;
}
.rec-create-an-account-popup .para {
  color: var(--grey-grey-2, #818181);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-create-an-account-popup .card {
    border: none;
    width: 100%;
    box-shadow: none;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=EnterOTPScreen.css.map */