.whyJoinUs-section h5 {
  font-size: 18px;
  font-weight: 400;
  color: #595959;
}

.cursor-pointer {
  cursor: pointer;
}

.update-button {
  border: 1px solid #4771c0;
  background: none;
  border-radius: 0.5rem;
  background-color: white;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  color: #4771c0;
}

.update-button:hover {
  background-color: rgba(59, 124, 227, 0.0705882353);
}/*# sourceMappingURL=WhyJoinUs.css.map */