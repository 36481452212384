.similer-post-slider {
  margin: 10px 0px 30px 0px;
  /* silder css start */
}
.similer-post-slider .second-section .slick-slider .slick-next:before, .similer-post-slider .second-section .slick-slider .slick-prev:before {
  font-size: 26px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(27, 55, 92);
}
.similer-post-slider .second-section .slick-slider .slick-next, .similer-post-slider .second-section .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -20%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.similer-post-slider .second-section .slick-slider .slick-next {
  right: 40px !important;
}
.similer-post-slider .second-section .slick-slider .slick-prev {
  right: 90px !important;
  left: unset;
}
.similer-post-slider .second-section .slick-slider .slick-next:before {
  position: absolute;
  content: "\f054";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.similer-post-slider .second-section .slick-slider .slick-prev:before {
  position: absolute;
  content: "\f053";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.similer-post-slider .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px 15px 15px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.similer-post-slider .second-section .similer-post-heading h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
}
.similer-post-slider .card {
  padding: 20px !important;
  margin: 0px 10px;
}
.similer-post-slider .card .card-sub-dv {
  display: flex;
}
.similer-post-slider .card .footer-heading {
  text-align: left;
}
.similer-post-slider .card .footer-heading h3 {
  color: #141414;
  font-size: 20px;
  font-weight: 500;
}
.similer-post-slider .card .footer-heading p {
  color: #565656;
  font-size: 14px;
  font-weight: 400;
}
.similer-post-slider .card .footer-heading p span a {
  color: #2B85FF;
  text-decoration: none;
  font-size: 12px;
  margin-left: 25px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
  padding: 3px 6px;
}
.similer-post-slider .card .footer-list-card {
  text-align: left;
}
.similer-post-slider .card .footer-list-card ul {
  padding: unset;
}
.similer-post-slider .card .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}
.similer-post-slider .card .footer-list-card ul li img {
  margin-right: 10px;
  display: inline-block;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .similer-post-slider .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .similer-post-slider .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .similer-post-slider .card .footer-heading p span a {
    margin-left: 0;
  }
  .similer-post-slider .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
  .similer-post-slider .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .similer-post-slider .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .similer-post-slider .card .footer-heading p span a {
    margin-left: 0;
  }
  .similer-post-slider .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=SimilerPostedJob.css.map */