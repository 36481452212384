$color_1: #1B375C;
$color_2: #FFF;
$color_3: #b0a7a7;
/*================================ media query start ========================================*/
.search-resume-sec-parent {.search-resumes-section {padding: 50px;
.filter-bg {border-radius: 4px;background: #FFF;padding: 30px;padding-bottom: 80px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(29, 95, 181, 0.20) inset;}
.search-resumes-bg {border-radius: 4px;padding: 20px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.search-resumes-display {display: flex;justify-content: space-between;
.search-heading {h3 {color: $color_1;font-size: 30px;font-weight: 700;}}
.search-btn1 {border-radius: 10px;border: 1px solid #1B375C;font-size: 16px;background: #fff;color: $color_1;padding: 10px 30px;text-decoration: none;}
.search-btn2 {border-radius: 10px;border: 1px solid #1B375C;font-size: 16px;background: #fff;color: $color_1;padding: 10px 20px;text-decoration: none;}
.search-btn3 {padding: 10px 20px;border-radius: 10px;border: 1px solid #1B375C;background: #1B375C;font-size: 16px;color: $color_2;text-decoration: none;}
}.custom-form-section {display: flex !important;
.search-custom {position: relative;height: 47px;width: 310px;.form-custom {height: 47px;padding-left: 35px;}
.form-custom.form-cus-one {border-radius: 8px 0px 0px 8px;}
.form-custom.form-cus-two {border-radius: 0px;}  
img {position: absolute;color: $color_3;position: absolute;top: 50%;left: 1%;transform: translate(-0%, -50%);}}
.form-custom-btn {border-radius: 0px 8px 8px 0px;background: #1B375C;color: $color_2;border: none;width: 144px;height: 47px;font-size: 13px;font-weight: 500;}
.fitter-icon {height: 24px;width: 24px;}}
.advance-search-parent {margin-left: auto;padding-right: 10px;}
.advance-icon {p {color: $color_1;margin-top: 9px;font-size: 18px;font-weight: 600;}}
.dropdown-custom-section {display: flex;justify-content: flex-end;
.dropdown-custom-btn {border-radius: 4px;border: 1px solid var(--black-50, #53545C);}}}}}
@media only screen and (min-width:768px) and (max-width:992px) {
.search-resume-sec-parent {.search-resumes-section {padding: 50px 0px !important;.search-resumes-bg {.custom-form-section{.search-custom {width: 100%;}}}
.dropdown-custom-section {flex-wrap: wrap;flex-direction: row;justify-content: flex-start;}
.search-area {flex-direction: column;}
.custom-form-section {flex-direction: row;flex-wrap: wrap;   
.form-custom-btn {margin: 7px 0 0 0;}}
.search-resumes-display {.search-btn1 {padding: 9px 3px;font-size: 12px !important;}
.search-btn2 {padding: 9px 23px;font-size: 12px !important;}
.search-btn3 {padding: 9px 14px;font-size: 12px !important;}}}}
.search-resume-sec-parent {.search-resumes-section {padding: 50px 0px !important;
.search-resumes-bg {.search-resumes-display {display: flex;flex-direction: column;
.search-heading {h3 {font-size: 20px;}}
.search-btn1 {padding: 9px 3px;font-size: 12px;}
.search-btn2 {padding: 9px 3px;font-size: 12px;}
.search-btn3 {padding: 9px 3px;font-size: 12px;}}
.search-area {flex-direction: column;}
.custom-form-section {display: flex !important;flex-direction: column;
.search-custom {input {width: 100% !important;border-radius: 0 !important;}}}}
.advance-search-parent {display: flex !important;justify-content: center !important;margin-left: unset !important;}
.advance-icon {display: flex !important;flex-direction: column;}
.dropdown-custom-section {display: flex;flex-direction: row;align-items: center;justify-content: flex-start;flex-wrap: wrap;align-content: center;}.search-resumes-bg{.dropdown-custom-section {display: flex;flex-direction: row;align-items: center;justify-content: flex-start;flex-wrap: wrap;align-content: center;}}
.search-custom {.form-custom {width: 237px;}}
.form-custom-btn {margin-top: 10px;}
.dropdown-custom-btn {margin-top: 17px;}
.form-custom-btn {border-radius: 8px !important;}}}}
@media only screen and (min-width:200px) and (max-width:767px) {.search-resume-sec-parent {.search-resumes-section {padding: 50px 0px !important;
.search-resumes-bg{.custom-form-section {.search-custom {width: 100%;}}}.dropdown-custom-section {flex-wrap: wrap;flex-direction: row;justify-content: flex-start;}
.search-area {flex-direction: column;}
.custom-form-section {flex-direction: row;flex-wrap: wrap;
.form-custom-btn {margin: 7px 0 0 0;}}
.search-resumes-display {.search-btn1 {padding: 9px 3px;font-size: 12px !important;}
.search-btn2 {padding: 9px 23px;font-size: 12px !important;}
.search-btn3 {padding: 9px 14px;font-size: 12px !important;}}}}
.search-resume-sec-parent {.search-resumes-section {padding: 50px 0px !important;
.search-resumes-bg {.search-resumes-display {display: flex;flex-direction: column;
.search-heading {h3 {font-size: 20px;}}
.search-btn1 {padding: 9px 3px;font-size: 12px;}
.search-btn2 {padding: 9px 3px;font-size: 12px;}
.search-btn3 {padding: 9px 3px;font-size: 12px;}}
.search-area {flex-direction: column;}
.custom-form-section {display: flex !important;flex-direction: column;
.search-custom {input {width: 100% !important;border-radius: 0px !important;}}}}
.advance-search-parent {display: flex !important;justify-content: center !important;margin-left: unset !important;}
.advance-icon {display: flex !important;flex-direction: column;}
.dropdown-custom-section {display: flex;flex-direction: row;align-items: center;justify-content: flex-start;flex-wrap: wrap;align-content: center;}.search-resumes-bg{.dropdown-custom-section {display: flex;flex-direction: row;align-items: center;justify-content: flex-start;flex-wrap: wrap;align-content: center;}}
.search-custom {.form-custom {width: 237px;}}
.form-custom-btn {margin-top: 10px;}
.dropdown-custom-btn {margin-top: 17px;}
.form-custom-btn {border-radius: 8px !important;}}}}