.company-review-form h4 {
  font-size: 23px;
  color: #2d2d2d;
  font-weight: 600;
}
.company-review-form #loginPopUpModalWriteReview btn.popup-login-btn {
  padding: 5px 20px;
  border-radius: 40px;
  background: #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B375C;
  width: -moz-fit-content;
  width: fit-content;
}
.company-review-form p {
  color: #595959;
  font-size: 15px;
  font-weight: 400;
}
.company-review-form .rounded-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
}
.company-review-form .text-xs {
  font-size: 12px;
}
.company-review-form .text-sm {
  font-size: 14px;
}
.company-review-form h1 {
  font-size: 35px;
  line-height: 1.5;
  font-weight: 700;
}
.company-review-form .form-label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}
.company-review-form .rounded-md {
  border-radius: 6px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2784313725);
}
.company-review-form .radio-group {
  display: flex;
  justify-content: start;
  align-items: center;
}
.company-review-form .radio-group input[type=radio] {
  display: none;
}
.company-review-form .radio-group label {
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ccc;
  margin-right: -1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-review-form .radio-group label:hover {
  background-color: #f0f0f0;
}
.company-review-form .radio-group input[type=radio]:checked + label {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}
.company-review-form .radio-group label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.company-review-form .radio-group label:last-of-type {
  border-radius: 0 5px 5px 0;
}
.company-review-form .radio-group label:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
.company-review-form .submit-btn {
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  background: #1B375C;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  border: none;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .company-review-form .how-long-till-interview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
}
@media (max-width: 767px) {
  .company-review-form .how-long-till-interview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
}
@media (max-width: 480px) {
  .company-review-form .how-long-till-interview {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
}/*# sourceMappingURL=CompanyReviews.css.map */