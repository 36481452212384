.bannre-area {
  background-image: url(../../../../../public/assets/images/matching-hiring/banner.png);
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
}
.bannre-area .banner-txt {
  position: relative;
  top: 100%;
}
.bannre-area .banner-txt h2 {
  color: var(--White, #FFF);
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
}
.bannre-area .banner-txt p {
  color: var(--White, #FFF);
  font-size: 16px;
  font-weight: 600;
}

.recruiting-software .heading h1 {
  color: #1B375C;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
}
.recruiting-software .heading p {
  color: #1E1E1E;
  font-size: 16px;
}
.recruiting-software .custm-bud-text {
  padding: 0 10px;
}
.recruiting-software .custm-bud-text h4 {
  color: #1B375C;
  font-size: 20px;
  font-weight: 700;
}
.recruiting-software .custm-bud-text p {
  color: #1E1E1E;
  font-size: 16px;
  max-height: 300px;
  overflow-y: auto;
}
.recruiting-software .custm-bud-text p a {
  text-decoration: none;
}
.recruiting-software .custm-bud-text li {
  color: #1E1E1E;
  text-align: justify;
  font-size: 20px;
}
.recruiting-software .custm-bud-img img {
  border-radius: 10px;
  border-radius: 25px;
  border: 10px solid #fff;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.recruiting-software .custm-vdo-sec {
  position: relative;
}
.recruiting-software .custm-vdo-sec img {
  margin: 0 0 20px 0;
}
.recruiting-software .custm-vdo-sec img:nth-child(2) {
  position: absolute;
  top: 60px;
  left: 125px;
}
.recruiting-software .custm-vdo-sec h4 {
  color: #1B375C;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}
.recruiting-software .custm-vdo-sec h4 a {
  text-decoration: none;
  color: #1B375C;
  transition: all 0.5s ease-in-out;
}
.recruiting-software .custm-vdo-sec h4 a:hover {
  text-decoration: underline;
}
.recruiting-software .custm-vdo-sec p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
}
.recruiting-software .help-hire-sec h4 {
  color: #1B375C;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}
.recruiting-software .help-hire-sec p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  padding: 0 10px;
  max-height: 300px;
  overflow-y: auto;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .bannre-area {
    height: 250px;
  }
  .bannre-area .banner-txt {
    top: 20%;
  }
  .bannre-area .banner-txt h2 {
    font-size: 20px;
  }
  .bannre-area .banner-txt p {
    font-size: 16px;
  }
  .recruiting-software {
    padding: 10px 0px !important;
  }
  .recruiting-software .row.mb-5 {
    margin-bottom: 10px !important;
  }
  .recruiting-software .row.mb-5 .col-lg-6.col-md-12.mb-4 {
    margin-bottom: 10px !important;
  }
  .recruiting-software .heading h1 {
    font-size: 22px;
  }
  .recruiting-software .heading p {
    font-size: 16px;
  }
  .recruiting-software .custm-bud-text {
    padding-top: 0px !important;
  }
  .recruiting-software .custm-bud-text h4 {
    font-size: 18px;
  }
  .recruiting-software .custm-bud-text p {
    font-size: 16px;
  }
  .recruiting-software .custm-bud-text li {
    font-size: 20px;
  }
  .recruiting-software .help-hire-sec h4 {
    font-size: 18px;
  }
  .recruiting-software .help-hire-sec p {
    font-size: 16px;
  }
}/*# sourceMappingURL=FindYourNextGreatHireHome.css.map */