.careercompany-card-details .banner-section {
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}
.careercompany-card-details .banner-section .career-img {
  margin-top: 20px;
  border: 1px solid #ccc;
  width: 65px;
  height: 65px;
  border-radius: 5px;
}
.careercompany-card-details .banner-section .career-img img {
  max-width: 65px;
  max-height: 65px;
}
.careercompany-card-details .banner-section .career-img img[alt] {
  font-size: 0.75em;
}
.careercompany-card-details .list-section li {
  list-style: none;
  padding: 0px 60px 0px 0px;
  color: rgba(20, 20, 20, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.careercompany-card-details .list-section li img {
  margin-right: 5px;
}
.careercompany-card-details .list-section ul {
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.careercompany-card-details .frame-img {
  border-radius: 4px;
  margin: 0px;
}
.careercompany-card-details .heading a {
  padding: 2px 10px;
  border-radius: 3px;
  background: rgba(125, 90, 226, 0.1);
  color: #7D5AE2;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.careercompany-card-details .company-logo-name .company-name {
  margin-bottom: 0px;
}
.careercompany-card-details .company-logo-name .heading h2 {
  font-size: 24px;
}
.careercompany-card-details .company-logo-name .heading h2 a {
  font-size: 12px;
}
.careercompany-card-details .company-logo-name .para span.full-half-desc {
  cursor: pointer;
}
.careercompany-card-details .company-logo-name .para p {
  color: rgba(20, 20, 20, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
}
.careercompany-card-details .company-logo-name .para span {
  color: #0A65CC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.careercompany-card-details .company-logo-name .para span a {
  text-decoration: none;
}
.careercompany-card-details .bg-second {
  border-radius: 4px;
  background: #FFF;
  padding: 40px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.careercompany-card-details .bg-form {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.careercompany-card-details .arror-icon a {
  text-decoration: none;
}
.careercompany-card-details .form-btn {
  background: #0A65CC;
  color: #FFF;
}
.careercompany-card-details .top-btn-continue {
  margin: 50px 0px;
  border: none;
  outline: none;
}
.careercompany-card-details .form-btn2 {
  border-radius: 40px;
  background: #1B375C;
  width: 488px;
  padding: 8px 0px 8px 0px;
  color: #FFF;
  font-size: 22px;
  font-weight: 500;
}
.careercompany-card-details .top-btn {
  text-align: center;
}
.careercompany-card-details .first-head h2 {
  font-size: 24px;
}
.careercompany-card-details .first-head {
  padding: 20px;
}
.careercompany-card-details .arror-icon {
  padding: 20px;
}
.careercompany-card-details .form-banner {
  padding: 0px 100px;
}
.careercompany-card-details .form-banner .before-input-cls {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .careercompany-card-details .form-btn {
    background: #0A65CC;
    color: #FFF;
    margin: 5px 0px 0px 0px;
  }
  .careercompany-card-details .list-section ul {
    flex-direction: row;
  }
  .careercompany-card-details .list-section ul li {
    list-style: none;
    padding: 0px 10px 0px 0px;
    color: rgba(20, 20, 20, 0.7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .careercompany-card-details .para span {
    line-height: inherit !important;
    margin-left: 0 !important;
  }
  .careercompany-card-details .para span a {
    display: block;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .careercompany-card-details .company-logo-name .heading h2 span {
    display: block;
  }
  .careercompany-card-details .banner-section {
    text-align: center;
  }
  .careercompany-card-details .banner-section .career-img {
    margin-top: 20px;
    border: 1px solid #ccc;
    width: 65px;
    height: 65px;
    border-radius: 5px;
  }
  .careercompany-card-details .banner-section .career-img img[alt] {
    font-size: 0.75em;
  }
  .careercompany-card-details .form-btn {
    background: #0A65CC;
    color: #FFF;
    margin: 5px 0px 0px 0px;
  }
  .careercompany-card-details .list-section ul {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .careercompany-card-details .list-section ul li {
    list-style: none;
    padding: 0px 10px 0px 0px;
    color: rgba(20, 20, 20, 0.7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .careercompany-card-details .form-btn2 {
    width: 190px !important;
  }
  .careercompany-card-details .bg-second {
    padding: 0px !important;
  }
  .careercompany-card-details .company-logo-name {
    margin: 10px;
  }
  .careercompany-card-details .company-logo-name .heading {
    text-align: center;
  }
  .careercompany-card-details .company-logo-name .company-name {
    margin-bottom: 0px;
    text-align: center;
  }
  .careercompany-card-details .para span {
    line-height: inherit !important;
    margin-left: 0 !important;
  }
  .careercompany-card-details .para span a {
    display: block;
  }
  .careercompany-card-details .form-banner {
    padding: 0px 9px !important;
  }
  .careercompany-card-details .top-btn {
    margin-top: 0px !important;
  }
  .careercompany-card-details .form-btn2 {
    margin-top: 10px !important;
  }
}/*# sourceMappingURL=YourCareerCompanyDetailsCard.css.map */