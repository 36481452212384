.rec-you-register-suc-job-search {.second-section {border-radius: 4px;background: #FFF;margin: 30px 0px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.main-head {align-items: center;padding: 58px;}
.para {color: #1D2026;font-size: 16px;text-align: left;}
.head {color: #1D2026;font-size: 42px;font-style: normal;text-align: left;}
.head2 {color: #1D2026;font-size: 26px;font-style: normal;text-align: left;}
.banner-btn {border-radius: 40px;height: 40px;background: #1B375C;color: #FFF;font-size: 15px;font-weight: 500;padding: 0px 82.5px 0 82.5px;border: none;outline: none;}
.custom-btn {padding: 30px 116px 30px 116px;background: #FFF;box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.12);}
.btn-cus {justify-content: center;align-items: center;flex-shrink: 0;}
.popup-bg {border-radius: 10px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
.modal-header-custom {border-bottom: unset;padding: 12px;}
.pop-custom {button {border-radius: 40px;background: rgb(27, 55, 92);padding: 0px 82.5px 0 82.5px;font-size: 15px;height: 40px;line-height: 40px;color: #fff;font-weight: 500;border: none;}
p {color: #FFF;font-size: 30px;font-weight: 700;}}
.pop-custom {.btn-bg-cus {box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.12);text-align: center;padding: 30px 20px;margin-top: 40px;}}
.popup-section {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
.pop-custom-img {margin: 0px 20px;
img {width: 300px;}}}
.para span {color: blue;font-size: 18px;}
.btn-popup {border-radius: 1.25rem;background-color: white;}}
@media only screen and (min-width:767px) and (max-width:992px) {
.rec-you-register-suc-job-search {
.main-head {align-items: center;padding: 10px;}
.pop-custom {text-align: center;
.btn-bg-cus {margin-top: 20px;padding: 20px 20px;}
p {color: #FFF;font-size: 16px;font-weight: 500;}
button {border-radius: 40px;background: #00A1FF;padding: 0px 45px 0px 45px;font-size: 13px;height: 40px;line-height: 40px;color: #1B375C;font-weight: 500;border: none;margin-top: 0;}}
.head {line-height: 38px;font-size: 30px;}
.head2 {line-height: 36px;font-size: 24px;}
.custom-btn {padding: 0px;box-shadow: unset;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.banner-btn {padding: 0px;width: 230px;height: 43px;border: none;outline: none;}
.para {font-size: 16px;}
.mark-top {margin-top: 20px !important;}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.rec-you-register-suc-job-search {.popup-section {display: flex;flex-direction: column-reverse;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
.pop-custom-img {img {width: 180px;}}}
.main-head {align-items: center;padding: 10px;}
.pop-custom {text-align: center;
p {color: #FFF;font-size: 16px;font-weight: 500;}
button {border-radius: 40px;background: #00A1FF;padding: 0px 50px 0 50px;font-size: 13px;height: 40px;line-height: 40px;color: #1B375C;font-weight: 500;border: none;margin-top: 0;}}
.head {line-height: 38px;font-size: 30px;}
.head2 {line-height: 36px;font-size: 24px;}
.custom-btn {padding: 0px;box-shadow: unset;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.banner-btn {padding: 0px;width: 230px;height: 43px;border: none;outline: none;}
.para {font-size: 16px;}
.mark-top {margin-top: 20px !important;}}}