.particular-company-homepage {
  /*===================================== banner-section-start-css========================= */
  /*======================================== About-section-start-Css============================ */
  /*=====================================Featured-section-start-css====================================*/
  /*=================================== card-section-start-css==================================== */
  /* ======================================form-section-start-css ===============================================================*/
}
.particular-company-homepage .banner-section .banner-img-custom {
  position: relative;
  height: 100vh;
}
.particular-company-homepage .banner-section .banner-img-custom img.img-fluid.banner-img-media {
  height: 100%;
  width: 100%;
}
.particular-company-homepage .banner-section .banner-img-custom .banner-heading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.particular-company-homepage .check-dv p {
  margin-bottom: 0px;
}
.particular-company-homepage .round-input label {
  margin-left: 10px;
}
.particular-company-homepage .hear-about-us p {
  margin-bottom: 0px;
}
.particular-company-homepage .banner-heading h2 {
  color: #FFF;
  text-align: center;
  word-spacing: 6px;
  font-size: 45px;
  font-weight: 700;
}
.particular-company-homepage .banner-heading p {
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
}
.particular-company-homepage .banner-btn-custom {
  border-radius: 10px;
  background: #0093FF;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  padding: 19px 40px;
  border: none;
}
.particular-company-homepage .banner-btn-second-custom {
  border-radius: 10px;
  border: 1px solid #FFF;
  padding: 19px 40px;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  background: rgba(68, 63, 63, 0.0784313725);
}
.particular-company-homepage .banner-btn {
  text-align: center;
}
.particular-company-homepage .banner-btn .custm-scroll p {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
.particular-company-homepage .about-first-heading h3 {
  color: #0093FF;
  font-size: 30px;
  font-weight: 400;
}
.particular-company-homepage .about-second-heading h2 {
  color: #303A42;
  font-size: 35px;
  font-weight: 700;
}
.particular-company-homepage .about-second-heading p {
  color: #303A42;
  font-size: 17px;
  font-weight: 400;
  margin-top: 26px;
}
.particular-company-homepage .about-section .abt-ratng {
  position: relative;
}
.particular-company-homepage .about-section .abt-ratng img:nth-child(1) {
  position: absolute;
  top: 108px;
  left: -65px;
}
.particular-company-homepage .Featured-first-heading h2 {
  color: #303A42;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
}
.particular-company-homepage .featured-cient-logo-section {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid rgb(203, 204, 204);
  padding: 16px 0px;
  margin-top: 20px;
}
.particular-company-homepage .card-first-heading h3 {
  color: #0093FF;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin-top: 80px;
}
.particular-company-homepage .card-second-heading h2 {
  color: #303A42;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
}
.particular-company-homepage .card-second-heading p {
  color: rgba(48, 58, 66, 0.8);
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}
.particular-company-homepage .card-third-heading h5 {
  color: #303A42;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-top: 14px;
}
.particular-company-homepage .card-para p {
  color: rgba(48, 58, 66, 0.8);
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin-top: 14px;
}
.particular-company-homepage .card-image-section {
  border: 1px solid #e5e5e5;
  border-radius: 27px;
  width: 55px;
  height: 55px;
  background: #daf1ff;
  margin: 0 auto;
  margin-top: -31px;
  line-height: 50px;
  text-align: center;
}
.particular-company-homepage .card-image-section img {
  margin: 8px;
}
.particular-company-homepage .card-custom {
  padding: 6px;
  background: #FFF;
  border: none;
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.51);
}
.particular-company-homepage .card-background {
  background: rgb(210, 236, 255);
  background: radial-gradient(circle, rgba(210, 236, 255, 0.8323704482) 17%, rgb(255, 255, 255) 48%, rgb(255, 255, 255) 100%);
}
.particular-company-homepage .card-btn-custom {
  display: flex;
  justify-content: center;
}
.particular-company-homepage .card-first-btn button {
  border-radius: 10px;
  background: #0093FF;
  color: #FFF;
  padding: 19px 40px;
  font-size: 16px;
  border: none;
  font-weight: 700;
}
.particular-company-homepage .card-btn-second button {
  border-radius: 10px;
  border: 1px solid #0093FF;
  padding: 19px 40px;
  color: #0093FF;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
}
.particular-company-homepage .form-first-heading h2 {
  color: #303A42;
  font-size: 30px;
  font-weight: 700;
}
.particular-company-homepage .form-para p {
  color: #303A42;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.particular-company-homepage .form-label-custom {
  color: #303A42;
  font-size: 16px;
  font-weight: 400;
}
.particular-company-homepage .form-custom {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.04);
  padding: 15px;
  border: none;
}
.particular-company-homepage .form-btn-custom button {
  border-radius: 10px;
  background: #0093FF;
  color: #FFF;
  border: none;
  font-size: 18px;
  padding: 12px;
  width: 100%;
  font-weight: 700;
  text-transform: capitalize;
}
.particular-company-homepage .footer-section-image {
  position: relative;
}
.particular-company-homepage .footer-section-heading {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.particular-company-homepage .footer-section-heading h2 {
  color: #FFF;
  font-size: 40px;
  font-weight: 700;
}
.particular-company-homepage .footer-section-heading button {
  border-radius: 10px;
  border: 1px solid #FFF;
  padding: 15px 30px;
  color: #FFF;
  background-color: #0093FF;
  font-size: 18px;
  font-weight: 700;
}

/*================================================ media query=================================== */
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .particular-company-homepage {
    /*============ banner-Qmedia================ */
  }
  .particular-company-homepage .card-btn-custom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .particular-company-homepage .card-btn-custom .card-first-btn,
  .particular-company-homepage .card-btn-custom .card-btn-second {
    width: 100%;
  }
  .particular-company-homepage .card-btn-custom .card-first-btn button,
  .particular-company-homepage .card-btn-custom .card-btn-second button {
    width: 100%;
    padding: 15px 0px;
    margin: 10px 0px;
  }
  .particular-company-homepage .banner-heading h2 {
    font-size: 20px !important;
  }
  .particular-company-homepage .banner-heading p {
    font-size: 16px;
  }
  .particular-company-homepage .banner-img-media {
    max-height: 727px !important;
  }
  .particular-company-homepage .banner-btn-second-custom {
    font-size: 10px;
  }
  .particular-company-homepage .banner-btn-custom {
    font-size: 13px;
    margin: auto;
  }
  .particular-company-homepage .featured-cient-logo-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  .particular-company-homepage .Featured-icon {
    padding: 10px;
  }
  .particular-company-homepage .banner-btn {
    text-align: center;
    display: flex;
    margin-top: unset !important;
  }
  .particular-company-homepage .banner-btn-second-custom {
    padding: 1px;
    font-weight: 400;
  }
  .particular-company-homepage .banner-btn-custom {
    font-weight: 400;
    padding: 6px;
  }
  .particular-company-homepage .card-first-btn button {
    font-size: 11px;
  }
  .particular-company-homepage .card-custom {
    margin-top: 35px;
  }
  .particular-company-homepage .card-btn-second button {
    padding: 11px 22px;
    font-size: 11px;
    font-weight: 600;
  }
  .particular-company-homepage .form-first-heading h2 {
    font-size: 20px;
  }
  .particular-company-homepage .card-second-heading h2 {
    font-size: 24px;
    text-align: left;
  }
  .particular-company-homepage .about-second-heading h2 {
    font-size: 22px;
  }
  .particular-company-homepage .footer-section-heading button {
    font-size: 11px;
    padding: 8px 22px;
  }
  .particular-company-homepage .footer-section-heading h2 {
    font-size: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .particular-company-homepage .banner-heading h2 {
    color: #FFF;
    text-align: center;
    word-spacing: 6px;
    font-size: 32px;
    font-weight: 700;
  }
  .particular-company-homepage .banner-heading p {
    font-size: 22px;
  }
  .particular-company-homepage .card-custom {
    margin-top: 40px;
  }
  .particular-company-homepage .banner-btn-custom {
    border-radius: 10px;
    background: #0093FF;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    padding: 16px 40px;
    border: 1px solid transparent;
  }
  .particular-company-homepage .banner-btn-second-custom {
    border-radius: 10px;
    border: 1px solid #FFF;
    padding: 15px 40px;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    background: rgba(68, 63, 63, 0.0784313725);
  }
}/*# sourceMappingURL=ParticularCompanyHomePage.css.map */