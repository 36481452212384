.after-login-dash {padding: 50px 0;.profile-head {position: relative;padding: unset;img.bg-banner {width: 100%}
.edit-prfile {position: absolute;top: 15px;right: 61px;image-rendering: auto;img {margin-bottom: 5px;padding: 0 5px 0 0;}
a {border-radius: 4px;background: #FE6108;border: unset;padding: 12px 20px;box-shadow: 0px 10px 30px 0px rgba(113, 123, 133, 0.05);color: #FFF5F5;text-align: center;font-size: 12px;font-weight: 700;text-transform: uppercase;text-decoration: none;}}
.dots {position: absolute;top: 15px;right: 15px;border-radius: 4px;padding: 6px 5px;background: rgba(255, 255, 255, 0.90);box-shadow: 0px 10px 30px 0px rgba(113, 123, 133, 0.05);}}
.profile-info {position: relative;.profile-skill {flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: center;
img {width: 30px;height: 30px;}}ul {padding-left: 0px;li {list-style: none;text-align: start;line-height: 30px;color: #181818;font-family: Barlow;font-size: 13px;font-weight: 500;word-wrap: break-word;}}
.profile-img {position: absolute;top: -25px;z-index: 1;img {width: 150px;height: 150px;}}
.pro-complete {background: #1B375C;box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);.sec-lft {h3 {color: #FFF;text-align: center;font-family: Barlow;font-size: 18px;font-weight: 700;line-height: 30.4px;text-transform: capitalize;}p {margin: unset;color: #fff;padding: 5px 0 0 8px;font-size: 10px;}}
.sec-rit {.img {padding: 12px 0;margin: 0 auto;}img {padding: 12px 0;}
.mission-btn {button {padding: 5px 10px;color: #1B375C;text-align: center;font-family: Poppins;font-size: 13px;font-weight: 500;border-radius: 40px;border: unset;}}}}}
.performance {.rec-actn {padding: 50px 20px;border-radius: 4px;background: #F4FAFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;}
.head+.col-lg-6 {border-right: 1px dashed #1B375C;}
h4 {font-family: Barlow;font-size: 18px;font-weight: 700;padding-bottom: 30px;padding-top: 10px;}h5 {color: #000;font-family: Barlow;font-size: 15px;font-weight: 600;padding-bottom: 15px;}
h2 {text-align: left;color: #1B375C;font-family: Barlow;font-size: 24px;font-weight: 700;margin-left: 7px;}}
.resume {.custm-resume {padding: 20px 30px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
h3 {text-align: left;color: #1B375C;font-size: 17px;font-weight: 500;}p {text-align: left;color: var(--gray-500, #71717A);font-size: 14px;font-weight: 400;line-height: 150%;}}
.apk {.custm-apk {height: 100%;padding: 30px;border-radius: 4px;background: #FFF;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.custm-qr{width:130px;height:130px;}.custm-download{img{ margin:5px 0;}}}h4{text-align:left;font-family:Barlow;font-size:18px;font-weight:700;line-height:25px;text-transform:capitalize;} }}.custom-scroll-job{ max-height:250px; overflow-y:auto;}
// ================Recommended Jobs================
.job-role,.textarea,h6{text-align:left}h5,h6{color:#000;font-weight:400}.custm-jobs{background:#fff;box-shadow:0 20px 60px 0 rgba(241,244,248,.5),-4px -4px 20px 0 rgba(27,55,92,.2) inset;padding:30px 12px;margin-top:30px}.desc{display:flex;margin:20px 10px;height:30px}h5{font-size:17px}.job-role{font-size:20px;font-weight:600;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.comp-logo{width:55px;height:55px;flex-shrink:0}img{width:55px;height:25px;flex-shrink:0}h6{font-size:14px}
// =============Rating Section=============
.rating-head,.rating_scale{background:#fff;font-size:18px}.rating-head{padding:30px;border-radius:4px;box-shadow:0 20px 60px 0 rgba(241,244,248,.5),-4px -4px 20px 0 rgba(27,55,92,.2) inset;color:#1b375c;font-family:Barlow;font-weight:700}button{border-radius:40px;background:#1b375c;color:#fff;text-align:center;font-family:Poppins;font-size:15px;font-weight:500;padding:5px 45px;margin:20px 0}.rating_scale{display:block;text-align:center;box-sizing:border-box;width:50px;height:50px;border:none;color:#696973;font-weight:400;padding-top:10px}& label:last-child span,label span{border-radius:10px}label span{margin:5px}& input[type=radio]{display:none}
// ========Recruiters are invitingyou to apply!========
.recruiters{border-radius:4px;background:#fff;box-shadow:0 20px 60px 0 rgba(241,244,248,.5),-4px -4px 20px 0 rgba(27,55,92,.2) inset;border-right:1px solid #d9d9d9;color:#1b375c;font-family:Barlow;font-size:24px;font-weight:700}.text-area{color:var(--light-text-primary,#1c1c1e);font-size:16px;font-weight:500;text-align:start}.loc,h5{font-size:12px;font-weight:400}h5{color:var(--light-text-secondary,rgba(28,28,30,.72));margin:0 8px}
// =================Famous Company===================
.famous-coumpany{padding:30px;border-radius:4px;background:#f4faff;box-shadow:0 20px 60px 0 rgba(241,244,248,.5),-4px -4px 20px 0 rgba(27,55,92,.2) inset;font-family:Barlow;font-size:18px;font-weight:700}button{border:unset;background-color:#ffffff00;color:#0a65cc}
// ================Famous Company================
.custm-fam-comp{border-radius:10px;border:1px solid #fff5ff;background:#fff;box-shadow:0 4px 10px 0 #ebebf0;padding:25px;height:200px;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical}h5{color:var(--violet-700,#6d28d9);font-size:12px;font-weight:400}.custm-company-info{font-size:14px;font-weight:500;padding:0 5px 0 0}.remove-link-css{text-decoration:none;color:#000}
// ================Invite================
.invite{ .wtsp-update{display:grid;padding:25px 45px;border-radius:4px;background:#FFF;box-shadow:0px 20px 60px 0px rgba(241,244,248,0.50),0px 4px 20px 0px rgba(27,55,92,0.20) inset;img{padding:0 0 10px 0;}h2{text-align:left;color:#001833;font-size:18px;font-weight:700;}p{text-align:left;color:#001833;font-size:14px;font-weight:400;}} .custm-invite{height:100%;padding:25px 45px;border-radius:4px;background:#FFF;box-shadow:0px 20px 60px 0px rgba(241,244,248,0.50),0px 4px 20px 0px rgba(27,55,92,0.20) inset;h2{text-align:left;color:#001833;font-size:18px;font-weight:700;}p{text-align:left;color:#001833;font-size:14px;font-weight:400;}button{background-color:#0049fc;color:#fff;border:unset;border-radius:10px;padding:10px 20px;}.share-txt+div{img{ height:240px; }} }}