$primary-color: #0047ab;
$secondary-color: #87ceeb; 
$text-color: #072540; 
$highlight-background: #b3d4fc; 
$disabled-button-background: #e7e8e9;
$disabled-button-text: #9fa3a9; 
$border-color: #eaeaea; 
$question-counter-color: $primary-color;
$total-question-color: #e0dee3; 
.quizy-only-sec {.quiz-body {font-family: 'Anek Malayalam', sans-serif;background: linear-gradient(90.04deg, $primary-color 0.03%, $secondary-color 99.96%);color: $text-color;display: flex;justify-content: center;margin: 0;padding: 0 30px 30px 30px;
.quiz-container {max-width: 500px;min-width: 250px;background: #ffffff;border-radius: 4px;margin-top: 100px;padding: 30px 60px;
.active-question-no {font-size: 32px;font-weight: 500;color: $question-counter-color;}
.total-question {font-size: 16px;font-weight: 500;color: $total-question-color;}
h2 {font-size: 20px;font-weight: 500;margin: 0;}
ul {margin-top: 20px;margin-left: -40px;
li {text-decoration: none;list-style: none;color: $text-color;font-size: 16px;background: #ffffff;border: 1px solid $border-color;border-radius: 16px;padding: 11px;margin-top: 15px;cursor: pointer;
&.selected-answer {background: $highlight-background;border: 1px solid $primary-color;}}}
button {background: linear-gradient(90.04deg, $primary-color 0.03%, $secondary-color 99.96%);border-radius: 9px;font-size: 18px;color: #ffffff;padding: 10px 42px;outline: none;border: none;cursor: pointer;margin-top: 15px;
&:disabled {background: $disabled-button-background;color: $disabled-button-text;cursor: not-allowed;}}
.flex-right {display: flex;justify-content: flex-end;}
.result {h3 {font-size: 24px;letter-spacing: 1.4px;text-align: center;}
p {font-size: 16px;font-weight: 500;
span {color: $primary-color;font-size: 22px;}}}}}}