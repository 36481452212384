.company-top-navbar {
  background: rgb(0, 147, 255);
}
.company-top-navbar .menu-right-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}
.company-top-navbar .menu-right-content .write-review {
  color: #fff;
  margin-right: 20px;
}
.company-top-navbar .menu-right-content .write-review a {
  color: #fff;
  text-decoration: none;
}
.company-top-navbar .menu-right-content .follow-btn {
  background: #fff;
  padding: 5px 10px;
  border-radius: 10px;
}
.company-top-navbar .menu-right-content .follow-btn a {
  text-decoration: none;
}/*# sourceMappingURL=CompanyTopNavbar.css.map */