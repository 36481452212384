.jobfind-description-section {.second-section {border-radius: 4px;background: #FFF;margin-top: 30px;padding: 20px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
.scroll-description {height: 660px;overflow-y: scroll;text-align: justify;
&::-webkit-scrollbar {background-color: transparent;width: 8px;box-shadow: unset;}
&::-webkit-scrollbar-track {-webkit-box-shadow: unset;background-color: transparent;}}}
.job-dep-parent {text-align: left;
h3 {color: var(--gray-900, #18191C);font-size: 20px;}
p {color: var(--gray-600, #5E6670);font-size: 16px;}}
.requirement-par {text-align: left;
h3 {color: var(--gray-900, #18191C);font-size: 20px;}
li {color: var(--gray-600, #5E6670);font-size: 16px;}}
.desirable-dev {text-align: left;
h3 {color: var(--gray-900, #18191C);font-size: 20px;}
li {color: var(--gray-600, #5E6670);font-size: 16px;}}
.benefits-dev {text-align: left;
h3 {color: var(--gray-900, #18191C);font-size: 20px;}
li {color: var(--gray-600, #5E6670);font-size: 16px;}}
.top-header {display: flex;justify-content: space-between;
.company-logo {justify-content: center;align-items: center;border: 1px solid #ccc;width: 65px;height: 65px;border-radius: 5px;display: flex;
img {max-height: 65px;max-width: 65px;}}
.company-logo img[alt] {font-size: 0.75em;}}
.top-head {h3 {font-size: 24px;}
p {span:nth-child(1) {color: #474C54;font-size: 18px;font-weight: 400;}
span:nth-child(2) {border-radius: 3px;background: #0BA02C;color: #FFF;font-size: 14px;font-weight: 600;padding: 4px 12px;}
span:nth-child(3) {border-radius: 20px;background: #FFEDED;padding: 4px 12px;color: #E05151;font-size: 14px;font-weight: 400;}}}
.top-btn {
.top-btn-tag {border-radius: 4px;background: #0BA02C;width: 248px;padding: 16px 32px;color: #FFF;font-size: 16px;font-weight: 600;border: none;}
.top-btn-tag-applied {border-radius: 4px;background: #0a65cc;width: 248px;padding: 16px 32px;color: #FFF;font-size: 16px;font-weight: 600;border: none;}
.apply-tag {text-decoration: none;color: #fff;background-color: transparent;border: none;box-shadow: none;}}
.left-card-section {border-radius: 8px;border: 2px solid #E7F0FA;background: #FFF;}
.border-right {border-right: 2px solid #E7F0FA;}
.left-section {padding: 20px 0;
h6 {color: #18191C;text-align: center;font-family: Inter;font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;}
h5 {color: #0BA02C;text-align: center;font-size: 20px;font-weight: 500;}
p {color: #8c8876;text-align: center;font-size: 14px;font-weight: 400;}}
.left-section2 {padding: 20px;
p {color: #767F8C;font-size: 16px;font-weight: 400;}
h5 {color: #18191C;font-size: 16px;font-weight: 500;}}
.left-card-second {border-radius: 8px;border: 2px solid #E7F0FA;background: #FFF;}
.border-bottom {border: 2px solid #E7F0FA;}
.card-list {display: flex;}
.list-head {h3 {color: #18191C;font-size: 18px;font-weight: 500;}}
.list-top-heading {margin: 20px;margin-top: 37px;}
.card-pad {padding: 10px;
p {color: #767F8C;font-size: 12px;font-weight: 400;text-transform: uppercase;}
h6 {color: #18191C;font-size: 14px;font-weight: 500;}}}
@media only screen and (min-width:768px) and (max-width:991px) {
.jobfind-description-section {
.card-list {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.scroll-description{height: unset !important;}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.jobfind-description-section {.card-pad {p {margin-bottom: 0px;}}
.scroll-description{height: unset !important;}
.top-header {display: flex;justify-content: center;flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;}
.top-head {text-align: left;
h3 {font-size: 18px;}
p {display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: space-between;align-items: center;
span {color: #474C54;font-size: 14px !important;font-weight: 400;}}}
.top-btn {width: 100%;
.top-btn-tag {width: 100% !important;height: 38px !important;padding: 0px;}}
.card-list {display: flex;flex-wrap: wrap;flex-direction: row;align-content: center;justify-content: center;align-items: center;
.card-list-child-dv {padding: 5px 10px;}}
.card {margin-top: 12px;}
.job-dep-parent {h3 {text-align: left;}
p {text-align: justify;}}
.requirement-par {h3 {text-align: left;}
ul {padding-left: 16px;li {text-align: left;}}}
.desirable-dev {h3 {text-align: left;}
ul {padding-left: 16px;li {text-align: left;}}}
.benefits-dev {h3 {text-align: left;}
ul {padding-left: 16px;
li {text-align: left;}}}}}