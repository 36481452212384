.settings {.mt-custon-1 {margin-top: 5rem;}
a {text-decoration: none;}
.input-text {width: 100%;height: 44px;border: 1px solid #a59b9b;border-radius: 10px;padding: 10px;}
.para {margin-bottom: 0px;margin-top: 30px;color: #a59b9b;text-align: left;}
.form {margin: 30px;}
.second-section {border-radius: 13px;margin-top: 80px;margin-bottom: 80px;-webkit-box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;-moz-box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;}
.para-3 {margin-bottom: 1px;margin-top: 22px;}
.password-custom {position: relative;
.clickable{cursor: pointer;}}
.password-custom span {position: absolute;right: 25px;top: 14px;color: #939191;}
.login-btn {a {border-radius: 30px;background: rgba(27, 55, 92, 1);color: white;font-size: 17px;box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);padding: 10px 145px;}}}
@media only screen and (min-width:768px) and (max-width:992px) {}
@media only screen and (min-width:200px) and (max-width:767px) {
.settings {.pt-2 {color: #a59b9b;text-align: left;
a {color: #a59b9b;}}}}
@media (max-width:480px){
.settings {.input-text {  font-size: 13px;}
.login-btn button{background-color: rgb(27, 55, 92);color: white;font-size: 8px;}
.mt-5.login-btn{margin-top: 20px !important;}
.order-md-1.order-1.mb-5{margin-bottom: 20px !important;}  .para {margin-top: 10px;}}}