.career-test-listing .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-test-listing .title h1 {
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.career-test-listing .title a {
  text-decoration: none;
  padding: 8px 50px;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
}
.career-test-listing .custm-info-box {
  background: #F6F6F6;
  border-radius: 10px;
}
.career-test-listing .custm-info-box img {
  border-radius: 5px;
}
.career-test-listing .custm-info-box .textarea {
  padding: 20px 20px;
}
.career-test-listing .custm-info-box .textarea h3 {
  text-align: left;
  color: var(--Main, #073E87);
  font-size: 25px;
  font-weight: 600;
  margin: 15px 0;
}
.career-test-listing .custm-info-box .textarea p {
  text-align: left;
  color: var(--Sub-ink, #747D8C);
  font-size: 15px;
  font-weight: 400;
}
.career-test-listing .custm-info-box .textarea ul {
  padding: unset;
}
.career-test-listing .custm-info-box .textarea ul li {
  list-style: none;
}
.career-test-listing .custm-info-box .textarea ul li img {
  padding: 0 10px 4px 0;
  -o-object-fit: none;
     object-fit: none;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .career-test-listing .title {
    display: block !important;
  }
  .career-test-listing .title h1 {
    font-size: 25px;
  }
  .career-test-listing .title a {
    font-size: 14px;
    padding: 8px 22px;
  }
  .career-test-listing .custm-info-box .textarea h3 {
    font-size: 18px;
  }
  .career-test-listing .custm-info-box .textarea ul {
    display: block !important;
  }
}/*# sourceMappingURL=CareerTestsOurTop.css.map */