.invite-interview {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.38);
  border-radius: 8px;
}
.invite-interview .btn-outline-primary {
  width: 33%;
}
.invite-interview .btn-check:checked + .btn-outline-primary {
  background-color: #007bff;
  color: white;
}
.invite-interview .btn-check:focus + .btn-outline-primary {
  box-shadow: none;
}
.invite-interview .form-select, .invite-interview .form-control {
  margin-bottom: 15px;
}
.invite-interview .modal-footer {
  border-top: none;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.invite-interview .btn-secondary, .invite-interview .btn-primary {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}
.invite-interview p {
  font-size: 14px;
}
.invite-interview .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: none;
}
.invite-interview .accordion {
  margin: 0 20px;
}
.invite-interview .accordion .accordion-button:focus {
  box-shadow: none !important;
}
.invite-interview .accordion .accordion-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.invite-interview .accordion .accordion-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.invite-interview .accordion .accordion-body h3 {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #999;
}
.invite-interview .accordion .accordion-body h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
.invite-interview .accordion .accordion-body p {
  margin-bottom: 5px;
}
.invite-interview .accordion .accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
}
.invite-interview .accordion .accordion-item h3 {
  font-size: 20px;
  font-weight: 600;
}
.invite-interview .radio-group label {
  width: auto !important;
}
.invite-interview .radio-group label:hover {
  background-color: #f0f0f0;
}
.invite-interview .radio-group input[type=radio]:checked + label {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}
.invite-interview .radio-group input[type=radio]:checked + label svg {
  fill: #fff;
}
.invite-interview .radio-group label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.invite-interview .radio-group label:last-of-type {
  border-radius: 0 5px 5px 0;
}
.invite-interview .radio-group label:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
.invite-interview .profile-header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}
.invite-interview .profile-header .btn-group .dropdown-menu li.invite-interview-list-cursor-pointer {
  cursor: pointer;
}
.invite-interview .profile-actions {
  flex-wrap: wrap !important;
  row-gap: 10px !important;
}
.invite-interview .profile-actions button {
  margin-right: 10px;
}
.invite-interview .profile-actions .interview-btn {
  background-color: #1b375c;
  color: white;
}
.invite-interview .profile-info {
  padding: 0px 20px 20px 20px;
}
.invite-interview .profile-info h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0px;
}
.invite-interview .profile-info p {
  word-break: break-all;
  margin: 5px 0;
}
.invite-interview .profile-status {
  background-color: #e8f5e9;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 20px;
}
.invite-interview .alert-info {
  margin: 0 20px 20px 20px;
  background-color: #f1f3f4;
}

.text-xs {
  font-size: 12px !important;
}

input[type=date],
input[type=time] {
  border: 1px solid #767676;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
}

.custom-modal .option-group {
  margin-bottom: 1rem;
}
.custom-modal .update-interview-form .interview-request-submit-button {
  color: white;
  background-color: #08385c;
}
.custom-modal .option-label {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.custom-modal .option-label:hover {
  background-color: #f8f9fa;
}
.custom-modal .option-label input {
  margin-right: 1rem;
}
.custom-modal .recommended-badge {
  background-color: #e7f3ff;
  color: #007bff;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 0.875rem;
  margin-left: 1rem;
}
.custom-modal .option-description {
  margin-left: 2rem;
  color: #6c757d;
  font-size: 0.875rem;
}
.custom-modal #specific-time-tab {
  border-radius: 0 5px 5px 0;
}
.custom-modal .custom-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}
.custom-modal .custom-tabs .nav-item {
  margin-right: 0;
}
.custom-modal .custom-tabs .nav-link {
  border: 1px solid #999;
  border-radius: 0;
  color: #000;
  font-weight: bold;
}
.custom-modal .custom-tabs .nav-link.active {
  background-color: #000;
  color: #fff;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-modal .flex-lg-col {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  .custom-modal .flex-lg-col {
    flex-direction: column;
  }
  .invite-interview .profile-header {
    padding: 12px 3px;
  }
  .invite-interview .accordion .accordion-body h3 {
    font-size: 16px !important;
  }
}/*# sourceMappingURL=InviteToInterview.css.map */