.popular-companies-slider-section {
  /*=============================================== silder-start-css=============================== */
}
.popular-companies-slider-section .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}
.popular-companies-slider-section .footer-list-card ul li img {
  margin-right: 10px;
}
.popular-companies-slider-section .footer-list-card ul {
  padding: unset;
}
.popular-companies-slider-section .card-custom {
  border: none;
  padding: 20px !important;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .card-first-custom {
  border: none;
  padding: 40px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .card-second-custom {
  border: none;
  padding: 50px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .card-second-custom .card-inner-item {
  text-align: center;
}
.popular-companies-slider-section .card-second-custom .card-inner-item img {
  text-align: center;
}
.popular-companies-slider-section .card-custom-2 {
  border: none;
  padding: 35px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .card-custom-3 {
  border: none;
  padding: 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .footer-heading p span:nth-child(3) {
  margin-left: 5px;
}
.popular-companies-slider-section .footer-heading img.star {
  height: 20px;
}
.popular-companies-slider-section .footer-heading .popular-companies-spans {
  height: 42px;
}
.popular-companies-slider-section .footer-heading h3 {
  color: #141414;
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px 0 0;
}
.popular-companies-slider-section .footer-heading span a {
  color: #2B85FF;
  text-decoration: none;
  font-size: 15px;
  margin-left: 15px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
}
.popular-companies-slider-section .footer-icon span a {
  color: #FE6108;
  text-decoration: none;
  font-size: 15px;
  margin-left: 15px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(254, 97, 8, 0.1);
}
.popular-companies-slider-section .footer-heading p {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
}
.popular-companies-slider-section .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .card-heading h3 {
  color: #141414;
  font-size: 26px;
  font-weight: 700;
}
.popular-companies-slider-section .card-second-heading h6 {
  color: #141414;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .popular-companies-slider-section .career-section .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .popular-companies-slider-section .search-custom .form-custom {
    height: 41px;
    width: 296px;
    margin-top: 6px;
  }
  .popular-companies-slider-section .career-section {
    padding: 0px;
  }
  .popular-companies-slider-section .form-custom-btn {
    width: 89px;
    height: 41px;
    font-size: 15px;
    margin-top: 11px;
  }
  .popular-companies-slider-section .career-section .career-top-heading h2 {
    font-size: 28px;
  }
  .popular-companies-slider-section .career-section .career-top-heading span {
    font-size: 28px;
  }
}
.popular-companies-slider-section {
  margin: 10px 0px 30px 0px;
  /* silder css start */
}
.popular-companies-slider-section .second-section .slick-slider .slick-next:before,
.popular-companies-slider-section .second-section .slick-slider .slick-prev:before {
  font-size: 26px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(27, 55, 92);
}
.popular-companies-slider-section .second-section .slick-slider .slick-next,
.popular-companies-slider-section .second-section .slick-slider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: -20%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.popular-companies-slider-section .second-section .slick-slider .slick-next {
  right: 40px !important;
}
.popular-companies-slider-section .second-section .slick-slider .slick-prev {
  right: 90px !important;
  left: unset;
}
.popular-companies-slider-section .second-section .slick-slider .slick-next:before {
  position: absolute;
  content: "\f054";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.popular-companies-slider-section .second-section .slick-slider .slick-prev:before {
  position: absolute;
  content: "\f053";
  color: #ffffff;
  font-size: 12px;
  font-family: fontawesome;
  background: rgb(27, 55, 92);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid rgb(27, 55, 92);
}
.popular-companies-slider-section .decoration-none {
  text-decoration: none;
}
.popular-companies-slider-section .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 30px 15px 15px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.popular-companies-slider-section .second-section .similer-post-heading h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.popular-companies-slider-section .card {
  padding: 20px !important;
  margin: 0px 10px;
  text-align: center;
  max-height: 200px;
  align-content: flex-start;
}
.popular-companies-slider-section .card .card-sub-dv {
  display: flex;
}
.popular-companies-slider-section .card img.logo-img {
  border: 1px solid #ccc;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  -o-object-fit: contain;
     object-fit: contain;
}
.popular-companies-slider-section .card .footer-heading {
  text-align: left;
}
.popular-companies-slider-section .card .footer-heading .line-clamp-comp-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.popular-companies-slider-section .card .footer-heading h3 {
  color: #141414;
  font-size: 18px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.popular-companies-slider-section .card .footer-heading h3 a {
  text-decoration: none;
  color: #141414;
}
.popular-companies-slider-section .card .footer-heading p {
  display: flex;
  color: #565656;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}
.popular-companies-slider-section .card .footer-heading p span a {
  color: rgb(254, 97, 8);
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
  padding: 3px 6px;
  background: rgba(254, 97, 8, 0.1);
}
.popular-companies-slider-section .card .footer-list-card {
  text-align: left;
}
.popular-companies-slider-section .card .footer-list-card div {
  text-align: left;
}
.popular-companies-slider-section .card .footer-list-card div h6 {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
}
.popular-companies-slider-section .card .footer-list-card div p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
.popular-companies-slider-section .card .footer-list-card ul {
  padding: unset;
}
.popular-companies-slider-section .card .footer-list-card ul li {
  list-style: none;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}
.popular-companies-slider-section .card .footer-list-card ul li img {
  margin-right: 10px;
  display: inline-block;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .popular-companies-slider-section .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .popular-companies-slider-section .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .popular-companies-slider-section .card .footer-heading p span a {
    margin-left: 0;
  }
  .popular-companies-slider-section .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
  .popular-companies-slider-section .card {
    margin: 0px 2px;
    padding: 20px !important;
  }
  .popular-companies-slider-section .card .footer-heading p {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .popular-companies-slider-section .card .footer-heading p span a {
    margin-left: 0;
  }
  .popular-companies-slider-section .card .card-sub-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=PopularCompaniesSlider.css.map */