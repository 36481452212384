@media only screen and (min-width:768px) and (max-width:992px) {
.rec-mt-hi-banner-sec {.banner-area {height: 320px;background-size: cover;
.banner-text {left: auto !important;transform: none !important;width: auto !important;top: 15% !important;
h1 {font-size: 30px !important;}
p {font-size: 18px !important;margin: 10px 0 15px 0 !important;}
a {padding: 7px 25px !important;font-size: 16px !important;}}
.banner-sec-lft {&::before {}}
.banner-sec-lft+.col-lg-6 {display: none;}
.banner-sec-rt {display: none;}}}
.rec-free-job-post-sec {padding-top: 20px !important;
.custm-features {padding: 20px;height: auto;margin: 10px 0px !important;height: 100%;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features2 {padding: 20px;height: auto;margin: 10px 0px !important;height: 150px;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features3 {padding: 20px;height: auto;margin: 10px 0px !important;height: 150px;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features4 {padding: 20px;height: auto;margin: 10px 0px !important;height: 150px;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}}.rec-search-candidates-sec {.form-area {width: 100%;height: 500px;
flex-shrink: 0;padding: 60px 40px 40px 40px;border-radius: 30px;background: #1B375C;position: relative;z-index: 9;}
.form-img{img {width: 100%;height: 495px;}}}}
@media only screen and (min-width:200px) and (max-width:767px) {
.rec-mt-hi-banner-sec {.banner-area {height: 240px;
.banner-text {left: auto !important;transform: none !important;width: auto !important;top: 15% !important;padding: 10px;h1 {font-size: 20px !important;}
p {font-size: 15px !important;margin: 0 0 15px 0 !important;}
a {padding: 7px 25px !important;font-size: 16px !important;}}
.banner-sec-lft {&::before {clip-path: unset;width: 100%;}}
.banner-sec-lft+.col-lg-6 {display: none;}
.banner-sec-rt {display: none;}}}
// -----------Search Candidate-------------
.rec-search-candidates-sec {padding-top: 20px !important;
.head-area {margin-bottom: 0 !important;
h2 {font-size: 22px;}}
.form-area {width: auto;padding: 20px;
form {label {font-size: 14px;}
input {height: auto;
&::placeholder {font-size: 14px;}}
.login-btn {padding: 8px 0;width: 100%;}}}
.form-img {img {width: auto;height: auto;margin-top: 10px;border-radius: 20px;}}}
// -----------Free Job Post-------------
.rec-free-job-post-sec {padding-top: 20px !important;
.head-area {margin-bottom: 0 !important;
h3 {font-size: 25px;}
h2 {font-size: 22px;margin-bottom: 0 !important;}}
.custm-features {padding: 20px;height: auto;margin: 10px 0px !important;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features2 {padding: 20px;height: auto;margin: 10px 0px !important;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features3 {padding: 20px;height: auto;margin: 10px 0px !important;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}
.custm-features4 {padding: 20px;height: auto;margin: 10px 0px !important;
h3 {font-size: 18px;}
h4 {font-size: 15px;line-height: normal;}}}}