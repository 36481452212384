.post-a-joboverview-sec {
  margin: 40px 0px;
}
.post-a-joboverview-sec .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.post-a-joboverview-sec .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.post-a-joboverview-sec .create-an-emp-inner .heading-inner h3 {
  font-size: 22px;
  font-weight: 600;
}
.post-a-joboverview-sec .create-an-emp-inner .overview-top-head h4 small {
  font-size: 18px;
  font-weight: 500;
}
.post-a-joboverview-sec .create-an-emp-inner .overview-button .see-full-descriptions {
  border: 1px solid #1B375C;
  color: #1B375C;
  text-decoration: none;
  border-radius: 40px;
  padding: 10px 30px;
}
.post-a-joboverview-sec .create-an-emp-inner .supplement-pay-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
.post-a-joboverview-sec .create-an-emp-inner .supplement-pay-btn .supplement-item {
  margin: 10px 15px;
}
.post-a-joboverview-sec .create-an-emp-inner .supplement-pay-btn .supplement-item .job-type-btn .btn-circle label {
  color: #fff;
  border-radius: 40px;
  background-color: #1B375C;
  padding: 10px 20px;
  width: 100%;
  font-size: 15px;
  text-align: center;
}
.post-a-joboverview-sec .create-an-emp-inner .supplement-pay-btn .supplement-item .job-type-btn .btn-circle input[type=checkbox] {
  display: none;
}
.post-a-joboverview-sec .create-an-emp-inner .supplement-pay-btn .supplement-item .job-type-btn .btn-circle input[type=checkbox]:checked + label {
  border: solid 1px transparent;
  background-color: #1B375C;
  color: #fff;
}
.post-a-joboverview-sec .create-an-emp-inner .setting-row-parent .setting-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.post-a-joboverview-sec .create-an-emp-inner .setting-row-parent .inpt-data-rpint .int-tag {
  border: none;
  font-size: 15px;
  color: #ccc;
}
.post-a-joboverview-sec .create-an-emp-inner .setting-row-parent .inpt-data-rpint .int-tag:focus-visible {
  border: none;
  outline: none;
}
.post-a-joboverview-sec .create-an-emp-inner .bottom-button-dv {
  margin: 40px 0px;
}
.post-a-joboverview-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  color: linear-gradient(0deg, #1B375C, #1B375C);
  padding: 10px 50px;
}
.post-a-joboverview-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 40px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .post-a-joboverview-sec .create-an-emp-inner {
    padding: 10px;
  }
  .post-a-joboverview-sec .create-an-emp-inner .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .post-a-joboverview-sec .create-an-emp-inner .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
}/*# sourceMappingURL=PostAJobOverview.css.map */