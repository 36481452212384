.saved-search-section-start .search-resumes-section {
  padding: 50px;
}
.saved-search-section-start .search-resumes-bg {
  border-radius: 4px;
  padding: 20px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.saved-search-section-start .search-resumes-bg .tab-area .nav-pills li:nth-child(1) button {
  border-radius: 8px 0 0 8px !important;
}
.saved-search-section-start .search-resumes-bg .tab-area .nav-pills li:nth-child(3) button {
  border-radius: 0 8px 8px 0 !important;
}
.saved-search-section-start .search-resumes-bg .tab-area .nav-pills .nav-item .nav-link {
  border-radius: 0;
  color: #1B375C;
  border: 1px solid #1b375c;
}
.saved-search-section-start .search-resumes-bg .tab-area .nav-pills .nav-item .nav-link.active {
  color: #fff;
  background-color: #1B375C !important;
}
.saved-search-section-start .search-resumes-display {
  display: flex;
  justify-content: space-between;
}
.saved-search-section-start .search-btn1 {
  border-radius: 10px;
  border: 1px solid #1B375C;
  font-size: 16px;
  background: #fff;
  color: #1B375C;
  padding: 10px 30px;
  text-decoration: none;
}
.saved-search-section-start .search-btn2 {
  border-radius: 10px;
  border: 1px solid #1B375C;
  font-size: 16px;
  background: #fff;
  color: #1B375C;
  padding: 10px 20px;
  text-decoration: none;
}
.saved-search-section-start .search-btn3 {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #1B375C;
  background: #1B375C;
  font-size: 16px;
  color: #FFF;
  text-decoration: none;
}
.saved-search-section-start .search-heading h3 {
  color: #1B375C;
  font-size: 30px;
  font-weight: 700;
}
.saved-search-section-start .custom-form-section {
  display: flex !important;
}
.saved-search-section-start .search-custom {
  position: relative;
}
.saved-search-section-start .search-custom .form-custom {
  height: 47px;
  width: 351px;
  padding-left: 40px;
}
.saved-search-section-start .search-custom img {
  position: absolute;
  top: 12px;
  left: 11px;
  color: #b0a7a7;
}
.saved-search-section-start .search-custom .form-custom.form-cus-one {
  border-radius: 8px 0px 0px 8px;
}
.saved-search-section-start .search-custom .form-custom.form-cus-two {
  border-radius: 0px;
}
.saved-search-section-start .form-custom-btn {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #FFF;
  border: none;
  width: 144px;
  height: 47px;
  font-size: 13px;
  font-weight: 500;
}
.saved-search-section-start .advance-search-parent {
  margin-left: auto;
}
.saved-search-section-start .advance-icon p {
  color: #1B375C;
  margin-top: 9px;
  font-size: 18px;
  font-weight: 600;
  color: #1B375C;
  margin-top: 9px;
  font-size: 18px;
  font-weight: 600;
}
.saved-search-section-start .fitter-icon {
  height: 24px;
  width: 24px;
}
.saved-search-section-start .filter-bg {
  border-radius: 4px;
  background: #FFF;
  padding: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.saved-search-section-start .filter-bg label {
  font-size: 14px;
}
.saved-search-section-start .day-btn button {
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(20, 20, 20, 0.1);
  background: #FFF;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #393F47;
  width: 60px;
}
.saved-search-section-start .filter-heading h5 {
  color: #141414;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
}
.saved-search-section-start .filter-heading-second h5 {
  color: #141414;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
}
.saved-search-section-start .cust_btn1 {
  border: 2px solid #1B375C;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.saved-search-section-start .cust_a1 {
  color: #1B375C;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.saved-search-section-start .cust_btn2 {
  border: 2px solid #1B375C;
}
.saved-search-section-start .cust_main {
  color: #141414;
  font-size: 20px;
  font-weight: 500;
}
.saved-search-section-start .cust_lis {
  padding-left: 0rem;
  margin-right: 2 rem;
}
.saved-search-section-start .cust_row {
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  margin-top: 17px;
}
.saved-search-section-start .photo-cus img {
  padding: 15px;
}
.saved-search-section-start .photo-cus .createimagefrominitials {
  width: 65px;
  height: 65px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0;
}
.saved-search-section-start .text-left a {
  text-decoration: none;
}
.saved-search-section-start .text-left a h5 {
  color: #000;
  margin-top: 17px;
  font-size: 18px;
}
.saved-search-section-start .cust_up ul {
  margin-top: 17px;
}
.saved-search-section-start .cust_up ul li .shareprofilebtn {
  border: none;
  background: none;
}
.saved-search-section-start .cust_up ul li:nth-child(4) a {
  color: #0090FF;
}
.saved-search-section-start .candidates-heading h3 {
  color: #141414;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
}

/*================================ media query start ========================================*/
@media only screen and (min-width: 768px) and (max-width: 920px) {
  .saved-search-section-start .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .saved-search-section-start .search-custom .form-custom {
    margin-top: 7px;
  }
  .saved-search-section-start .form-custom-btn {
    margin-top: 18px;
  }
  .saved-search-section-start .search-btn3 {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 992px) {
  .saved-search-section-start .custom-form-section {
    flex-direction: row;
  }
  .saved-search-section-start .form-search-area {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .saved-search-section-start .search-resumes-display {
    display: flex;
    flex-direction: column;
  }
  .saved-search-section-start .search-resumes-section {
    padding: 0px;
  }
  .saved-search-section-start .search-btn2 {
    padding: 9px 3px;
    font-size: 12px;
  }
  .saved-search-section-start .search-btn1 {
    padding: 9px 3px;
    font-size: 12px;
  }
  .saved-search-section-start .search-btn3 {
    padding: 9px 3px;
    font-size: 12px;
  }
  .saved-search-section-start .search-heading h3 {
    font-size: 20px;
  }
  .saved-search-section-start .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .saved-search-section-start .advance-icon {
    display: flex !important;
    flex-direction: column;
  }
  .saved-search-section-start .search-custom .form-custom {
    width: 237px;
    margin-top: 8px;
  }
  .saved-search-section-start .advance-icon p {
    display: none;
  }
  .saved-search-section-start .fitter-icon {
    display: none;
  }
  .saved-search-section-start .cust_main {
    font-size: 18px;
  }
  .saved-search-section-start .tab-area .nav-pills .nav-item {
    margin: 0 2px 10px 2px;
    border-radius: 8px !important;
  }
  .saved-search-section-start .tab-area .nav-pills .nav-item .nav-link {
    border-radius: 8px !important;
    font-size: 14px;
    padding: 6px !important;
  }
}/*# sourceMappingURL=SavedSearch.css.map */