.about-us-content-sec {
  /*================== slider-section-start-css ==================*/
  /*======================= about-section-start-css====================== */
  /*================= card-section-start-css ==================*/
  /*=============== brand-section-start-css================== */
  /*===================== Research-section-start-css=============== */
}
.about-us-content-sec .main-head {
  padding: 1px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.about-us-content-sec .custom-scrollbar {
  max-height: 255px;
  overflow-y: auto;
}
.about-us-content-sec .main-head h2 {
  color: #1B375C;
  font-size: 22px;
  font-weight: 700;
  padding: 15px;
  text-align: center;
  text-transform: capitalize;
}
.about-us-content-sec .main-section {
  padding: 40px;
}
.about-us-content-sec .first-head h2 {
  color: #1B375C;
  font-size: 60px;
  font-weight: 700;
  margin-top: 152px;
}
.about-us-content-sec .first-head span {
  color: #0A65CC;
  font-size: 60px;
  font-weight: 700;
  line-height: 137.5%;
}
.about-us-content-sec .banner-section {
  border-radius: 4px;
  background: #FBFBFB;
}
.about-us-content-sec .banner-second {
  padding: 55px;
  border-radius: 4px;
  background: #FBFBFB;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.about-us-content-sec .slider-section {
  border-radius: 4px;
  background: #FFF;
}
.about-us-content-sec .about-section {
  border-radius: 4px;
  background: #FBFBFB;
  padding: 66px;
}
.about-us-content-sec .about-us-heading h2 {
  color: #1E1E1E;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 48px;
  font-weight: 700;
  justify-items: center;
  letter-spacing: 0.96px;
  padding: 0;
}
.about-us-content-sec .about-us-heading p {
  color: #1E1E1E;
  font-style: normal;
  font-weight: 400;
  padding: 20px;
}
.about-us-content-sec .about-us-heading button {
  border-radius: 5px;
  background: #1B375C;
  box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
  width: 157px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
}
.about-us-content-sec .about-second-section h2 {
  color: #1B375C;
  padding: 10px;
  font-size: 40px;
  font-weight: 700;
}
.about-us-content-sec .about-second-section p {
  color: #1E1E1E;
  font-weight: 400;
  padding: 15px;
  letter-spacing: 0.36px;
}
.about-us-content-sec .about-list-section ul li {
  list-style: none;
  color: #1E1E1E;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 30px;
}
.about-us-content-sec .about-list-section ul {
  position: relative;
}
.about-us-content-sec .about-list-section ul li:before {
  position: absolute;
  content: "\f128";
  left: 25px;
  color: #000000;
  font-size: 18px;
  margin-left: -16px;
  font-family: fontawesome;
}
.about-us-content-sec .card-custom {
  border-radius: 30px;
  background: #FFF;
  border: 1px solid rgb(19, 205, 238);
  padding: 20px;
  box-shadow: -6px -1px 18px -4px rgba(0, 0, 0, 0.53);
}
.about-us-content-sec .card-img {
  text-align: center;
  margin-top: 40px;
}
.about-us-content-sec .card-head {
  text-align: center;
}
.about-us-content-sec .card-head h5 {
  margin-top: 20px;
}
.about-us-content-sec .card-head p {
  margin-top: 20px;
}
.about-us-content-sec .card-second-section {
  margin-left: 60px;
}
.about-us-content-sec .card-second-section h2 {
  color: #1B375C;
  font-size: 44px;
  font-weight: 700;
}
.about-us-content-sec .card-second-section p {
  color: #1E1E1E;
  font-weight: 400;
}
.about-us-content-sec .card-second-section button {
  border-radius: 5px;
  background: #1B375C;
  box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
  color: #FFF;
  width: 157px;
  height: 48px;
  font-size: 18px;
  font-weight: 700;
}
.about-us-content-sec .brand-section {
  background: #F2F2F2;
  padding: 60px;
}
.about-us-content-sec .brand-top-heading h2 {
  color: #1B375C;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
}
.about-us-content-sec .brand-top-heading p {
  color: #1E1E1E;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
}
.about-us-content-sec .brand-section-bg {
  border-radius: 30px;
  background: #FFF;
  padding: 70px;
}
.about-us-content-sec .brand-image-bg {
  border-radius: 15px;
  background: #ffffff;
  padding: 14px;
  box-shadow: 3px 9px 37px -12px rgba(0, 0, 0, 0.75);
}
.about-us-content-sec .brand-last-head h3 {
  color: #1B375C;
  font-size: 30px;
  font-weight: 700;
}
.about-us-content-sec .brand-last-head p {
  color: #1E1E1E;
  margin-top: 10px;
  font-weight: 400;
}
.about-us-content-sec .brand-last-head button {
  border-radius: 5px;
  background: #1B375C;
  box-shadow: 0px 10px 40px -12px rgba(30, 30, 30, 0.35);
  color: #FFF;
  border: none;
  width: 157px;
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}
.about-us-content-sec .research-heading h2 {
  color: #1B375C;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
}
.about-us-content-sec .research-heading p {
  color: #1E1E1E;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
}
.about-us-content-sec .research-section {
  padding: 60px;
}
.about-us-content-sec .research-custom h3 {
  color: #1B375C;
  font-size: 44px;
  font-weight: 700;
}
.about-us-content-sec .research-custom p {
  color: #1E1E1E;
  margin-top: 15px;
  font-weight: 400;
}
.about-us-content-sec .research-custom button {
  border-radius: 5px;
  background: #1B375C;
  box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
  color: #FFF;
  width: 157px;
  height: 48px;
  font-size: 18px;
  border: none;
  font-weight: 700;
}
.about-us-content-sec .reseach-second-bg {
  border-radius: 15px;
  background: #ffffff;
  padding: 14px;
  border-radius: 25px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}
.about-us-content-sec .reseach-second-bg img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-us-content-sec .group-brand {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .about-us-content-sec .card-custom {
    margin-top: 36px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .about-us-content-sec .first-head h2 {
    color: #1B375C;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-top: 20px;
    margin: 12px;
  }
  .about-us-content-sec .first-head span {
    font-size: 22px;
  }
  .about-us-content-sec .main-section {
    padding: 0px;
  }
  .about-us-content-sec .card-desgin {
    margin-top: 18px;
  }
  .about-us-content-sec .banner-second {
    margin-top: 20px;
    padding: 18px;
  }
  .about-us-content-sec .about-section {
    padding: 0px;
    margin-top: 15px;
  }
  .about-us-content-sec .about-second-section p {
    padding: 0px;
    text-align: justify;
    word-break: break-all;
    word-spacing: 0px;
  }
  .about-us-content-sec .card-custom {
    margin-top: 30px;
  }
  .about-us-content-sec .card-second-section h2 {
    margin-top: 25px;
    font-size: 30px;
  }
  .about-us-content-sec .brand-section {
    padding: 0px;
    padding-bottom: 20px;
  }
  .about-us-content-sec .brand-section .brand-last-head {
    padding-bottom: 20px;
  }
  .about-us-content-sec .brand-section-bg {
    padding: 0px;
  }
  .about-us-content-sec .group-brand {
    display: block !important;
    margin-top: 24px;
  }
  .about-us-content-sec .group-brand .group-brand-img {
    padding-left: 20px;
  }
  .about-us-content-sec .research-section {
    padding: 0px;
    margin-top: 20px;
  }
  .about-us-content-sec .card-second-section {
    margin-left: 0px;
  }
  .about-us-content-sec .card-second-section p {
    color: #1E1E1E;
    font-weight: 400;
  }
  .about-us-content-sec .brand-top-heading p {
    color: #1E1E1E;
    text-align: center;
    margin-top: 20px;
    font-weight: 400;
  }
  .about-us-content-sec .research-custom h3 {
    color: #1B375C;
    font-size: 30px;
    font-weight: 700;
  }
  .about-us-content-sec .research-heading h2 {
    font-size: 30px;
    text-align: left;
    margin-top: 42px;
  }
  .about-us-content-sec .about-us-heading h2 {
    font-size: 30px;
    padding: 0px;
  }
  .about-us-content-sec .about-us-heading .get-in-touch-btn {
    border-radius: 5px;
    background: #1B375C;
    box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
    color: #FFF;
    width: 157px;
    height: 48px;
    font-size: 18px;
    font-weight: 500;
    margin-left: 0px !important;
    outline: none;
    border: none;
  }
  .about-us-content-sec .about-us-heading p {
    padding: 0px;
  }
  .about-us-content-sec .first-head {
    margin-top: 25px;
  }
  .about-us-content-sec .about-slider-heading h2 {
    font-size: 30px !important;
    text-align: left !important;
  }
  .about-us-content-sec .about-second-section h2 {
    font-size: 30px;
    padding-left: 0px;
  }
  .about-us-content-sec .brand-top-heading h2 {
    font-size: 30px;
    margin-top: 15px;
  }
}/*# sourceMappingURL=AboutUs.css.map */