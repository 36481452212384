.register-header-sec {.main-header {background: #1B375C;padding: 10px 0;display: flex;align-items: center;
.head-sec-lft {display: flex;align-items: center;
i {color: #fff;}
p {color: #fff;margin-bottom: unset;}
.translator {ul {margin-bottom: unset;list-style: none;padding-left: 0;color: #fff;}}
.call-info {.remove-link-style {a {color: white;text-decoration: none;}}}}
.head-sec-rit {display: flex;align-items: center;justify-content: end;
.dropdown-toggle {color: #fff;}
.dropdown-menu li {display: flex;align-items: center;padding: 5px;}}}
.header-second {background: #FFF;box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);// height: 80px;display: flex;align-items: center;
nav {background-color: #fff;
ul .nav-item {font-weight: 600;color: #000;font-size: 15px;}}
.navbar-brand {color: #1B375C;font-size: 20px;font-style: normal;font-weight: 600;line-height: 22px;/* 100% */text-transform: uppercase;
img {width: 180px;height: 34px;object-fit: cover;}}
.header-sec-btn1 a {color: #2B2B2B;font-size: 15px;font-weight: 600;}
.header-sec-btn2 button {background-color: #0A65CC;color: #fff;border-radius: 50px;padding: 10px 40px;border: unset;font-size: 15px;font-weight: 600;}
}}
@media only screen and (min-width:200px) and (max-width:767px) {.register-header-sec {.head-sec-lft {p {font-size: 13px;}
.call-info {.remove-link-style {text-decoration: none;color: white;}}}
.head-sec-rit {justify-content: start }
.header-second {display: block;}}}