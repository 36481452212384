// ===================Transparent Pricing Banner=================
.transparent-pricing-banner {.banner-area {background: url(../../../../../public/assets/images/matching-hiring/t-ban.png);background-size: cover;background-position: 100%;background-repeat: no-repeat;height: 500px;
.banner-sec-lft {position: relative;&:after {position: absolute;content: '';height: 100%;width: 100%;top: 0;left: 0;background: #1b375c;opacity: 0.9;z-index: -1;}
&::before {position: absolute;content: '';height: 100%;width: 120%;top: 0;left: 0;background: #1B375C;opacity: 0.9;clip-path: polygon(0 0, 92% 0, 65% 100%, 0% 100%);}
.banner-text {position: absolute;top: 40%;left: 50%;transform: translate(-50%, -50%);width: 600px;
h1 {text-align: left;color: #FFF;font-weight: 600;}
p {text-align: left;color: #FFF;font-size: 16px;line-height: 30px;margin: 30px 0 35px 0;}
a {border-radius: 50px;background: #0A65CC;color: #FFF;border: unset;text-decoration: none;padding: 10px 40px;font-size: 16px;font-weight: 400;}}}}}// --------------------End-------------------
// ===================Why pay to Sponsor=================
.pay-sponsor {background: #F6F6F6;
h1 {color: #1B375C;text-align: center;font-size: 48px;font-weight: 700;text-transform: capitalize;}
.spons-crd {height: 140px;border-radius: 15px;background: #FFF;padding: 20px;
h3 {color: var(--Main, #073E87);font-size: 25px;font-weight: 600;}
p {color: #1E1E1E;text-align: start;font-size: 18px;}}}
// --------------------End-------------------
// ===================How Pricing Work=================
.how-pricing-work {h1 {color: #1B375C;text-align: center;font-size: 48px;font-weight: 700;text-transform: capitalize;}
.custm-card {background-image: url(../../../../../public/assets/images/matching-hiring/crd.png);background-size: cover;background-position: 100%;height: 350px;padding: 30px 40px;
h3 {color: #1B375C;font-size: 28px;font-weight: 700;}
ul {padding: unset;li {color: #1E1E1E;text-align: justify;font-size: 16px;margin: 0 0 0 20px;}
li:nth-child(1) {list-style: none;margin: unset;}}}}// --------------------End-------------------
// ===================See Budget Action=================
.see-budget-action {.head-area {h1 {color: #1B375C;text-align: center;font-size: 48px;font-weight: 700;text-transform: capitalize;}}
.custm-bud-text {padding: 0 10px;
h4 {text-align: left;color: #1B375C;font-size: 30px;font-weight: 700;padding: 50px 0 0 0;}
p {text-align: left;color: #1E1E1E;text-align: justify;font-size: 20px;padding: 0 60px 0 0;}
li {color: #1E1E1E;text-align: start;word-break: break-all;font-size: 20px;word-spacing: 8px;}}
.custm-bud-img {img {width: 400px;height: 300px;object-fit: cover;border-radius: 10px;border-radius: 25px;border: 10px solid #fff;background: #FFF;box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);}}}
// --------------------End-------------------
// ===================Transparent Pricing Banner=================
.more-solution {background: #F6F6F6;
h1 {color: #1B375C;text-align: center;font-size: 48px;font-weight: 700;text-transform: capitalize;}
.design2 {border-radius: 10px;background: #FFF9EA !important;box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
h3 {color: #DEAD00 !important;}}
.design3 {border-radius: 10px;background: #FFE9E9 !important;box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
h3 {color: #F14A4A !important;}}
.ux {height: 260px;padding: 20px;border-radius: 10px;background: #E9F2FF;box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
img {margin: 0 0 20px 0;}
h3 {color: #1B375C;font-size: 25px;font-weight: 600;margin: 0 0 30px 0;padding: 0 25px 0 0;}
a {border-radius: 40px;background: #1B375C;text-decoration: none;color: #fff;font-size: 14px;padding: 10px 30px;}}}
// --------------------End-------------------