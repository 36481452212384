.quizy-sec .quizy-button-parent {
  background-color: #1B375C;
  height: 50px;
  line-height: 50px;
  text-align: right;
  padding: 0px 20px;
}
.quizy-sec .quizy-button-parent button {
  color: #fff;
}
.quizy-sec .quiz-body {
  background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
  color: #072540;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px 30px 30px;
  height: 100%;
  min-height: 100vh;
}
.quizy-sec .quiz-body .quiz-container {
  max-width: 500px;
  min-width: 400px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 50px;
  padding: 30px 60px;
  height: 100%;
}
.quizy-sec .quiz-body .quiz-container .question-number-count-parent {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.quizy-sec .quiz-body .quiz-container .question-number-count-parent .question-no-count-timer {
  font-size: 20px;
  font-weight: 600;
}
.quizy-sec .quiz-body .quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #0047ab;
}
.quizy-sec .quiz-body .quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}
.quizy-sec .quiz-body .quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.quizy-sec .quiz-body .quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}
.quizy-sec .quiz-body .quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #072540;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}
.quizy-sec .quiz-body .quiz-container ul li.selected-answer {
  background: #b3d4fc;
  border: 1px solid #0047ab;
}
.quizy-sec .quiz-body .quiz-container button {
  background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.quizy-sec .quiz-body .quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}
.quizy-sec .quiz-body .quiz-container .flex-right {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.quizy-sec .quiz-body .quiz-container .result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}
.quizy-sec .quiz-body .quiz-container .result p {
  font-size: 16px;
  font-weight: 500;
}
.quizy-sec .quiz-body .quiz-container .result p span {
  color: #0047ab;
  font-size: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .quizy-sec .quiz-body {
    background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
    color: #072540;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 30px 30px 30px;
    height: 100%;
  }
  .quizy-sec .quiz-body .quiz-container {
    max-width: 500px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 50px;
    padding: 12px;
    height: 100%;
  }
  .quizy-sec .quiz-body .quiz-container h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .quizy-sec .quiz-body .quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #072540;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .quizy-sec .quiz-body {
    background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
    color: #072540;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 30px 30px 30px;
    height: 100%;
  }
  .quizy-sec .quiz-body .quiz-container {
    max-width: 500px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 50px;
    padding: 12px;
    height: 100%;
  }
  .quizy-sec .quiz-body .quiz-container h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .quizy-sec .quiz-body .quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #072540;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
  }
}/*# sourceMappingURL=Quizy.css.map */