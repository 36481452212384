.create-an-employer-ac-sec {
  margin: 40px 0px;
}
.create-an-employer-ac-sec .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.create-an-employer-ac-sec .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label label {
  margin-bottom: 7px;
  margin-top: 15px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label select {
  color: rgba(33, 37, 41, 0.7411764706);
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  color: rgba(102, 102, 102, 0.6);
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label-checkbox input {
  margin-top: 5px;
  margin-right: 15px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label-checkbox input[type=checkbox] {
  position: relative;
  cursor: pointer;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label-checkbox input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .input-label-checkbox input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .form-inner-top p {
  margin-bottom: 0px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .form-inner-top a {
  text-decoration: none;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .bottom-button-dv {
  margin: 40px 0px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  color: #1B375C;
  padding: 10px 50px;
}
.create-an-employer-ac-sec .create-an-emp-inner .form-area .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 50px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .create-an-employer-ac-sec .create-an-emp-inner {
    padding: 10px;
  }
  .create-an-employer-ac-sec .create-an-emp-inner .form-area .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .create-an-employer-ac-sec .create-an-emp-inner .form-area .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
}/*# sourceMappingURL=CreateAnEmployerAccount.css.map */