.applied-job-section {.job-from-rec {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: flex-start;border: 1px solid rgba(20, 20, 20, 0.05);padding: 15px;border-radius: 8px;margin: 10px 0px;
.right-logo-flex {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;align-items: flex-start;justify-content: flex-start;width: 85%;
.company-content {margin-left: 20px;text-align: left;
p {margin-bottom: 0px;}
.post-cls {align-items: center;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: flex-start;align-content: center;
h4 {margin-bottom: 0px;
a {color: inherit;text-decoration: none;}}
h6 {font-size: 12px;color: rgba(125, 90, 226, 1);background: rgba(125, 90, 226, 0.1);padding: 5px;border-radius: 3px;margin-left: 20px;margin-bottom: 0px;}}
.icon-working-show {ul {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: flex-start;align-items: center;padding-left: 0;margin-left: 0;margin-top: 10px;
li {list-style-type: none;padding: 0px 30px 0px 0px;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;
span {padding: 0 3px;}}}}}}
.left-btn-flex {width: 15%;
.left-btn {background: rgba(11, 160, 44, 1);color: #fff;width: 100%;padding: 10px 10px;border-radius: 4px;border: none;outline: none;
img {margin-right: 5px;}}}}}
@media only screen and (min-width:768px) and (max-width:992px) {.applied-job-section {.job-from-rec{.right-logo-flex {.company-content{.icon-working-show {ul {display: flex;flex-direction: row;align-content: center;justify-content: flex-start;align-items: center;padding-left: 0;margin-left: 0;margin-top: 10px;flex-wrap: wrap;}}}}}.job-from-rec {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: flex-start;border: 1px solid rgba(20, 20, 20, 0.05);padding: 5px;border-radius: 8px;margin: 10px 0px;
.right-logo-flex {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;justify-content: center;
.company-content {margin-left: 0;text-align: left;padding: 10px;
p {margin-bottom: 0px;text-align: justify;word-spacing: 3px;word-wrap: break-word;word-break: break-all;text-align: center;}
.post-cls {align-items: center;display: flex;flex-direction: column;flex-wrap: nowrap;justify-content: center;align-content: center;}
.icon-working-show {ul {display: flex;flex-wrap: wrap;align-content: center;justify-content: space-between;align-items: center;padding-left: 0;margin-left: 0;margin-top: 10px;flex-direction: row;
li {padding: 8px 0px 8px 0px;}}}}}
.left-btn-flex {width: 100%;}}}}
@media only screen and (min-width:200px) and (max-width:767px) {.applied-job-section {.job-from-rec {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: flex-start;border: 1px solid rgba(20, 20, 20, 0.05);padding: 5px;border-radius: 8px;margin: 10px 0px;
.right-logo-flex {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;justify-content: center;
.company-content {margin-left: 0;text-align: left;padding: 10px;
p {margin-bottom: 0px;text-align: justify;word-spacing: 3px;word-wrap: break-word;word-break: break-all;text-align: center;}
.post-cls {align-items: center;display: flex;flex-direction: column;flex-wrap: nowrap;justify-content: center;align-content: center;}
.icon-working-show {ul {display: flex;flex-wrap: wrap;align-content: center;justify-content: space-between;align-items: center;padding-left: 0;margin-left: 0;margin-top: 10px;flex-direction: row;
li {padding: 8px 0px 8px 0px;}}}}}
.left-btn-flex {width: 100%;}}}}