@media only screen and (min-width:568px) and (max-width:992px) {
.rec-mt-hi-banner {.banner-area {height: 320px;background-size: cover;
.banner-text {left: auto !important;transform: none !important;width: auto !important;top: 15% !important;
h1 {font-size: 30px !important;}
p {font-size: 18px !important;margin: 10px 0 15px 0 !important;}
a {padding: 7px 25px !important;font-size: 16px !important;}}
.banner-sec-lft {&::before {clip-path: unset;}}
.banner-sec-lft+.col-lg-6 {display: none;}
.banner-sec-rt {display: none;}}}}
@media only screen and (min-width:200px) and (max-width:567px) {
.rec-mt-hi-banner {.banner-area {height: 240px;
.banner-text {left: auto !important;transform: none !important;width: auto !important;top: 15% !important;
h1 {font-size: 20px !important;}
p {font-size: 15px !important;margin: 0 0 15px 0 !important;}
a {padding: 7px 25px !important;font-size: 16px !important;}}.banner-sec-lft {
&::before {clip-path: unset;}}
.banner-sec-lft+.col-lg-6 {display: none;}
.banner-sec-rt {display: none;}}}
// -----------Free Job Post-------------
.rec-free-job-post {padding-top: 20px !important;
.head-area {margin-bottom: 0 !important;
h2 {font-size: 22px;margin-bottom: 0 !important;}}
.custm-features {padding: 20px;height: auto;
h3 {font-size: 18px;}
h4 {font-size: 15px;}}
.custm-features2 {padding: 20px;height: auto;
h3 {font-size: 18px;}
h4 {font-size: 15px;}}
.custm-features3 {padding: 20px;height: auto;
h3 {font-size: 18px;}h4 {
font-size: 15px;}}
.custm-features4 {padding: 20px;height: auto;
h3 {font-size: 18px;}
h4 {font-size: 15px;}}}
// -----------Add Budget-------------
.rec-add-budget {padding-top: 20px !important;padding-bottom: 10px !important;
.head-area {margin-bottom: 0 !important;h2 {font-size: 22px;margin-bottom: 0 !important;}}
.custm-bud-text {h4 {padding: 20px 0 0 0;font-size: 18px;}
p {padding: 0px;font-size: 15px;}}}
// -----------Search Candidate-------------
.rec-search-candidates {padding-top: 20px !important;
.head-area {margin-bottom: 0 !important;
h2 {font-size: 22px;}}
.form-area {width: auto;padding: 20px;
form {label {font-size: 14px;}
input {height: auto;
&::placeholder {font-size: 14px;}}
.login-btn {padding: 8px 0;width: 100%;}}}
.form-img {img {width: auto;height: auto;}}}}