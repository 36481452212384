.rec-chat-section .main-subs-chat .cus-head {
  width: 100%;
  height: 65px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.rec-chat-section .main-subs-chat .cus-head h5 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
  padding: 20px 12px 12px 12px;
}
.rec-chat-section .main-subs-chat .subscrip-card {
  border-radius: 4px;
  padding: 20px 20px 30px 20px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat {
  height: 535px;
  overflow-y: scroll;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat::-webkit-scrollbar-thumb {
  background-color: #1b375c !important;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.rec-chat-section .main-subs-chat .subscrip-card .user-listing-left {
  height: 650px;
  overflow-y: scroll;
}
.rec-chat-section .main-subs-chat .subscrip-card .user-listing-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.rec-chat-section .main-subs-chat .subscrip-card .user-listing-left::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.rec-chat-section .main-subs-chat .subscrip-card .user-listing-left::-webkit-scrollbar-thumb {
  background-color: #1b375c !important;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-input {
  position: relative;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-input .cus-ctrl {
  border-radius: 10px;
  border: 1px solid #e1e1e5;
  padding: 15px 10px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-input img {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list a {
  text-decoration: none;
  color: #141522;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.28px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:first-child {
  border-radius: 10px;
  background: #f1f1f1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:first-child .cus-class-p {
  color: #8E92BC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.12px;
  --bs-text-opacity: 1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:first-child .cus-msg {
  color: #141522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-bottom: 0;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(3) .cus-class-p {
  color: #8E92BC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.12px;
  --bs-text-opacity: 1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(3) .cus-msg {
  color: #141522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-bottom: 0;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(5) .cus-class-p {
  color: #8E92BC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.12px;
  --bs-text-opacity: 1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(5) .cus-msg {
  color: #141522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-bottom: 0;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(7) .cus-class-p {
  color: #8E92BC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.12px;
  --bs-text-opacity: 1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list li:nth-child(7) .cus-msg {
  color: #141522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-bottom: 0;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list .cus-class-p {
  color: #8E92BC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.12px;
  --bs-text-opacity: 1;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-list .inner-else-li {
  color: #8E92BC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.12px;
  margin-bottom: 0px;
}
.rec-chat-section .main-subs-chat .subscrip-card .card.cus-card {
  border: unset;
  margin-top: 20px;
}
.rec-chat-section .main-subs-chat .subscrip-card .card-body.cus-c-b {
  padding: unset;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-class-sec-2 a {
  text-decoration: none;
  color: #141522;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.28px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-class-sec-2 span {
  color: #141522;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.24px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-class-sec-2 .cus-img img {
  border-radius: 100px;
  border: 1px solid #F5F5F7;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat .cus-small {
  color: #8E92BC;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat .bg-primaryy {
  border-radius: 10px 0px 10px 10px;
  background: #0090FF;
  box-shadow: 0px 1px 3px 0px rgba(84, 111, 255, 0.1);
}
.rec-chat-section .main-subs-chat .subscrip-card .my-cus-chat .cus-p {
  border-radius: 0px 10px 10px 10px;
  background: #FFF;
  box-shadow: 0px 1px 3px 0px rgba(84, 111, 255, 0.1);
  color: #141522;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.28px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-hr {
  background: #F5F5F7;
  height: 1px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-inpi {
  color: #8E92BC;
  font-size: 14px;
  letter-spacing: -0.28px;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-g {
  position: relative;
}
.rec-chat-section .main-subs-chat .subscrip-card .cus-g .cus-img {
  position: absolute;
  top: 26px;
  right: 87px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-chat-section .dv-parent-flex {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
}/*# sourceMappingURL=RecChat.css.map */