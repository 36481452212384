.reviews-section {.box-rounded svg {fill: #9d2b6b;}
.progress-bar {background-color: #9d2b6b;}
.progress {height: 8px;}
.border-bottom {border-bottom: 1px solid #e4e2e0;}
span {font-size: 13px;}
.border-dotted {border-bottom: 1px dashed #949494;}
.share-btn {padding: 8px 18px;border-radius: 0.5rem;font-size: 16px;font-weight: 500;background: none;border: none;
svg {fill: #767676 !important;width: 20px !important;height: 20px !important;}}
.share-btn:hover,
.rating-btn:hover {color: rgb(22, 64, 129);background-color: rgb(238, 241, 254);}
.rating-btn {padding: 5px 18px;border-radius: 0.5rem;font-size: 16px;font-weight: 500;background: #f3f2f1;border: none;}
.feedback {p {font-size: 14px;font-weight: 400;color: #595959;line-height: 24px;}
svg {width: 13px;height: 15px;fill: #9d2b6b;}}
.useful-review {background: #eef1fe;border-radius: 8px;padding: 12px 15px;
span {color: #164081;}
h2 {color: #164081;font-weight: 600;font-size: 17px;}}
.review-box-container {flex-wrap: wrap;
.review-box {padding: 6px 14px;border-radius: 30px;font-size: 16px;font-weight: 500;background: #f3f2f1;}}
h3 {color: #2d2d2d;font-weight: 700;font-size: 1.5rem;line-height: 1.25;}
h5,
h4 {font-weight: 400;color: #595959;}
h5 {font-size: 18px;}
h4 {font-size: 16px;}
.rounded-t {border-radius: .5rem .5rem 0px 0px;}
.rounded-b {border-radius: 0px 0px .5rem .5rem;}
.form-section,
.form-section-2 {padding: 1.5rem 1rem 1.5rem;background-color: #f3f2f1;
.sort-rating {border-left: 1px solid #b6b6b6;border-radius: 6px 0 0 6px;border-right: 1px solid #b6b6b6;}
.date {border-radius: 0px 6px 6px 0px;border-right: 1px solid #b6b6b6;}
.sort-rating,.date {padding: 6px 14px;border-top: 1px solid #b6b6b6;border-bottom: 1px solid #b6b6b6;background-color: #fff;font-size: 15px;font-weight: 400;color: #000;}
label {font-size: 1rem;font-weight: 700;line-height: 1.5;margin-bottom: 6px;}
.rating {display: flex;flex-wrap: wrap;gap: 11px;
.box {border: 1px solid #b6b6b6;padding: 6px 14px;background: #fff;border-radius: 50px;}
span {font-size: 14px;font-weight: 400;line-height: 1.5;}
svg {width: 20px;fill: #9d2b6b;}}}}
.claimed-profile {h4 {color: #595959;font-weight: 600;font-size: 16px;}
svg {fill: #1f662c;width: 25px;}}
.update-button {border: 1px solid #4771c0;background: none;border-radius: 0.5rem;background-color: white;padding: 6px 14px;font-size: 16px;font-weight: 600;color: #4771c0;}
.update-button:hover {background-color: #3b7ce312;}
.accordion {h4 {font-size: 16px !important;font-weight: 600;line-height: 1.5;color: #2d2d2d;}}
.rate-recent-company {.rate-star {background: #eef1fe;border-radius: 6px;padding: 6px 15px;width: fit-content;}
background: #eef1fe;border-radius: 8px;padding: 30px 22px;display: flex;flex-direction: column;justify-content: center;align-items: center;
p {font-size: 14px;}
h4 {font-size: 20px;font-weight: 600;line-height: 1.5rem;color: #2d2d2d;}
svg {width: 25px !important;height: 25px !important;}}
.box-rounded {border-radius: .5rem;padding: 1.5rem;border: 1px solid #d4d2d0;
h4 {font-size: 16px !important;font-weight: 600;line-height: 1.5;color: #2d2d2d;}
p {font-size: 14px !important;font-weight: 400;line-height: 1.5;}}
#loginPopUpModal {button.popup-login-btn {padding: 5px 20px;border-radius: 40px;background: #1B375C;text-decoration: none;color: #FFF;text-align: center;font-size: 16px;font-weight: 500;border: 1px solid #1B375C;width: fit-content;}}
.cmp-salary-karlatoon {.remove-link-css {text-decoration: none;
&:hover {border: 1px solid #2557a7;
}.cmp-SalaryCategoryCard {border-radius: 8px;border: 1px solid #fff;box-sizing: border-box;display: flex;flex-direction: column;justify-content: flex-start;margin-block-end: 0;margin-block-start: 0;margin-inline-end: 0;margin-inline-start: 0;min-inline-size: 0;overflow-wrap: anywhere;padding: 1rem 2rem;width: 100%;
&:hover {box-shadow: none;}}}
.cmp-SalaryCategoryCard-title {color: #2557a7 !important;font-size: 1rem !important;font-weight: inherit !important;font-weight: 700 !important;}
.cmp-SalaryCategoryCard-SalaryValue {font-size: 1.25rem !important;}
.cmp-SalaryCategoryCard-SalaryType {font-size: 1rem !important;}}