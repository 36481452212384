.our-partner-sec{
    .slick-slider .slick-slide {padding: 0 15px;}
        /*================== slider-section-start-css ==================*/
        .slick-next:before, .slick-prev:before {font-size: 30px;line-height: 1;opacity:1;color: rgb(212, 212, 212);}
        .slider-section {border-radius: 4px;background: #FFF;}.about-section {border-radius: 4px;background: #FBFBFB;padding: 66px;}    
        .about-us-heading h2 {color: #1E1E1E;text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);font-size: 48px; font-weight: 700;justify-items: center;letter-spacing: 0.96px;padding: 25px;}    
        .about-us-heading p {color: #1E1E1E;font-size: 18px;font-style: normal;font-weight: 400;padding: 20px;}    
        .about-us-heading button {border-radius: 5px;background: #1B375C;box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);color: #FFF;width: 157px;height: 48px; font-size: 18px;font-weight: 500;}   
        .about-slider-heading h2 {color: #1B375C;text-align: center;font-size: 40px;font-weight: 700;}
        .card-desgin {border-radius: 20px;background: #F6F6F6;width: 220px;height: 108px;text-align: center;padding: 16px;transition: all ease-in-out 0.5s;
           img{text-align: center;margin: 0 auto;}} .card-desgin:hover {transform: scale(1.01);box-shadow: 0px 10px 20px 0px rgba(5, 5, 5, 0.08);}}