.you-register-suc-home-return-search {
    margin: 40px 0px;
    .second-section { border-radius: 4px;background: #FFF; margin-top: 30px;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }
    .main-head {align-items: center;padding: 58px;}
    .para {color: #1D2026;font-size: 16px;text-align: left;}
    .head {color: #1D2026;font-size: 42px;font-style: normal;text-align: left;}
    .head2 {color: #1D2026;font-size: 26px;font-style: normal;text-align: left;}
    .banner-btn {border-radius: 40px;height: 50px;width: 327px;background: #1B375C; color: #FFF;font-size: 16px;font-weight: 500; border: none;outline: none;margin: 0px 10px;}
    .custom-btn {padding: 40px 20px;background: #FFF; box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.12); width: 70%;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;margin: 60px auto;}
    .btn-cus {justify-content: center;align-items: center;flex-shrink: 0;}
    .popup-bg { border-radius: 10px;background: #1B375C;box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;}
    .modal-header-custom { border-bottom: unset;padding: 12px;}
    .pop-custom button {border-radius: 40px;background: #00A1FF;padding: 12px 82.5px 13px 82.5px;font-size: 22px;height: 59px;color: #1B375C;font-weight: 500;border: none;margin-top: 15px;}
    .pop-custom {display: flex;justify-content: space-evenly;
        p {color: #FFF;font-size: 30px;font-weight: 700;}
    }
    .para span {color: blue;font-size: 18px;}
    .btn-popup {border-radius: 1.25rem;background-color: white;}
}
@media only screen and (min-width:768px) and (max-width:992px) {
    .you-register-suc-home-return-search {
        .pop-custom {display: flex;justify-content: space-evenly;
            p {color: #FFF;font-size: 16px;font-weight: 500;}
            button {border-radius: 40px;background: #00A1FF;padding: 12px 45px 13px 45px;font-size: 20px;color: #1B375C;font-weight: 500;border: none;margin-top: 0px;}
        }
        .head {line-height: 38px; font-size: 30px;}
        .head2 {line-height: 36px;font-size: 24px;}
        .custom-btn {padding: 0px; box-shadow: unset;}
        .banner-btn {padding: 0px; width: 230px;height: 43px;}
        .para {font-size: 16px;}
        .mark-top { margin-top: 20px !important;}
        .main-head { align-items: center;padding: 10px;}
    }

}
@media only screen and (min-width:200px) and (max-width:767px) {
    .you-register-suc-home-return-search {
        .pop-custom {display: flex;justify-content: space-evenly;
            p {color: #FFF;font-size: 16px;font-weight: 500;}
            button {border-radius: 40px;background: #00A1FF;padding: 12px 23px 13px 24px;font-size: 16px;color: #1B375C;font-weight: 500;border: none;margin-top: 0px;}
        }
        .head {line-height: 38px;font-size: 30px;}
        .head2 {line-height: 36px;font-size: 24px;}
        .custom-btn {padding: 0px;box-shadow: unset;flex-direction: column;
            .banner-btn {padding: 0px;width: 230px;height: 43px;margin: 10px 0px;}
        }
        .para { font-size: 16px;}
        .mark-top {margin-top: 20px !important;}
        .main-head {align-items: center;padding: 10px;}
    }
}