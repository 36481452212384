.rec-header .main-header {
  padding: 10px 0px;
  background: #1B375C;
  height: 100%;
}
.rec-header .main-header .css-custom-sty {
  width: 200px;
  left: -60px;
  padding: 10px;
}
.rec-header .main-header .head-sec-lft .trans-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.rec-header .main-header .head-sec-lft i {
  color: #fff;
}
.rec-header .main-header .head-sec-lft p {
  color: #fff;
  margin-bottom: unset;
}
.rec-header .main-header .head-sec-lft .translator ul {
  margin-bottom: unset;
  list-style: none;
  padding-left: 0;
  color: #fff;
}
.rec-header .main-header .head-sec-rit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  color: #fff;
}
.rec-header .main-header .head-sec-rit .recent-search-txt p {
  margin-bottom: 0;
  margin-right: 40px;
}
.rec-header .main-header .head-sec-rit .recent-search-inpt {
  position: relative;
}
.rec-header .main-header .head-sec-rit .recent-search-inpt input {
  border-radius: 50px;
  width: 280px;
  height: 40px;
  padding: 0px 0px 0px 10px;
  box-shadow: none;
  border: none;
}
.rec-header .main-header .head-sec-rit .recent-search-inpt img {
  position: absolute;
  right: 16px;
  top: 13px;
  width: 16px;
}
.rec-header .header-second {
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}
.rec-header .header-second .recheader-ul li.left-icon-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
}
.rec-header .header-second .recheader-ul li.left-icon-menu a img {
  width: 30px;
}
.rec-header .header-second .navbar-nav li {
  margin: 0 0px;
}
.rec-header .header-second .navbar-nav li a {
  color: #000;
}
.rec-header .header-second .navbar-brand {
  color: #1B375C;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 100% */
  text-transform: uppercase;
}
.rec-header .header-second .navbar-brand img {
  width: 180px;
  height: 34px;
  -o-object-fit: cover;
     object-fit: cover;
}
.rec-header .header-second .header-sec-btn1 button {
  background-color: unset;
  border: 1px solid #0A65CC;
  color: #0A65CC;
  border-radius: 50px;
  padding: 8px 10px;
}
.rec-header .header-second .header-sec-btn2 button {
  background-color: #0A65CC;
  color: #fff;
  border-radius: 50px;
  padding: 8px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .rec-header .main-header .head-sec-lft .trans-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-header .main-header .head-sec-rit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    color: #fff;
    margin: 10px 0px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-header .header-second .recheader-ul li.left-icon-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
  }
  .rec-header .main-header .head-sec-lft {
    text-align: center;
    margin-bottom: 10px;
  }
  .rec-header .main-header .head-sec-lft .trans-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-header .main-header .head-sec-rit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    color: #fff;
  }
  .rec-header .main-header .head-sec-rit .recent-search-txt {
    display: none;
  }
  .rec-header .main-header .head-sec-rit .recent-search-txt p {
    margin-bottom: 0;
    margin-right: 40px;
  }
  .rec-header .main-header .head-sec-rit .recent-search-inpt {
    position: relative;
  }
  .rec-header .main-header .head-sec-rit .recent-search-inpt input {
    border-radius: 50px;
    width: 280px;
    height: 40px;
    padding: 0px 0px 0px 10px;
    box-shadow: none;
    border: none;
  }
  .rec-header .main-header .head-sec-rit .recent-search-inpt img {
    position: absolute;
    right: 16px;
    top: 13px;
    width: 16px;
  }
}/*# sourceMappingURL=RecHeader.css.map */