.company-top-navbar {
    background: rgba(0, 147, 255, 1);

    .menu-left-logo {}

    .menu-right-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        .write-review{
            color: #fff;
            margin-right: 20px;
            a{
                color: #fff;
                text-decoration: none;
            }
        }
        .follow-btn {
            background: #fff;
            padding: 5px 10px;
            border-radius: 10px;
            a{
                text-decoration: none;
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:992px){}
@media only screen and (min-width:200px) and (max-width:767px){
    .company-top-navbar {
        
    }
}