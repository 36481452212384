.profile-tab .banner {
  height: 277px;
  position: relative;
}
.profile-tab .banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile-tab .banner .button-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 40px;
  background: #000;
  border-radius: 50px;
  transform: translate(-50%, -50%);
}
.profile-tab .banner .button-box svg {
  fill: #fff;
}
.profile-tab .text-base {
  font-size: 16px !important;
}
.profile-tab .text-blue {
  color: #2557a7 !important;
}
.profile-tab .color-light {
  color: #595959 !important;
}
.profile-tab .cursor-poiner {
  cursor: pointer;
}
.profile-tab .tab-page #loginPopUpModalProfile button.popup-login-btn {
  padding: 5px 20px;
  border-radius: 40px;
  background: #1B375C;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B375C;
  width: -moz-fit-content;
  width: fit-content;
}
.profile-tab .tab-page span {
  font-size: 12px;
  font-weight: 400;
  color: #595959;
}
.profile-tab .tab-page h2 {
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 600;
  color: #2d2d2d;
}
.profile-tab .tab-page .nav-tabs .nav-item .nav-link {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.profile-tab .tab-page .nav-tabs .nav-item .nav-link.active {
  font-weight: 500;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid #2d2d2d;
}
.profile-tab .tab-page h4 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #2d2d2d;
}
.profile-tab .tab-page h5 {
  color: #2d2d2d;
  font-size: 1rem;
  font-weight: 700;
}
.profile-tab .tab-page p {
  color: #2d2d2d;
  font-weight: 400;
}
.profile-tab .tab-page .btn-gray {
  background: #2d2d2d;
  color: #fff;
}
.profile-tab .tab-page .img-box {
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 100px;
}
.profile-tab .tab-page .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.profile-tab .tab-page .btn-md {
  font-size: 18px;
  font-weight: 600;
}
.profile-tab .submit-btn {
  background-color: #2557a7;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
}
.profile-tab .form-control {
  border-color: #767676;
}
.profile-tab .justify-cotntent-between {
  justify-content: space-between;
}
.profile-tab .w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.profile-tab .about-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.profile-tab .list-style-none {
  list-style: none;
}
.profile-tab .companyInfo-box {
  border: 1px solid #d4d2d0;
  padding: 18px;
  border-radius: 6px;
  min-height: 120px;
  flex-direction: column;
  justify-content: space-between;
}
.profile-tab .companyInfo-box span {
  font-size: 12px;
  color: #767676;
}
.profile-tab .companyInfo-box h5 {
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
}
.profile-tab .companyInfo-box p {
  font-size: 16px;
  font-weight: 400;
  color: #2d2d2d;
}
.profile-tab .text {
  font-size: 15px !important;
  font-weight: 400;
  color: #595959 !important;
  line-height: 25px;
}
.profile-tab .font-semibold {
  font-weight: 600;
}
.profile-tab p {
  text-align: justify;
}
.profile-tab .flex-col {
  flex-direction: column;
}
.profile-tab .border-none {
  border: none;
}
.profile-tab .bg-none {
  background: none;
}

.custom-modal-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: 1;
  height: 29px;
  width: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 15px !important;
  font-weight: 400;
  color: #595959 !important;
  line-height: 25px;
}

.font-semibold {
  font-weight: 600 !important;
}

.flex-col {
  flex-direction: column;
}

.border-none {
  border: none;
}

.bg-none {
  background: none;
}

@media (max-width: 992px) {
  .profile-tab .about-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (max-width: 992px) {
  .profile-tab .companyInfo-box {
    margin-top: 20px;
  }
}
@media screen and (max-width: 575px) {
  .profile-tab .submit-btn, .profile-tab .btn-secondary, .profile-tab .btn-light, .profile-tab .update-button {
    font-size: 15px;
    padding: 6px 10px;
    width: auto !important;
  }
}
@media (max-width: 480px) {
  .profile-tab .about-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
@media screen and (max-width: 375px) {
  .profile-tab .profile-actions {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .profile-tab .update-button {
    margin-right: 0px !important;
  }
}/*# sourceMappingURL=tabs.css.map */