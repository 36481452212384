.add-job-details-sec {
  margin: 40px 0px;
}
.add-job-details-sec .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.add-job-details-sec .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.add-job-details-sec .create-an-emp-inner .heading-inner h3 {
  font-size: 22px;
  font-weight: 600;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 40px;
  margin: 20px 0px;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle label {
  border: 1px solid rgb(27, 55, 92);
  color: #1B375C;
  border-radius: 40px;
  background-color: #fff;
  padding: 10px 0px;
  width: 100%;
  font-size: 15px;
  text-align: center;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle label:hover {
  background-color: #1B375C;
  color: #fff;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle input[type=checkbox] {
  display: none;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle input[type=checkbox]:checked + label {
  border: solid 1px transparent;
  background-color: #1B375C;
  color: #fff;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv label {
  font-weight: 600;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .inner-input .radio-inpt label {
  color: rgba(102, 102, 102, 0.93);
  font-weight: 400;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .inner-input select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .left-sd-content p {
  margin-bottom: 0px;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 40px;
  padding: 10px 40px;
  text-decoration: none;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn span {
  margin-left: 10px;
}
.add-job-details-sec .create-an-emp-inner .card-outer-dv1 {
  padding: 30px 40px;
}
.add-job-details-sec .create-an-emp-inner .bottom-button-dv {
  margin: 40px 0px;
}
.add-job-details-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  padding: 10px 50px;
  color: #1b375c;
}
.add-job-details-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 50px;
}
.add-job-details-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .next-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 32px;
  padding: 10px 70px;
  margin: 4rem 10px 0px 10px;
  border: none;
  text-decoration: none;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .add-job-details-sec .create-an-emp-inner {
    padding: 10px;
  }
  .add-job-details-sec .create-an-emp-inner .card-outer-dv {
    padding: 10px 20px;
  }
  .add-job-details-sec .create-an-emp-inner .card-outer-dv .right-sd-btn {
    margin: 30px 0px;
  }
  .add-job-details-sec .create-an-emp-inner .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .add-job-details-sec .create-an-emp-inner .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
}/*# sourceMappingURL=AddJobDetails.css.map */