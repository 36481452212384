.register-header-sec .main-header {
  background: #1B375C;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.register-header-sec .main-header .head-sec-lft {
  display: flex;
  align-items: center;
}
.register-header-sec .main-header .head-sec-lft i {
  color: #fff;
}
.register-header-sec .main-header .head-sec-lft p {
  color: #fff;
  margin-bottom: unset;
}
.register-header-sec .main-header .head-sec-lft .translator ul {
  margin-bottom: unset;
  list-style: none;
  padding-left: 0;
  color: #fff;
}
.register-header-sec .main-header .head-sec-lft .call-info .remove-link-style a {
  color: white;
  text-decoration: none;
}
.register-header-sec .main-header .head-sec-rit {
  display: flex;
  align-items: center;
  justify-content: end;
}
.register-header-sec .main-header .head-sec-rit .dropdown-toggle {
  color: #fff;
}
.register-header-sec .main-header .head-sec-rit .dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 5px;
}
.register-header-sec .header-second {
  background: #FFF;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.register-header-sec .header-second nav {
  background-color: #fff;
}
.register-header-sec .header-second nav ul .nav-item {
  font-weight: 600;
  color: #000;
  font-size: 15px;
}
.register-header-sec .header-second .navbar-brand {
  color: #1B375C;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
  text-transform: uppercase;
}
.register-header-sec .header-second .navbar-brand img {
  width: 180px;
  height: 34px;
  -o-object-fit: cover;
     object-fit: cover;
}
.register-header-sec .header-second .header-sec-btn1 a {
  color: #2B2B2B;
  font-size: 15px;
  font-weight: 600;
}
.register-header-sec .header-second .header-sec-btn2 button {
  background-color: #0A65CC;
  color: #fff;
  border-radius: 50px;
  padding: 10px 40px;
  border: unset;
  font-size: 15px;
  font-weight: 600;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .register-header-sec .head-sec-lft p {
    font-size: 13px;
  }
  .register-header-sec .head-sec-lft .call-info .remove-link-style {
    text-decoration: none;
    color: white;
  }
  .register-header-sec .head-sec-rit {
    justify-content: start;
  }
  .register-header-sec .header-second {
    display: block;
  }
}/*# sourceMappingURL=registerheader.css.map */