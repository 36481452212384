.rec-mt-hi-banner-sec .banner-area {
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1b375c;
  opacity: 0.9;
  z-index: -1;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  background: #1B375C;
  opacity: 0.9;
  -webkit-clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
  z-index: 0;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft .banner-sec-lft-cus {
  position: relative;
  z-index: 2;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft .banner-text {
  padding: 10px;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft .banner-text h1 {
  text-align: left;
  color: #FFF;
  font-size: 50px;
  font-weight: 600;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft .banner-text p {
  text-align: left;
  color: #FFF;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 30px;
}
.rec-mt-hi-banner-sec .banner-area .banner-sec-lft .banner-text a {
  border-radius: 50px;
  background: #0A65CC;
  color: #FFF;
  border: unset;
  text-decoration: none;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 400;
}

.rec-free-job-post-sec .head-area h2 {
  color: #1B375C;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}
.rec-free-job-post-sec .head-area h3 {
  color: #1B375C;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
}
.rec-free-job-post-sec .custm-features h3 {
  text-align: left;
  color: #ffffff;
  font-size: 28x;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.16px;
}
.rec-free-job-post-sec .custm-features h4 {
  text-align: left;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
}
.rec-free-job-post-sec .custm-features p {
  overflow-y: scroll;
  text-align: left;
  color: #ffffff;
  height: 200px;
  padding: 10px;
}
.rec-free-job-post-sec .custm-features p::-webkit-scrollbar-track {
  -webkit-box-shadow: unset;
  background-color: transparent;
}
.rec-free-job-post-sec .custm-features p::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.rec-free-job-post-sec .custm-features p::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.rec-free-job-post-sec .custm-features {
  background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
  position: relative;
  background-position: 100%;
  background-size: cover;
  height: 300px;
  padding: 40px;
}
.rec-free-job-post-sec .custm-features::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
  background: linear-gradient(181deg, #1B375C 19%, #1B5DB5 94.34%), rgba(0, 0, 0, 0.45);
}

.rec-search-candidates-sec {
  background: #F6F6F6;
}
.rec-search-candidates-sec .head-area h2 {
  color: #1B375C;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
}
.rec-search-candidates-sec .head-area h4 {
  color: #1E1E1E;
  font-size: 22px;
}
.rec-search-candidates-sec .form-area {
  width: 535px;
  height: 500px;
  flex-shrink: 0;
  padding: 60px 40px 40px 40px;
  border-radius: 30px;
  background: #1B375C;
  position: relative;
  z-index: 9;
}
.rec-search-candidates-sec .form-area h5 {
  text-align: left;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 20px 0;
  line-height: 25px;
}
.rec-search-candidates-sec .form-area label {
  display: flex;
  color: #ffffff;
}
.rec-search-candidates-sec .form-area input {
  background: #1B375C;
  height: 40px;
}
.rec-search-candidates-sec .form-area input::-moz-placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.rec-search-candidates-sec .form-area input::placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.rec-search-candidates-sec .form-area textarea {
  background: #1B375C;
  height: 70px;
}
.rec-search-candidates-sec .form-area textarea::-moz-placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.rec-search-candidates-sec .form-area textarea::placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.rec-search-candidates-sec .form-area .login-btn {
  width: 90%;
  padding: 10px 10px;
  border-radius: 32px;
  background: #F0F7FF;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  color: #1B375C;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
}
.rec-search-candidates-sec .form-img img {
  width: 662px;
  height: 495px;
}/*# sourceMappingURL=matchinghiringsec.css.map */