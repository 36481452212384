.bannre-area {background-image: url(../../../../../public/assets/images/matching-hiring/banner.png);background-position: 100%;background-size: cover;background-repeat: no-repeat;height: 450px;
.banner-txt {position: relative;top: 100%;
h2 {color: var(--White, #FFF);font-size: 36px;font-weight: 600;text-transform: capitalize;}
p {color: var(--White, #FFF);font-size: 16px;font-weight: 600;}}}
// =====================Recruiting Software===================
.recruiting-software {.heading {h1 {color: #1B375C;text-align: left;font-weight: 700;text-transform: capitalize;}
p {color: #1E1E1E;font-size: 16px;}}
.custm-bud-text {padding: 0 10px;
h4 {color: #1B375C;font-size: 20px;font-weight: 700;}
p {color: #1E1E1E;font-size: 16px;max-height:  300px;overflow-y: auto;
a {text-decoration: none;}}
li {color: #1E1E1E;text-align: justify;font-size: 20px;}}
.custm-bud-img {img {border-radius: 10px;border-radius: 25px;border: 10px solid #fff;background: #FFF;box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);}
}
.custm-vdo-sec {position: relative;
img {margin: 0 0 20px 0;}
img:nth-child(2) {position: absolute;top: 60px;left: 125px;}
h4 {color: #1B375C;font-size: 24px;font-weight: 700;text-transform: capitalize;
a {text-decoration: none;color: #1B375C;transition: all 0.5s ease-in-out;
&:hover {text-decoration: underline;}}}
p {color: #1E1E1E;text-align: justify;font-size: 16px;}}
// =================Bottom Section=================
.help-hire-sec {h4 {color: #1B375C;font-size: 20px;font-weight: 700;text-transform: capitalize;}
p {color: #1E1E1E;text-align: justify;font-size: 16px;padding: 0 10px;max-height: 300px;overflow-y: auto;}}
}
// ----------------------------End------------------------
@media only screen and (min-width:200px) and (max-width:767px) {.bannre-area {height: 250px;
.banner-txt {top: 20%;
h2 {font-size: 20px;}
p {font-size: 16px;}}}
.recruiting-software {padding: 10px 0px !important;
.row.mb-5 {margin-bottom: 10px !important;
.col-lg-6.col-md-12.mb-4 {margin-bottom: 10px !important;}}
.heading {h1 {font-size: 22px;}
p {font-size: 16px;}}
.custm-bud-text {padding-top: 0px !important;
h4 {font-size: 18px;}
p {font-size: 16px;}
li {font-size: 20px;}}
.help-hire-sec {h4 {font-size: 18px;}
p {font-size: 16px;}}}}