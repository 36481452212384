.rec-confirmation-sec .form-head-dv {
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding: 30px;
  margin: 60px 0px;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-1 {
  margin: 0px 10px;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-1 input {
  margin-right: 10px;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-1 .a-tag-color {
  color: #1B375C;
  font-weight: 600;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-2 {
  margin: 0px 10px;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-2 input {
  margin-right: 10px;
}
.rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-2 .a-tag-color {
  color: #1B375C;
  font-weight: 600;
}
.rec-confirmation-sec .form-head-dv .next-pre-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.rec-confirmation-sec .form-head-dv .next-button .next-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 32px;
  padding: 10px 70px;
  margin: 4rem 10px 0px 10px;
  border: none;
  text-decoration: none;
}
.rec-confirmation-sec .form-head-dv .prev-button .prev-btn {
  background: #fff;
  color: rgb(27, 55, 92);
  border-radius: 32px;
  padding: 10px 70px;
  margin: 4rem 10px 0px 10px;
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
}
.rec-confirmation-sec .form-head-dv .cd-heading {
  text-align: left;
}
.rec-confirmation-sec .form-head-dv .cd-heading h2 {
  font-size: 28px;
}
.rec-confirmation-sec .form-head-dv .cd-form-file label.label input[type=file] {
  position: absolute;
  top: -1000px;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label {
  cursor: pointer;
  border: 1px solid rgb(173, 173, 173);
  width: 100%;
  border-radius: 9px;
  padding: 7px 15px 7px 15px;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label .upload-icon {
  float: right;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label span {
  color: #797b7e;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label:hover {
  background: #ccc;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label:active {
  background: #ccc;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label:invalid + span {
  color: #000000;
}
.rec-confirmation-sec .form-head-dv .cd-form-file .label:valid + span {
  color: #ffffff;
}
.rec-confirmation-sec .form-head-dv .cd-form-f-btn {
  margin-top: 26px;
}
.rec-confirmation-sec .form-head-dv .cd-form-f-btn input {
  background: linear-gradient(0deg, #0A65CC, #0A65CC), linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: #fff;
  padding: 8px 15px 8px 15px;
}
.rec-confirmation-sec .form-head-dv .cd-form-f {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content .content {
  margin-left: 20px;
}
.rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content .content a {
  text-decoration: none;
}
.rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content .content .remove-border {
  border: none !important;
  outline: none;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-confirmation-sec .form-head-dv .cd-form-f {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content .content {
    margin-left: 3px;
    width: 150px;
    word-wrap: break-word;
  }
  .rec-confirmation-sec .form-head-dv .cd-form-f .confirm-icon-content .content a {
    text-decoration: none;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .rec-confirmation-sec .form-head-dv .checkbox-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
  }
  .rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-1 {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-1 label {
    font-size: 13px;
  }
  .rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-2 {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-confirmation-sec .form-head-dv .checkbox-dv .checkbox-2 label {
    font-size: 13px;
  }
  .rec-confirmation-sec .form-head-dv .next-button .next-btn {
    background: rgb(27, 55, 92);
    color: #fff;
    border-radius: 32px;
    padding: 10px 15px;
    margin: 4rem 0px 0px 0px;
    border: none;
    text-decoration: none;
  }
  .rec-confirmation-sec .form-head-dv .prev-button .prev-btn {
    background: #fff;
    color: rgb(27, 55, 92);
    border-radius: 32px;
    padding: 10px 30px;
    margin: 4rem 10px 0px 10px;
    border: 1px solid rgb(27, 55, 92);
    text-decoration: none;
  }
}/*# sourceMappingURL=ProfileDetails.css.map */