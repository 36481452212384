.company-details-form .form-head-dv {
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding: 30px;
  margin: 60px 0px;
}
.company-details-form .form-head-dv .check-circle-parent .check-circle-relative {
  position: relative;
}
.company-details-form .form-head-dv .check-circle-parent .check-circle-relative .greencheck-circle-absolute {
  position: absolute;
  top: 45%;
  right: 1%;
  transform: translate(-1%, -45%);
}
.company-details-form .form-head-dv .next-button {
  margin-top: 30px;
}
.company-details-form .form-head-dv .next-button .next-btn {
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 32px;
  padding: 10px 125px;
  border: none;
  margin-top: 4rem;
  text-decoration: none;
}
.company-details-form .form-head-dv .cd-heading {
  text-align: left;
}
.company-details-form .form-head-dv .cd-heading h2 {
  font-size: 28px;
}
.company-details-form .form-head-dv .cd-form-f {
  width: 100%;
  text-align: left;
}
.company-details-form .form-head-dv .cd-form-f label {
  margin-bottom: 5px;
}
.company-details-form .form-head-dv .cd-form-f input {
  border: 1px solid rgb(173, 173, 173);
  width: 100%;
  border-radius: 9px;
  padding: 7px 15px 7px 15px;
}
.company-details-form .form-head-dv .cd-form-f .input-dropdown {
  border: 1px solid rgb(173, 173, 173);
  width: 100%;
  border-radius: 9px;
  padding: 7px 15px 7px 15px;
}
.company-details-form .form-head-dv .cd-form-f-btn {
  margin-top: 26px;
}
.company-details-form .form-head-dv .cd-form-f-btn input {
  background: linear-gradient(0deg, #0A65CC, #0A65CC), linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: #fff;
  padding: 8px 15px 8px 15px;
}

.overlay-form-submission {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color and opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .company-details-form .form-head-dv .next-button .next-btn {
    box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
    background: rgb(27, 55, 92);
    color: #fff;
    border-radius: 32px;
    padding: 10px 70px;
    border: none;
    margin-top: 4rem;
    text-decoration: none;
  }
}/*# sourceMappingURL=CompanyDetailscss.css.map */