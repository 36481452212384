.quizy-only-sec .quiz-body {
  font-family: "Anek Malayalam", sans-serif;
  background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
  color: #072540;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px 30px 30px;
}
.quizy-only-sec .quiz-body .quiz-container {
  max-width: 500px;
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 100px;
  padding: 30px 60px;
}
.quizy-only-sec .quiz-body .quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #0047ab;
}
.quizy-only-sec .quiz-body .quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}
.quizy-only-sec .quiz-body .quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.quizy-only-sec .quiz-body .quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}
.quizy-only-sec .quiz-body .quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #072540;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}
.quizy-only-sec .quiz-body .quiz-container ul li.selected-answer {
  background: #b3d4fc;
  border: 1px solid #0047ab;
}
.quizy-only-sec .quiz-body .quiz-container button {
  background: linear-gradient(90.04deg, #0047ab 0.03%, #87ceeb 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.quizy-only-sec .quiz-body .quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}
.quizy-only-sec .quiz-body .quiz-container .flex-right {
  display: flex;
  justify-content: flex-end;
}
.quizy-only-sec .quiz-body .quiz-container .result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}
.quizy-only-sec .quiz-body .quiz-container .result p {
  font-size: 16px;
  font-weight: 500;
}
.quizy-only-sec .quiz-body .quiz-container .result p span {
  color: #0047ab;
  font-size: 22px;
}/*# sourceMappingURL=QuizyOnly.css.map */