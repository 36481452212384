.rec-candidate-profile-sec {.candidate-pro-section-inner-dv {box-shadow: 0px 20px 60px 0px #F1F4F880;box-shadow: -4px -4px 20px 0px #1B375C33 inset;padding: 40px 20px;margin: 30px 0px;
.candidate-pro-btn {display: flex;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.candidate-pro-atag {a {text-decoration: none;border: 1px solid #1B375C;padding: 6px 10px;margin: 0px 5px;border-radius: 10px;color: #1B375C;transition: 0.5s ease-in-out;
&:hover {background-color: #1B375C;color: #fff;img {filter: contrast(0%);}}
img {margin-right: 10px;width: 22px;}}}.candidate-pro-icons {a {margin-right: 20px;}}}.sidepanel-img-tabs-parent {.sidepanel-img {img {border-radius: 50%;}}
.skill-heading {.skill-head {color: #CCC;}.skill-para {margin-bottom: 0px;}.skill-btn {-webkit-appearance: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;outline: none;cursor: pointer;background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);border-radius: 30px;border: 1px solid #8F9092;box-shadow: 0 4px 3px 1px #FCFCFC, 0 1px 6px #D6D7D9, 0 -1px 6px #CECFD1, 0 0px 2px #FEFEFE, inset 0 0 0px 0 #CECFD1;transition: all 0.2s ease;color: #606060;text-shadow: 0 1px #fff;padding: 3px 6px;margin: 6px 6px;
&:hover {background-image: linear-gradient(to top, #0055ff 0%, #1e74f5 80%, #3da0e7 100%);border-radius: 30px;border: 1px solid #8F9092;box-shadow: 0 4px 3px 1px #FCFCFC, 0 1px 6px #D6D7D9, 0 -1px 6px #CECFD1, 0 0px 2px #FEFEFE, inset 0 0 0px 0 #CECFD1;color: #fff;}}}}.rght-dv-profile-details{.top-heading{border-bottom: 2px solid #ccc;margin: 10px 0px;.social-details{word-wrap: break-word;h6{a{text-decoration: none;color: #000;}}}}.top-heading2{h2{color: #ccc;margin-bottom: 10px;}.resume-details-company{margin:20px 0px;.last-a-tag{a{text-decoration: none;color: #000;}}}p{margin-bottom: 0px;}.head2-links{.head2-links-atag{color: #000;}}.social-details{word-wrap: break-word;
h6{a{text-decoration: none;color: #000;}}}}}}}
@media only screen and (min-width:768px) and (max-width:992px) {.rec-candidate-profile-sec {.candidate-pro-section-inner-dv {.candidate-pro-btn {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.candidate-pro-atag {margin-bottom: 20px;}}}}
}
@media only screen and (min-width:200px) and (max-width:767px) {.containerrr {max-width: 100%;background: #fff;margin: 0px auto 0px;border-radius: 3px;padding: 30px 15px !important;}.rec-candidate-profile-sec {.candidate-pro-section-inner-dv {.candidate-pro-btn {display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: space-between;align-items: center;
.candidate-pro-atag {margin-bottom: 20px;}}}}
}