.transparent-button {
  border: none;
  background-color: transparent;
}

.myprofile-section .main-head {
  padding: 1px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.myprofile-section .main-head h2 {
  color: #1B375C;
  font-size: 22px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: capitalize;
}
.myprofile-section .main-section {
  padding: 40px;
}
.myprofile-section .banner-section {
  border-radius: 4px;
  background: #FFF;
  padding: 10px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.myprofile-section .banner-second {
  padding: 55px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.myprofile-section .banner-bg .banner-bg-child-img-content-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.myprofile-section .banner-bg .banner-bg-child-img-content-parent .profile-images img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.myprofile-section .banner-bg .banner-bg-child-img-content-parent .first-head-bg h4 {
  font-size: 18px;
  margin: 0 0 5px 0;
  word-break: break-all;
}
.myprofile-section .banner-bg .banner-bg-child-img-content-parent .first-head-bg p {
  font-size: 14px;
  word-break: break-all;
}
.myprofile-section .banner-bg .first-head-bg h4 {
  font-size: 24px;
  margin: 0 0 5px 0;
}
.myprofile-section .banner-bg .first-head-bg p {
  font-size: 14px;
}
.myprofile-section .banner-bg img {
  padding: 12px;
}
.myprofile-section .banner-right-bg .banner-play-btn {
  border-radius: 10px;
  border: 1px solid #0A65CC;
  background: #0A65CC;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.myprofile-section .banner-right-bg {
  display: flex;
  align-items: center;
}
.myprofile-section .banner-right-bg .banner-right-img {
  padding: 6px;
}
.myprofile-section .banner-right-bg .popup-video-play .player-wrapper #myVideoPlayer {
  width: 100%;
  height: 100%;
}
.myprofile-section .first-head-bg {
  margin-top: 18px;
  margin-left: 17px;
}
.myprofile-section .bg-img {
  padding: 10px;
}
.myprofile-section .user-section {
  background: #FFF;
  padding: 20px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -2px -2px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.myprofile-section .user-section .word-break-name {
  word-break: break-word;
  width: 80%;
}
.myprofile-section .top-user-section {
  display: flex;
  justify-content: space-between;
}
.myprofile-section .top-user-section .top-user-head h4 {
  margin-bottom: 0px;
  font-size: 20px;
}
.myprofile-section .top-user-section .top-user-head .first-para {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  margin-bottom: 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.myprofile-section .see-more-para span a {
  text-decoration: none;
}
.myprofile-section .top-user-section .edit-btn {
  padding: 10px 20px;
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #51b6dd;
  border-radius: 13px;
  background: #fff;
}
.myprofile-section .user-details {
  display: flex;
  justify-content: space-between;
}
.myprofile-section .user-details p {
  color: #000;
  font-weight: 600;
  word-break: break-all;
}
.myprofile-section .user-details h6 {
  color: var(--light-text-disabled, rgb(124, 121, 121));
}
.myprofile-section .edit-btn2 {
  color: #51b6dd;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #51b6dd;
  border-radius: 13px;
  background: #fff;
  height: 45px;
  width: 121px;
}
.myprofile-section .border-bottom {
  border: 1px solid rgba(28, 28, 30, 0.12);
  width: 100%;
}
.myprofile-section .card-icon-section {
  display: flex;
  justify-content: space-between;
}
.myprofile-section .card-icon-section h4 {
  font-size: 20px;
}
.myprofile-section .card-custom {
  border-radius: 8px;
  border: 1px solid #F2F2F7;
}
.myprofile-section .icon-card-section {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 10px;
  border: 1px solid rgba(52, 67, 75, 0.15);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.myprofile-section .icon-card-section .icon-card-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
}
.myprofile-section .icon-card-section img.social-img {
  margin-right: 20px;
}
.myprofile-section .icon-card-section p {
  margin-bottom: 0px;
  word-break: break-all;
}
.myprofile-section .icon-card-section .trush-icon {
  display: flex;
  margin-left: 70px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.myprofile-section .icon-card-section .second-bg-section {
  background: #1DB5BE;
}
.myprofile-section .icon-card-section .second-bg-head h2 {
  color: #FFF;
  font-feature-settings: "liga" off;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.2px;
}
.myprofile-section .icon-card-section .second-bg-head p {
  color: #111827;
  text-align: justify;
  font-feature-settings: "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.myprofile-section .icon-card-section .mark-top {
  margin-top: 120px;
}
.myprofile-section .last-element-show-more {
  margin-bottom: 15px;
}
.myprofile-section .last-element-show-more a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .myprofile-section .top-user-section .add-btn-parent {
    width: 220px;
  }
  .myprofile-section .top-user-section .add-btn-parent .edit-btn {
    font-size: 15px;
  }
  .myprofile-section .first-head h2 {
    font-size: 18px;
  }
  .myprofile-section .first-head span {
    font-size: 18px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .myprofile-section {
    margin: 10px 0px;
  }
  .myprofile-section .first-head-bg {
    margin-top: 18px;
    margin-left: 17px;
  }
  .myprofile-section .banner-bg .banner-bg-child-img-content-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .myprofile-section .banner-bg .banner-bg-child-img-content-parent .profile-images img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .myprofile-section .banner-bg .first-head-bg h4 {
    font-size: 16px;
    margin: 0 0 5px 0;
  }
  .myprofile-section .top-user-section .edit-btn {
    padding: 8px 10px;
    font-size: 16px;
  }
  .myprofile-section .user-section {
    padding: 0px;
    padding-bottom: 10px;
  }
  .myprofile-section .card-icon-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .myprofile-section .card-icon-section .trash-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .myprofile-section .icon-card-section .icon-card-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
  }
  .myprofile-section .icon-card-section img.social-img {
    margin-right: 3px;
  }
  .myprofile-section .top-user-section .d-flex {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .myprofile-section .top-user-section .display-btn-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
  }
  .myprofile-section .main-section {
    padding: 0px !important;
  }
  .myprofile-section .top-user-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .myprofile-section .first-head h2 {
    font-size: 18px;
  }
  .myprofile-section .first-head span {
    font-size: 18px;
  }
}/*# sourceMappingURL=MyProfile.css.map */